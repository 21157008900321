import axios from 'axios';
import { config } from '../config';
import { index } from '../store';
import { callNodeServerApi } from '../utils/interceptor';
import { API_PATH } from '../constants';

export const saveProjectProgressApiCall = async (req) => {
    if (config.urls.serverurl) {
        const res = await axios.patch(`${config.urls.serverurl}/metadata/v1/${req.id}`, req, {
            headers: {
                "Content-Type": "application/json"
                // "Authorization": `Bearer ${cookie}`
            },
        });
        return res.data
    }

}

export const getProjectProgressApiCall = async (req) => {
    if (config.urls.serverurl) {
        const res = await axios.get(`${config.urls.serverurl}/metadata/v1/${req}`, {
            headers: {
                "Content-Type": "application/json"
                // "Authorization": `Bearer ${}`
            },
        });
        return res.data
    }

}


export const updateRecordingToVumu = async (req) => {
    const res = await axios.post(`${config.urls.vumuserverless}/update-recording`, req, {
        headers: {
            "Content-Type": "application/json"
        },
    });
    return res
}
export const addRecordingToVumu = async (req) => {
    const res = await axios.post(`${config.urls.vumuserverless}/add-recording`, req, {
        headers: {
            "Content-Type": "application/json"
        },
    });
    return res
}

export const getProjectFromServer = async (payload) => {
    const state = index.getState();
    const token = state.userReducer.userDetails?.jwtToken;
    const res = await callNodeServerApi({url: API_PATH.FETCH_PROJECT, payload, method: 'POST', token})
    return res
}

export const addProjectKeevi = async (req, token) => {
    const state = index.getState();
    const userDetails = state.userReducer.userDetails;

    const res = await axios.post(`${config.urls.dashboardapiurl}/projects/add-project`, req, {
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${userDetails.jwtToken}`
        },
    });
    return res
}
export const updateProjectKeevi = async (req, token) => {
    const state = index.getState();
    const userDetails = state.userReducer.userDetails;

    const res = await axios.post(`${config.urls.dashboardapiurl}/projects/update-project`, req, {
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${userDetails.jwtToken}`
        },
    });
    return res
}

export const saveAsTemplate = async (req) => {
    if (config.urls.serverurl) {
        const res = await axios.post(`${config.urls.serverurl}/metadata/templates`, req, {
            headers: {
                "Content-Type": "application/json"
            },
        });
        return res.data
    }
}

export const getWorkspaceApi = async (payload, token) => {
    const res = await callNodeServerApi({url: API_PATH.GET_WORKSPACE, payload, method: 'POST', token})
    return res
}

export const getProfileApi = async (payload, token) => {
    const res = await callNodeServerApi({url: API_PATH.GET_PROFILE, payload, method: 'POST', token})
    return res
}