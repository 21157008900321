import { SET_INTEGRATION, SET_PROFILE } from "../../constants/ActionTypes";


const initialState = {
    profile: null,
    selectedIntegration: {},
};


const shareReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_PROFILE: {
            return { ...state, profile: { ...action.payload } }
        }
        case SET_INTEGRATION: {
            return { ...state, selectedIntegration: action.payload }
        }
        default:
            return state;
    }
};


export default shareReducer;
