import X_ICON from "../assets/images/socialX.png";
import TT_ICON from "../assets/images/socialTiktok.png";
import IG_ICON from "../assets/images/instagramSocial.png";
import FB_ICON from "../assets/images/socialFacebook.png";
import YT_ICON from "../assets/images/youtubeSocial.png";

export const MAX_TRACK_START_GAP = 4
export const MAX_ELEMENT_GAP = 8
export const MAX_MONTH_SPAN = 8
export const TIMELINE_INCREMENT_INTERVAL = 0.25;
export const TIMELINE_VIDEO_TRACK_THUMBNAIL_WIDTH = 100;
export const SAVE_PROJECT_PROGRESS_INTERVAL = 20000;
export const DEFAULT_VIDEO_RATIO = 16/9;
export const DEFAULT_AUDIO_THUMBNAIL_HEIGHT = 480;

/**
 * Sample Video Array
 */
export const VIDOES = [
    {
        thumbnail: 'https://i.vimeocdn.com/video/586506729_640x360.jpg',
        src: 'https://player.vimeo.com/external/178783049.sd.mp4?s=6601d5e2503194fcbbb429a6f83cc17f8ce5fd56&profile_id=164',
        start: 0,
        end: 60,
        duration: 60,
        currentDuration: 60,
        s3Src: 'https://player.vimeo.com/external/178783049.sd.mp4?s=6601d5e2503194fcbbb429a6f83cc17f8ce5fd56&profile_id=164',
        x: 0,
        y: 0,
    },
    {
        thumbnail: 'https://i.vimeocdn.com/video/529775728_640x360.jpg',
        src: 'https://player.vimeo.com/external/135653517.mobile.mp4?s=50bba47c8366d45959464f574153b19dd7c5da40&profile_id=116',
        start: 0,
        end: 0,
        duration: 31,
        s3Src: 'https://player.vimeo.com/external/135653517.mobile.mp4?s=50bba47c8366d45959464f574153b19dd7c5da40&profile_id=116',
        x: 0,
        y: 0,
    }
]

export const DEFAULT_REWIND_FORWARD_INTERVAL_VALUE_ = 5
export const FIXED_CONTROL_HEIGHT = 60
export const FIXED_EMPTY_SPACE_ZONE_BETWEEN_EDITOR_TIMELINE = 60
export const FIXED_WIDTH_PADDING = 100
export const TRANSPARENT_BACKGROUND_COLOUR = '#000000'
export const FADE_ANIMATION_DELTA = 1;
export const CLOUDFRONT = 'https://media.keevi.io/';
export const GIPHY_API_KEY = "MNkHxYRoYrmNZZz3zWn41aWoT8GEs4sQ";
export const PANELS = {
    NONE: 0,
    TEXT: 1,
    STICKERS: 2,
    VIDEO: 3,
    SUBTITLES: 4,
    MEDIA: 5,
    SETTINGS: 6,
    ITEMSETTINGS: 7
}
export const IMAGE_DEFAULT_DIMENSIONS = {
    WIDTH: 300,
    HEIGHT: 300
}
export const AUDIO = "AUDIO"
export const VIDEO = "Video"
export const TEXT = "Text"
export const GIF = "Gif"
export const EMOJI = "Emoji"
export const IMAGE = "Image"
export const SUBS = "Subs"
export const EDITTEXT = "EDIT"
export const TIMEUPDATED = "TIMEUPDATED"
export const SET_SELECTED = "SET_SELECTED"
export const IN_PROGRESS = "IN_PROGRESS"
export const COMPLETED = "COMPLETED"
export const FAILED = "FAILED"
export const TRANSCRIPTION = "TRANSCRIPTION"
export const RENDERING = "RENDERING"
export const SUBS_TEXT_SAMPLE = "And he says that one day it could cure everything from paralysis toe blindness. And he says that one day"
export const MEDIA = "Media"
export const MAXIMUM_VIDEO_SIZE = 104857600 //bytes 100mb
export const MAXIMUM_VIDEO_TIME = 1200 // seconds 20 minutes

export const DEFAULT_TIMELINE_ZOOM_FACTOR = 10;
export const DEFAULT_TIMELINE_RULER_ZOOM_FACTOR = 5;
export const DEFAULT_TIMELINE_RULER_ZOOM_FACTOR_THRESHOLD = 4;
export const DEFAULT_TIMELINE_RULER_SEGMENT = 10;
export const DEFAULT_TIMELINE_RULER_UNIT = 10;
export const DEFAULT_ZOOM_PERCENTAGE = 100;
export const MIN_ZOOM_PERCENTAGE = 25;
export const MAX_ZOOM_PERCENTAGE = 400;

export const TIMELINE_LEFT_PANEL_WIDTH = 70;
export const TIMELINE_ADD_VIDEO_BUTTON_WIDTH = 72;

export const DIMENSION_ENUM = {
    "0:0": 1,// default ratio of the video
    "1:1": 2,// for ratio 1:1
    "16:9": 3,// for ratio 16:9
    "9:16": 4,// for ratio 9:16
    "4:5": 5,// for ratio 4:5
    "5:4": 6 // for ratio 5:4
}

export const VIDEO_TRANSFORMATION = {
    NONE: 0,
    FIT: 1,
    FILL: 2
}

export const ELEMENT_POSITION = {
    CENTER: "CENTER",
    TOP: "TOP",
    LEFT: "LEFT",
    BOTTOM: "BOTTOM",
    RIGHT: "RIGHT",
    TOP_LEFT: "TOP LEFT",
    TOP_RIGHT: "TOP RIGHT",
    BOTTOM_LEFT: "BOTTOM_LEFT",
    BOTTOM_RIGHT: "BOTTOM_RIGHT"
}

export const TRANSFORMATION = {
    ROTATE: 1,
    RESIZE: 2,
    DRAG: 3
}
export const textType = {
    HEADING: "HEADING",
    SUB_HEADING: "SUB_HEADING",
    BODY_TEXT: "BODY_TEXT",
    ANIMATION: "ANIMATION"
}

export const RENDERING_PRESETS = [{
        'quality': 3, // LOW
        'compression': 2, // HIGH
        'fps': 25, // Frames per second
        'target_resolution': '720P',
        'value': 'draft',
        'label': 'Draft'
    },{
        'quality': 2, // High
        'compression': 3, // Low
        'fps': 60, // Frames per second
        'target_resolution': '1080P',
        'value': 'high_quality',
        'label': 'High Quality'
    },{
        'quality': 1, // Medium
        'compression': 1, // Medium
        'fps': 30, // Frames per second
        'target_resolution': '720P',
        'value': 'youtube',
        'label': 'Youtube'
    },{
        'quality': 1, // High
        'compression': 1, // Medium
        'fps': 30, // Frames per second
        'target_resolution': '720P',
        'value': 'facebook',
        'label': 'Facebook'
    },{
        'quality': 1, // High
        'compression': 1, // Medium
        'fps': 30, // Frames per second
        'target_resolution': '1080P',
        'value': 'instagram',
        'label': 'Instagram'
    },{
        'quality': 1, // High
        'compression': 1, // Medium
        'fps': 30, // Frames per second
        'target_resolution': '720P',
        'value': 'custom',
        'label': 'Custom'
    }
]

export const RESOLUTIONS = [
    { label: '480P', value: "480P" },
    { label: '720P', value: "720P" },
    { label: '1080P', value: "1080P" }
]

export const COMPRESSION = [
    { label: 'Low', value: 3 },
    { label: 'Medium', value: 1 },
    { label: 'High', value: 2 }
]

export const QUALITY = [
    { label: 'Low', value: 3 },
    { label: 'Medium', value: 1 },
    { label: 'High', value: 2 }
]

export const FRAME_RATE = [
    { label: '25', value: 25 },
    { label: '30', value: 30 },
    { label: '60', value: 60 }
]

export const HTTP_REQUEST_STATUS = {
    PROCESSING: 'processing',
    ERROR: 'error',
    SUCCESS: 'success'
}

export const TEMPLATE_CATEGORY_TYPES = [
    {
        label: 'All',
        value: 'all',
    },
    {
        label: 'Birthday',
        value: 'birthday',
    },
    {
        label: 'Wedding',
        value: 'wedding',
    },
    {
        label: 'Motivation',
        value: 'motivation',
    },
    {
        label: 'Technology',
        value: 'technology'
    },
    {
        label: 'Design',
        value: 'design'
    }
]

export const AWS_COGNITO_SIGNIN_USER_ADMIN = 'aws.cognito.signin.user.admin';
export const COGNITO_GROUPS = {
    ADMIN: 'Admin'
}

export const API_PATH = {
  FETCH_PROJECT: "/projects/fetch-project",
  GET_WORKSPACE: "/workspace/get-workspace",
  GET_PROFILE: "/profile/get-profile",
  GET_RENDER_PAYLOADS: "/render/get-payloads",
  START_RENDER_JOB: "/render/render-job",
  DOWNLOAD_FILE: "/download/file",
  PUBLISH_TWEET: "/share/twitter-tweet",
  PUBLISH_TIKTOK_VIDEO: "/share/tiktok-publish-video",
  PUBLISH_INSTAGRAM_VIDEO: "/share/publish-to-instagram",
  PUBLISH_TO_FACEBOOK: "/share/publish-to-facebook",
  PUBLISH_TO_YOUTUBE: "/share/publish-to-youtube",
  TIKTOK_CREATER_INFO: "/share/tiktok-creator_info",
  TIKTOK_OAUTH: "/share/tiktok-oauth",
  META_OAUTH: "/share/meta-app-oauth",
  YOUTUBE_OAUTH: "/share/youtube-oauth",
  LIST_INSTAGRAM_ACCOUNTS: "/share/list-instagram-accounts",
  LIST_FACEBOOK_PAGES: "/share/list-facebook-pages",
  TWITTER_OATH: "/share/twitter-oauth-verifier/",
  TWITTER_REQUEST_TOKEN: "/share/twitter-request-token",
  TIKTOK_MUSIC_CONFIRMATION:
    "https://www.tiktok.com/legal/page/global/music-usage-confirmation/en",
  TIKTOK_BRAND_CONTENT_POLICY:
    "https://www.tiktok.com/legal/page/global/bc-policy/en",
    DOWNLOAD_ZIP: "download/download-zip",
};

export const HTTP_STATUS = {
  OK: 200,
  GONE: 410,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
};

export const VIDEO_LENGTH_LIMIT_IN_SECONDS = {
  TIKTOK: 600,
  INSTAGRAM: 600,
  FACEBOOK: 1200,
  YOUTUBE: 900,
  TWITTER: 140,
};

export const DESCRIPTION_CHARACTER_LIMIT = {
  TIKTOK: 2200,
  INSTAGRAM: 2200,
  FACEBOOK: 5000,
  YOUTUBE: 5000,
  TWITTER: 280,
};

export const TITLE_CHARACTER_LIMIT = {
    YOUTUBE: 100,
  };

export const TIKTOK_PERMISSIONS = ['Comment', 'Duet', 'Stitch'];
export const YT_PRIVACY_STATUS = [{label:"Public", value:"public"}, {label:"Private", value:"private"}, {label:"Unlisted", value:"unlisted"}];
export const EXP_TIME_LEFT = 60000; //one minute before token is expired

export const integrationsData = [
  {
    name: "twitter",
    icon: X_ICON,
    description_limit: DESCRIPTION_CHARACTER_LIMIT.TWITTER,
    video_limit: VIDEO_LENGTH_LIMIT_IN_SECONDS.TWITTER,
  },
  {
    name: "tiktok",
    icon: TT_ICON,
    description_limit: DESCRIPTION_CHARACTER_LIMIT.TIKTOK,
    video_limit: VIDEO_LENGTH_LIMIT_IN_SECONDS.TIKTOK,
  },
  {
    name: "instagram",
    icon: IG_ICON,
    description_limit: DESCRIPTION_CHARACTER_LIMIT.INSTAGRAM,
    video_limit: VIDEO_LENGTH_LIMIT_IN_SECONDS.INSTAGRAM,
  },
  {
    name: "facebook",
    icon: FB_ICON,
    description_limit: DESCRIPTION_CHARACTER_LIMIT.FACEBOOK,
    video_limit: VIDEO_LENGTH_LIMIT_IN_SECONDS.FACEBOOK,
  },
  {
    name: "youtube",
    icon: YT_ICON,
    title_limit: TITLE_CHARACTER_LIMIT.YOUTUBE,
    description_limit: DESCRIPTION_CHARACTER_LIMIT.YOUTUBE,
    video_limit: VIDEO_LENGTH_LIMIT_IN_SECONDS.YOUTUBE,
  },
];

export const TOKEN_EXPIRED = 'TOKEN_EXPIRED';
export const FB_PAGE_PUBLISH_INFO = "Make sure that your Facebook account has a role of Admin if it's a classic Page. And Facebook access with full control on a new Page type."

