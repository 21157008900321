import {
    SET_UPLOAD_PROGRESS,
    SET_REDUX_FROM_STATE_VIDEO,
    SET_UPLOAD_STATUS,
    SET_FILE_INFO,
    SET_SHOW_UPLOAD_SUCCESS_MESSAGE,
    SET_MEDIA_LIST,
    ADD_VIDEO_OBJECT,
    MODIFY_VIDEO_OBJECT
} from '../constants/ActionTypes';

export const addVideoObject = payload => dispatch => {
    dispatch({ type: ADD_VIDEO_OBJECT, payload });
}

export const modifyVideoObject = payload => dispatch => {
    dispatch({ type: MODIFY_VIDEO_OBJECT, payload });
}

/**
 * setting video s3 progress
 */
export const updateVideoUploadProgress = progress => dispatch => {
    dispatch({ type: SET_UPLOAD_PROGRESS, payload: progress });
}

export const setReduxFromStateVideo = (payload) => dispatch => {
    dispatch({ type: SET_REDUX_FROM_STATE_VIDEO, payload })
}

export const setUploadStatus = (payload) => dispatch => {
    dispatch({ type: SET_UPLOAD_STATUS, payload });
}

export const setFileInfo = (payload) => async (dispatch) => {
    return await dispatch({ type: SET_FILE_INFO, payload });
}

export const setShowUploadSuccessMessage = (payload) => dispatch => {
    dispatch({ type: SET_SHOW_UPLOAD_SUCCESS_MESSAGE, payload });
}

export const setMediaList = (payload) => dispatch => {
    dispatch({ type: SET_MEDIA_LIST, payload });
}

