import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import * as ActionTypes from '../../../constants/ActionTypes';
import { socialAuthTwitter } from '../../../api/socialShare-apis';

export function* socialShareTwitterSaga() {
    yield takeEvery(ActionTypes.SOCIAL_SHARE_TWITTER, function* (payload) {
        const req_payload = payload['payload'];

        try {
            const result = yield call(socialAuthTwitter, req_payload);
           console.log(result)
        } catch (error) {
            console.log(error)
        }
    });
}

export default function* socialShareSaga() {
    yield all([
        fork(socialShareTwitterSaga)
    ]);
}
