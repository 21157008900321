import { persistedState } from "../index";
import uploadReducer from './upload';
import tracksReducer from './tracks';
import viewportReducer from "./viewport";
import timelineReducer from './timeline';
import cursorReducer from './cursor';
import controlsReducer from './controls';
import backgroundJobsReducer from './background-jobs';
import appStateInfoReducer from './app-state-info';
import userReducer from "./user";
import renderReducer from "./render";
import connectionReducer from "./connection";
import animationsReducer from './animations';
import subtitlesReducer from './subtitles';
import templateReducer from './template';
import awsReducer from './aws';
import { connectRouter } from "connected-react-router";
import { HYDRATE } from "../../constants/ActionTypes";
import clone from 'lodash/cloneDeep';
import shareReducer from "./share";

const combineReducers = (history) => (state = persistedState, action) => {
    return action.type === HYDRATE
        ? clone(state)
        : ({
            router: connectRouter(history),
            appStateInfoReducer: appStateInfoReducer(state.appStateInfoReducer, action, state),
            backgroundJobsReducer: backgroundJobsReducer(state.backgroundJobsReducer, action, state),
            viewportReducer: viewportReducer(state.viewportReducer, action, state),
            tracksReducer: tracksReducer(state.tracksReducer, action, state),
            controlsReducer: controlsReducer(state.controlsReducer, action, state),
            timelineReducer: timelineReducer(state.timelineReducer, action, state),
            cursorReducer: cursorReducer(state.cursorReducer, action, state),
            uploadReducer: uploadReducer(state.uploadReducer, action, state),
            userReducer: userReducer(state.userReducer, action, state),
            renderReducer: renderReducer(state.renderReducer, action, state),
            animationsReducer: animationsReducer(state.animationsReducer, action, state),
            subtitlesReducer: subtitlesReducer(state.subtitlesReducer, action, state),
            connectionReducer: connectionReducer(state.connectionReducer, action, state),
            templateReducer: templateReducer(state.templateReducer, action, state),
            awsReducer: awsReducer(state.awsReducer, action, state),
            shareReducer: shareReducer(state.shareReducer, action, state)
        });
};

export default combineReducers;
