import { SET_CONNECTED, SET_RE_CONNECTING, CONNECTION_TRIES_RESET } from '../../constants/ActionTypes';

const initState = {
    connected: false,
    tries: 0
}

export default function connection(state = initState, action) {
    switch (action.type) {
        case SET_CONNECTED: {
            return { connected: action.payload.value, reconnecting: false, tries: state.tries + 1 }
        }

        case SET_RE_CONNECTING: {
            return { ...state, connected: false, reconnecting: true }
        }

        case CONNECTION_TRIES_RESET: {
            return { ...state, tries: action.payload || 0 }
        }

        default:
            return state;
    }
}
