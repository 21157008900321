import AppManager from './app-manager';

const appManager = new AppManager();

export const initialize = () => {
    return {
        app: appManager
    }
}

export const exports = {
    app: appManager
}
