/**
 *
 * @param {*} data
 * Default render video model which will contain all the nesassary attributes for the payload used in backend.
 */
export function RenderImageModel(data) {
    this.id = data.id
    this.src = data.file
    this.xpos = data.xPosition
    this.ypos = data.yPosition
    this.duration = data.duration
    this.width = data.currentWidth
    this.height = data.currentHeight
    this.start = data.start
    this.end = data.end
    this.showtime = data.showTime
    this.rotation = data.rotation
    this.level = data.level
    this.type = "IMAGE"
}


