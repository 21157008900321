import {
    UPDATE_USER,
    USER_AUTHENTICATED
} from '../../constants/ActionTypes';

const initState = {
    userDetails: {},
    loggedIn: false,
    authentication: (() => {
            let resolve, reject;
            const promise = new Promise((res, rej) => {
                [resolve, reject] = [res, rej];
            });
            return {promise, reject, resolve};
        }
    )(),
}

export default function userReducer(state = initState, action) {
    switch (action.type) {
        case UPDATE_USER:
            return {
                ...state,
                userDetails: action.payload,
                loggedIn: !!action.payload.jwtToken
            }
        case USER_AUTHENTICATED: {
            if(state.authentication){
                if(action.payload) {
                    return {
                        ...state,
                        authentication: state.authentication.resolve(),
                    }
                } else {
                    return {
                        ...state,
                        authentication: state.authentication.reject()
                    }
                }
            } else {
                return {...state}
            }         
        }


        default:
            return state
    }
}
