import { batch } from 'react-redux';
import * as Constants from "../constants";
import * as ActionTypes from "../constants/ActionTypes";

export const setTimelineDimensions = (payload) => dispatch => {
    payload.width = payload.width - Constants.TIMELINE_LEFT_PANEL_WIDTH - Constants.TIMELINE_ADD_VIDEO_BUTTON_WIDTH;
    batch(() => {
        dispatch({ type: ActionTypes.SET_TIMELINE_DIMENSIONS, payload })
        dispatch(setZoomBaseFactor(payload.width));
    })
}

export const setTimelineMaxDuration = payload => (dispatch, getState) => {
    const timeline = getState().timelineReducer;
    const duration = timeline.maxDuration;
    dispatch({ type: ActionTypes.SET_MAX_DURATION, payload });

    if (duration !== payload && timeline.dimensions.width) {
        dispatch(setZoomBaseFactor(timeline.dimensions.width));
    }
}

export const setZoomBaseFactor = payload => (dispatch, getState) => {
    const store = getState();
    const availableWidth = payload;
    if (availableWidth > 0) {
        const timeline = store.timelineReducer;
        const duration = timeline.maxDuration;
        let zoom = timeline.zoom;

        if (duration && timeline.zoomPercentage === Constants.DEFAULT_ZOOM_PERCENTAGE) {
            zoom = zoom * (availableWidth / (duration * zoom));
            dispatch({ type: ActionTypes.SET_BASE_ZOOM_FACTOR, payload: { zoom } });
        }
    }
}

export const setZoomFactor = (payload) => (dispatch, getState) => {
    batch(() => {
        dispatch({ type: ActionTypes.SET_ZOOM_FACTOR_DISPLAY, payload });

        const timeline = getState().timelineReducer;
        const duration = timeline.maxDuration;

        if (duration && timeline.zoomPercentage === Constants.DEFAULT_ZOOM_PERCENTAGE) {
            dispatch(setZoomBaseFactor(timeline.dimensions.width));
        } else {
            dispatch({ type: ActionTypes.SET_ZOOM_FACTOR, payload });
            // dispatch({ type: ActionTypes.SET_TIMELINE_DIMENSIONS, payload: {
            //     dimensions: {
            //         width: timeline.width,
            //         height: timeline.height
            //     }
            // }})
        }
    });
}
