import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import * as ActionTypes from '../../../constants/ActionTypes';
import { fetchCredentials } from '../../../api/aws-apis';

export function* socialShareTwitterSaga() {
    yield takeEvery(ActionTypes.FETCH_AWS_CREDENTIALS_FROM_SERVER, function* ({ payload, resolve, reject }) {
      try {
          const result = yield call(fetchCredentials, payload);
          if(result && result.data)
            resolve(result.data);
      } catch (error) {
          console.log(error)
          reject(error);
      }
    });
}

export default function* socialShareSaga() {
    yield all([
        fork(socialShareTwitterSaga)
    ]);
}
