import { batch } from 'react-redux';
import * as Constants from '../constants';
import * as ActionTypes from "../constants/ActionTypes";
import { events } from '../store'
import * as utils from '../utils';
import { getTrackAndElementIndex } from "../utils";
import * as helpers from '../utils/index.helper';
import * as StudioActions from "./StudioActions";

export const setSubtitlesStatusLoading = (payload) => dispatch => {
    dispatch({
        type: ActionTypes.SET_SUBTITLES_LOADING_FROM_SERVER, payload: {
            videoId: payload.videoId,
            loading: false
        }
    });
}

export const setSubtitlesReset = (payload) => dispatch => {
    dispatch({ type: ActionTypes.SET_RESET_SUBTITLES, payload });
}

export const setSubtitleStyle = (payload) => dispatch => {
    dispatch({ type: ActionTypes.SET_SUBTITLES_STYLE, payload })
}

export const setVideoToTranscribe = (payload) => dispatch => {
    dispatch({ type: ActionTypes.SET_VIDEO_TO_TRANSCRIBE, payload });

    if (payload) {
        dispatch({ type: ActionTypes.SET_SELECTED_VIDEOS_FOR_TRANSCRIBE, payload });
    }
}

export const setSubtitlesVideosForTranscribe = (payload) => dispatch => {
    dispatch({ type: ActionTypes.SET_SELECTED_VIDEOS_FOR_TRANSCRIBE, payload });
}

export const fetchSubtitlesFromServer = (payload) => dispatch => {
    dispatch({ type: ActionTypes.SET_SUBTITLES_LOADING_FROM_SERVER, payload: { videoId: payload.video_id, loading: true } });
    dispatch({ type: ActionTypes.FETCH_SUBTITLES_FROM_SERVER, payload });
}

export const setSubtitlesCompleted = (payload) => dispatch => {
    dispatch({ type: ActionTypes.SET_SUBTITLES_COMPLETED, payload });
}

export const setVideoSubtitles = (payload) => (dispatch, getState) => {
    const store = getState();
    const videoId = payload.videoId;
    const subtitlesArray = payload.res;
    const selectedVideo = store.subtitlesReducer.videos.find(vid => videoId === vid.id);
    const tracks = store.tracksReducer.tracks;
    const indexes = getTrackAndElementIndex(tracks, { id: selectedVideo.id });

    const result = utils.addSubtitlesToTimeline({
        subtitlesArray: subtitlesArray,
        type: subtitlesArray[0].type ? subtitlesArray[0].type : 'AUTO'
    }, Constants.TIMELINE_INCREMENT_INTERVAL);

    if (result.success) {
        // SETTING SUBTITLE STYLE
        const dimensions = helpers.getTextDimensions(Constants.SUBS_TEXT_SAMPLE, 'normal' + " " + "24px " + 'Open Sans');
        const styles = {
            ...store.subtitlesReducer.subtitleStyle,
            divWidth: (store.viewportReducer.width / 100) * 80,
            divHeight: (store.viewportReducer.height / 100) * 20,
            xPosition: (store.viewportReducer.width / 100) * 10,
            yPosition: (store.viewportReducer.height - (dimensions.height + 30) - 70)
        }

        batch(() => {
            dispatch({ type: ActionTypes.SET_SUBTITLES_STYLE, payload: styles });
            dispatch({ type: ActionTypes.SET_LAYERS_TRACKS, payload: result.tracks });
            dispatch({ type: ActionTypes.SET_SUBTITLES_HASH, payload });
            dispatch({ type: ActionTypes.SET_SUBTITLES_COMPLETED, payload: { videoId: selectedVideo.id } });

            dispatch({ type: ActionTypes.SET_SUBTITLES_COMPLETED, payload: { videoId: selectedVideo.id } });

            const indexes = utils.getSubtitlesTrackAndElementIndexFromVideo(tracks, { id: selectedVideo.id });
            if (indexes.trackIndex !== -1) {
                dispatch(StudioActions.setSelectedLayerElement(tracks[indexes.trackIndex].elements[indexes.elementIndex]));
            }

            events.dispatch_event('hashmap:generate', {
                start: tracks[indexes.trackIndex].elements[indexes.elementIndex].start,
                end: tracks[indexes.trackIndex].elements[indexes.elementIndex].end
            });
        });

        // return Object.assign({}, state, {
        //     tracks: res.tracks,
        //     subtitlesArray: action.payload,
        //     completed: true,
        //     subtitleStyle: res.subtitleStyle
        // })

        // dispatch({ type: ActionTypes.SET_SUBTITLES_HASH, payload });
    }
}
