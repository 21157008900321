import { message } from "antd";
import { batch } from 'react-redux';
import * as AppActions from '../../../actions/AppActions';
import * as ControlsActions from '../../../actions/ControlsActions';
import * as RenderActions from '../../../actions/RenderActions';
import * as StudioActions from '../../../actions/StudioActions';
import * as SubtitlesActions from '../../../actions/SubtitlesActions';
import * as TimelineActions from '../../../actions/TimelineActions';
import * as UploadActions from '../../../actions/UploadActions';
import * as ViewportActions from '../../../actions/ViewPortActions';
import { getProjectProgressApiCall, saveProjectProgressApiCall } from "../../../api/studio-apis";
import { AUDIO, VIDEO } from "../../../constants";
import * as ActionTypes from "../../../constants/ActionTypes";
import * as utils from "../../../utils";
import { events } from "../../../store";

export const fetchMetadata = (payload) => (dispatch) => {
    return new Promise(async (resolve, reject) => {
        dispatch({ type: ActionTypes.STUDIO_STATE_READY, payload: false });
        const { projectId } = payload;

        new Promise(async (res, rej) => {
            const lsData = window.sessionStorage.getItem(projectId);
            if (lsData) {
                try {
                    const result = { metadata: JSON.parse(lsData) };
                    res(result)
                } catch {
                    rej({ metadata: null });
                }
            } else {
                try {
                    const response = await getProjectProgressApiCall(projectId);
                    res({ metadata: response.metaData })
                } catch {
                    rej({ metadata: null });
                }
            }
        }).then(response => {
            const transformedData = transformOnImport(response.metadata);

            /* ^^**^^^^**^^^^**^^ DIASPORA for METADATA ^^**^^^^**^^^^**^^^^**^^ */
            batch(() => {
                dispatch(StudioActions.setReduxFromStateStudio(transformedData.studioInfo));
                dispatch(UploadActions.setReduxFromStateVideo(transformedData.uploadInfo));

                dispatch(ViewportActions.setViewportAspectRatioPreset({ presetId: transformedData.viewportInfo.viewportPresetId }));
                dispatch(ViewportActions.setViewportBackgroundColor({ background: transformedData.viewportInfo.canvasColor }));

                dispatch(RenderActions.setRenderingPreferences(transformedData.renderInfo.renderingPreferences))

                dispatch(ControlsActions.setMute(transformedData.controlsInfo.muted));

                if (transformedData.subtitlesInfo.subtitleStyle)
                    dispatch(SubtitlesActions.setSubtitleStyle(transformedData.subtitlesInfo.subtitleStyle));
                dispatch(SubtitlesActions.setSubtitlesVideosForTranscribe(transformedData.subtitlesInfo.subtitleVideos));
                dispatch(TimelineActions.setTimelineMaxDuration(transformedData.timelineInfo.maxDuration));

                if (transformedData.projectDetailsInfo.projectDetails !== undefined)
                    dispatch(AppActions.setProjectToDashboard(transformedData.projectDetailsInfo.projectDetails));

                // dispatch(AnimationsActions.changeAnimationType(transformedData.animationsInfo.animationTypeRedux));
            });

            events.dispatch_event('hashmap:generate');
            resolve(response);
        }).catch((error) => {
            dispatch(AppActions.setNewProject({ value: true }));
            console.error(error);
            window.sessionStorage.clear();
            reject(error);
        }).finally(() => {
            dispatch({ type: ActionTypes.STUDIO_STATE_READY, payload: true });
        });
    });
}

export const saveProjectProgress = payload => (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
        const state = getState();
        if (state.tracksReducer.tracks) {
            const _payload = {
                id: payload['projectId'],
                userId: payload['userId'],
                metaData: payload['metaData']
            }

            saveProjectProgressApiCall(_payload).then((response) => {
                resolve({ _id: payload['projectId'] });
            }).catch((error) => {
                message.error("Something went wrong while saving project.");
                reject(error);
            });
        }
    })
}

const transformOnImport = ({ id, tracks, maxDuration, selectedItemModel, animationTypeRedux, muted, mediaList, subtitleStyle, canvasColor, canvasWidth, canvasHeight, viewportPresetId, renderingPreferences, subtitleVideos, projectDetails }) => {
    const infoStudio = { tracks, selectedItemModel, canvasWidth, canvasHeight }
    const infoRender = { renderingPreferences };
    let infoUpload = { mediaList, videoObj: null }
    const infoViewport = { viewportPresetId, canvasColor }
    const infoControls = { muted }
    const infoTimeline = { maxDuration }
    const infoAnimations = { animationTypeRedux }
    const infoSubtitles = { subtitleStyle, subtitleVideos }
    const infoProjectDetails = { projectDetails }

    for (let i = 0; i < tracks.length; i++) {
        let trackObj = tracks[i];
        if (trackObj.type === VIDEO) {
            for (let j = 0; trackObj && trackObj.elements && j < trackObj.elements.length; j++) {
                let videoObj = trackObj.elements[j];
                videoObj.videoElement = utils.createVideoElement(videoObj, muted);
                infoUpload.videoObj = infoUpload.videoObj === null ? [videoObj] : [...infoUpload.videoObj, videoObj];
            }
        }
        if (trackObj.type === AUDIO) {
            for (let j = 0; trackObj && trackObj.elements && j < trackObj.elements.length; j++) {
                let audioObj = trackObj.elements[j];
                audioObj.videoElement = utils.createVideoElement(audioObj, muted);
            }
        }
    }

    return {
        studioInfo: infoStudio,
        renderInfo: infoRender,
        uploadInfo: infoUpload,
        viewportInfo: infoViewport,
        controlsInfo: infoControls,
        timelineInfo: infoTimeline,
        animationsInfo: infoAnimations,
        subtitlesInfo: infoSubtitles,
        projectDetailsInfo: infoProjectDetails
    }
}
