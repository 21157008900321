import * as ActionTypes from '../../constants/ActionTypes';

const initState = {
    play: false,
    muted: false,

}

export default function tracksReducer(state = initState, action) {
    // console.log(action.type);
    switch (action.type) {
        case ActionTypes.PLAY_VIDEO:
            return {
                ...state,
                play: action.payload,
            }
        case ActionTypes.SET_MUTED:
            return {
                ...state,
                muted: action.payload,
            }
        default:
            return state
    }
}
