import axios from 'axios';
import { config } from '../config';

export const fetchCredentials = async (req) => {
    if (config.urls.dashboardapiurl) {
        const res = await axios.get(`${config.urls.dashboardapiurl}/c/get-credentials`, req, {
            headers: {
                "Content-Type": "application/json"
            },
        });
        return res.data
    }
}
