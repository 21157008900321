import { PANELS } from "../../constants";
import {CHANGE_PANEL_ID, SET_PROJECT_ID, SET_NEW_PROJECT} from "../../constants/ActionTypes";
import * as ActionTypes from "../../constants/ActionTypes";

const initState = {
    panelId: PANELS.NONE,
    projectId: undefined,
    projectDetails: '',
    ready: false,
    new: false,
    saving: false,
    currentWorkspace: null
}

export default function appStateInfo(state = initState, action) {
    switch (action.type) {
        case SET_PROJECT_ID:
            return {
                ...state,
                projectId: action.payload.projectId
            }

        case SET_NEW_PROJECT:
            return {
                ...state,
                new: action.payload.value,
                projectDetails: { name: `Keevi Project ${new Date().getTime()}` },
            }

        case CHANGE_PANEL_ID: {
            return {
                ...state,
                panelId: action.payload
            }
        }

        case ActionTypes.PROGRESS_SAVING: {
            return {
                ...state,
                saving: true
            }
        }

        case ActionTypes.STUDIO_STATE_READY: {
            return {
                ...state,
                ready: action.payload
            }
        }

        case ActionTypes.PROGRESS_SAVED_SUCCESSFULLY: {
            return {
                ...state,
                saving: false
            }
        }

        case ActionTypes.SET_PROJECT_DETAILS: {
            return {
                ...state,
                projectDetails: action.payload
            }
        }
        case ActionTypes.SET_WORKSPACE: {
            return {
                ...state,
                currentWorkspace: action.payload
            }
        }
        default:
            return state
    }
}

