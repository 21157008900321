import { SUBS } from "../constants";


export function SubtitlesStyleModel(data) {
    this.fontFamily = data.fontFamily || "Open Sans";
    this.modelType = SUBS;
    this.fontSize = parseInt(data.fontSize) || 24;
    this.fontWeight = data.fontWeight || "normal";//'normal', 'bold', 'bolder', 'lighter' and '100', '200', '300', '400', '500', '600', '700', '800' or '900'
    this.fontStyle = data.fontStyle || "normal";// The font style('normal', 'italic' or 'oblique')
    this.color = data.color || "#fffbf5"; //pixi has keyword fill(for the font-family)
    this.align = data.align || "center";// default center align
    this.height = data.height || 0;
    this.divWidth = data.divWidth || 200;
    this.divHeight = data.divHeight || 50;
    this.xScale = data.xScale || 1;
    this.yScale = data.yScale || 1;
    this.xPosition = data.xPosition || 10;
    this.yPosition = data.yPosition || 10;
    this.xTimelinePosition = data.xTimelinePosition || 0; //for timeline
    this.yTimelinePosition = data.yTimelinePosition || 0; //for timeline
    this.rotation = data.rotation || 0;
    this.anchor = data.anchor || 0;// set the position of text.
    this.background = data.background || '#fefefe00';
    this.fillGradientType = data.fillGradientType || 'LINEAR_HORIZONTAL_NOREPEAT';
    this.fontVariant = data.fontVariant || 'normal';
    this.wordWrapWidth = data.wordWrapWidth || 500;
    this.wordWrap = data.wordWrap || false;
    this.animation = data.animation || false;
    this.animationType = data.animationType || 0;
    this.animationColour = data.animationColour || '#fece00';
    this.currentResizeDeltaWidth = data.currentResizeDeltaWidth || 0;
    this.style = data.style || {
    };
    this.letterSpacing = parseInt(data.letterSpacing) || 0;
    this.lineSpacing = parseFloat(data.lineSpacing) || 10;
}
