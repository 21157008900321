import * as ActionTypes from "../../constants/ActionTypes";

const initState = {
    animationTypeRedux: {}
}

export default function animationsReducer(state = initState, action) {
    // console.log(action.type);
    switch (action.type) {
        case ActionTypes.CHANGE_ANIMATION_TYPE:
            return {
                ...state,
                animationTypeRedux: {
                    ...state.animationTypeRedux,
                    [action.payload.id]: action.payload.type
                }
            }

        default:
            return state
    }
}
