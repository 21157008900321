import Dexie from "dexie";

const url_DB = new Dexie("bitesyzed_url_db");
url_DB.version(1).stores({
  renderedUrls: "++id,data",
});

export const saveQueue = [];

const processSaveQueue = async () => {
  if (saveQueue.length === 0) return;

  const { id, payload } = saveQueue.shift();

  try {
    const _data = await getRenderedUrls(id);
    const data = { ...payload, ..._data.data };
    await url_DB.renderedUrls.put({ id, data });
  } catch (error) {
    console.error("Error saving JSON object in processQueue:", error);
  }
};

(async function () {
  setInterval(processSaveQueue, 1000);
})();

export const getRenderedUrls = async (id) => {
  try {
    const result = await url_DB.renderedUrls.where("id").equals(id).first();
    if (result) {
      return result;
    } else {
      return {};
    }
  } catch (error) {
    console.error("Error getting JSON object:", error);
    return null;
  }
};

export const deleteRenderedUrls = async () => {
  try {
    await url_DB.renderedUrls.clear();
    console.log("All records from renderedUrls have been deleted.");
  } catch (error) {
    console.error("Failed to clear records from renderedUrls:", error);
  }
};
