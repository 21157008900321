import { datadogLogs } from "@datadog/browser-logs";
import { datadogRum } from "@datadog/browser-rum";
import { APP } from "../config";

export function initDatadog() {
  if (process.env.REACT_APP_ENV === "prod") {
    datadogLogs.logger.setLevel('error')
    // RUM Datadog
    datadogRum.init({
      clientToken: APP.DATADOG_RUM_CLIENT_TOKEN,
      applicationId: APP.DATADOG_RUM_APP_ID,
      site: 'datadoghq.eu',
      service: APP.TITLE,
      env: process.env.REACT_APP_ENV,
      trackInteractions: true,
    });
    datadogRum.startSessionReplayRecording();
    // Browser Log Collection
    datadogLogs.init({
      clientToken: APP.DATADOG_LOG_CLIENT_TOKEN,
      site: 'datadoghq.eu',
      forwardErrorsToLogs: true,
      service: APP.TITLE,
      env: process.env.REACT_APP_ENV,
      beforeSend: () => {
        datadogLogs.addLoggerGlobalContext(
          "userId",
          localStorage.getItem('user')
        );
      },
    });
  }
}
