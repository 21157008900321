import { IN_PROGRESS } from "../constants";
import { getLocalUser } from "../utils/index.helper";

export function BackgroundJobModel(data) {
    this.videoId = data.videoId || 'undefined'
    this.jobId = data.jobId || 'undefined'
    this.userId = data.userId || getLocalUser().userId
    this.projectId = data.projectId || 'undefined'
    this.jobStatus = data.jobStatus || IN_PROGRESS
    this.type = data.type
}


