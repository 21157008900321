import { SET_AWS_CREDENTIALS } from '../../constants/ActionTypes';

const initState = {}

export default function awsReducer(state = initState, action) {
    switch (action.type) {
        case SET_AWS_CREDENTIALS: {
          return {
            ...state,
            ...action.payload,
          }
        }

        default:
            return state
    }
}
