import * as Constants from "../constants";
import { AUDIO, EMOJI, GIF, IMAGE, PANELS, SUBS, TEXT, VIDEO } from '../constants';
import {
    CHANGE_PANEL_ID,
    COMPUTE_ELEMENTS_RELATIVE_TRANSFORMATION, DELETE_LAYER_ELEMENT, REMOVE_VIDEO_OBJECT, REMOVE_VIDEO_SUB_OBJECT, SELECTED_OBJECT, SET_LAYERS_TRACKS, SET_MAX_DURATION, SET_PROJECT_ID, SET_REDUX_FROM_STATE_STUDIO, UPDATED_TEXT, YOUTUBE_DOWNLOAD_PROGRESS
} from '../constants/ActionTypes';
import Thunks from '../store/middlewares/thunks';
import * as utils from '../utils';
import { events } from '../store';



export const setLayersTracks = tracks => dispatch => {
    dispatch({ type: SET_LAYERS_TRACKS, payload: tracks });
}
export const ytdProgress = payload => dispatch => {
    dispatch({ type: YOUTUBE_DOWNLOAD_PROGRESS, payload })
}

export const updateSelectedText = payload => dispatch => {
    dispatch({ type: UPDATED_TEXT, payload });
}

export const deleteItem = payload => (dispatch, getState) => {
    if (payload.elementToRemove.type === Constants.SUBS) {
        dispatch({ type: REMOVE_VIDEO_SUB_OBJECT, payload });
        dispatch({ type: CHANGE_PANEL_ID, payload: Constants.PANELS.NONE });
    } else {
        dispatch({ type: DELETE_LAYER_ELEMENT, payload });
    }

    const store = getState();
    dispatch({ type: SET_MAX_DURATION, payload: utils.getMaxDuration(store.tracksReducer.tracks) });

    if (payload.elementToRemove.type === VIDEO) {
        dispatch({ type: REMOVE_VIDEO_OBJECT, payload: { id: payload.elementToRemove.id } });
        dispatch({ type: CHANGE_PANEL_ID, payload: Constants.PANELS.NONE });
    }

    if (payload.elementToRemove.type === Constants.SUBS && payload.elementToRemove.subtitles) {
        const el = utils.getTrackAndElementIndex(store.tracksReducer.tracks, { id: payload.elementToRemove.videoId });
        if (el.trackIndex !== -1) {
            payload.elementToRemove.start = store.tracksReducer.tracks[el.trackIndex].elements[el.elementIndex].start;
            payload.elementToRemove.end = store.tracksReducer.tracks[el.trackIndex].elements[el.elementIndex].end;
        }
    }

    events.dispatch_event('hashmap:generate', {
        start: payload.elementToRemove.start,
        end: payload.elementToRemove.end
    });
}

export const computeElementsTransformationWithRespectToViewport = (payload) => dispatch => {
    dispatch({ type: COMPUTE_ELEMENTS_RELATIVE_TRANSFORMATION, payload });

}

export const setSelectedLayerElement = payload => async (dispatch, getState) => {
    const store = getState();

    if (payload) {
        const panelId = getRelatedPanel(payload.modelType);

        if (panelId !== store.appStateInfoReducer.panelId) {
            dispatch({ type: CHANGE_PANEL_ID, payload: panelId });
        }
    }

    dispatch({ type: SELECTED_OBJECT, payload });
}

export const setReduxFromStateStudio = (payload) => dispatch => {
    dispatch({ type: SET_REDUX_FROM_STATE_STUDIO, payload });
}

export const fetchMetadata = (payload) => dispatch => {
    dispatch({ type: SET_PROJECT_ID, payload });
    return dispatch(Thunks.metadata.fetchMetadata(payload));
}

const getRelatedPanel = (modelType) => {
    switch (modelType) {
        case TEXT:
            return PANELS.TEXT;
        case IMAGE:
        case GIF:
        case EMOJI:
        case VIDEO:
        case AUDIO:
            return PANELS.ITEMSETTINGS;
        case SUBS:
            return PANELS.SUBTITLES;
        default:
            return PANELS.NONE;
    }
}
