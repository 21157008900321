import toInteger from "lodash/toInteger";
import * as Constants from "../../constants";
import * as ActionTypes from '../../constants/ActionTypes';
import * as utils from '../../utils';

const initState = {
    tracks: [],
    selectedItemModel: null,
    youtubeDownloadProgress: null
}

export default function tracksReducer(state = initState, action, store) {
    switch (action.type) {
        case ActionTypes.SET_LAYERS_TRACKS: {
            const sorted = action.payload.sort(utils.levelSort);
            return {
                ...state,
                tracks: sorted
            }
        }
        case ActionTypes.DELETE_LAYER_ELEMENT: {
            const _state = state;
            let tracks = _state.tracks;
            const elementToRemove = action.payload.elementToRemove;
            let indexes = utils.getTrackAndElementIndex(tracks, elementToRemove);

            if (elementToRemove.type !== Constants.VIDEO && elementToRemove.type !== Constants.SUBS && elementToRemove.type !== Constants.AUDIO) {
                tracks.splice(indexes.trackIndex, 1);

            } else {
                let track = tracks[indexes.trackIndex];
                if (elementToRemove.type === Constants.VIDEO || elementToRemove.type === Constants.AUDIO) {
                    utils.removeVideoElementById(elementToRemove.videoElementId);

                    const subParentElements = tracks.filter(track => track.type === Constants.SUBS).map(t => t.elements).flat();
                    const idx = subParentElements.findIndex(el => el.videoId === elementToRemove.id)
                    if(idx !== -1) {
                        tracks = utils.deleteSub(tracks, subParentElements[idx]);
                        indexes = utils.getTrackAndElementIndex(tracks, elementToRemove);
                    }
                }

                track.elements.splice(indexes.elementIndex, 1);
                if (track.elements.length === 0) {
                    tracks.splice(indexes.trackIndex, 1);
                }
            }

            _state.tracks = utils.resetTrackAndElementsIndexes(tracks.reverse()).sort(utils.levelSort);

            return {
                ..._state,
                selectedItemModel: null
            }
        }
        case ActionTypes.REMOVE_VIDEO_SUB_OBJECT: {
            const tracks = utils.deleteSub([ ...state.tracks ], action.payload.elementToRemove);
            return { ...state, tracks }
        }
        case ActionTypes.COMPUTE_ELEMENTS_RELATIVE_TRANSFORMATION: {
            const _state = { ...state }
            computeElementsTransformations(store.viewportReducer, _state);
            return _state;
        }
        case ActionTypes.SELECTED_OBJECT:
            state.selectedItemModel = Object.assign({}, action.payload);
            return { ...state };

        case ActionTypes.SET_REDUX_FROM_STATE_STUDIO:
            return { ...state, ...action.payload }

        case ActionTypes.YOUTUBE_DOWNLOAD_PROGRESS:
            return {...state, youtubeDownloadProgress: action.payload};
        default:
            return state
    }
}

const computeElementsTransformations = (viewport, state) => {
    const { tracks, canvasWidth, canvasHeight } = state;
    const oldWidth = (canvasWidth || viewport.width);
    const oldHeight = (canvasHeight || viewport.height);
    const widthRatio = viewport.width / (canvasWidth || viewport.width);
    const heightRatio = viewport.height / (canvasHeight || viewport.height);

    let ratioMultiplier = 1;
    if(Math.abs(viewport.width - oldWidth) > Math.abs(viewport.height - oldHeight)) {
        ratioMultiplier = widthRatio;
    } else {
        ratioMultiplier = heightRatio;
    }

    for (let i = 0; tracks && i < tracks.length; i++) {
        let tempObj = tracks[i];
        for (let j = 0; j < tempObj.elements.length; j++) {
            let element = tempObj.elements[j];

            const isCentered = checkIfCentered({ width: oldWidth, height: oldHeight }, element);

            if (tempObj.type === Constants.VIDEO) {
                if ((element.currentWidth === 0 && element.currentHeight === 0)) {
                    let projectionRatio = 1;
                    if (element.originalHeight > element.originalWidth) { // portrait
                        projectionRatio = viewport.height / element.originalHeight;
                        let newWidth = Math.round(element.originalWidth * projectionRatio);
                        if (newWidth > viewport.width)
                            projectionRatio = viewport.width / element.originalWidth;
                    } else { // landscape
                        projectionRatio = viewport.width / element.originalWidth;
                        let newHeight = Math.round(element.originalHeight * projectionRatio);
                        if (newHeight > viewport.height)
                            projectionRatio = viewport.height / element.originalHeight;
                    }
                    element.currentWidth = element.originalWidth * projectionRatio;
                    element.currentHeight = element.originalHeight * projectionRatio;
                }
                else {
                    element.currentWidth = element.currentWidth * ratioMultiplier;
                    element.currentHeight = element.currentHeight * ratioMultiplier;
                }
            }
            else {
                element.divHeight = element.divHeight * ratioMultiplier;
                element.divWidth = element.divWidth * ratioMultiplier;
            }

            if(tempObj.type === Constants.TEXT) {
                element.fontSize = toInteger(element.fontSize * ratioMultiplier);
            }

            element.xPosition = Math.round(element.xPosition * (widthRatio));
            element.yPosition = Math.round(element.yPosition * (heightRatio));

            const w = tempObj.type === Constants.VIDEO ? element.currentWidth : element.divWidth;
            const h = tempObj.type === Constants.VIDEO ? element.currentHeight : element.divHeight;

            if(isCentered.x)
                element.xPosition = (viewport.width / 2) - (w / 2);
            if(isCentered.y)
                element.yPosition = (viewport.height / 2) - (h / 2);
        }
    }

    state.canvasWidth = viewport.width;
    state.canvasHeight = viewport.height;
}

const checkIfCentered = (viewport, element) => {
    const center = { x: viewport.width / 2, y: viewport.height / 2 };

    const w = element.type === Constants.VIDEO ? element.currentWidth : element.divWidth;
    const h = element.type === Constants.VIDEO ? element.currentHeight : element.divHeight;

    const elCenter = { x: (element.xPosition + (w / 2)), y: (element.yPosition + (h / 2)) };

    return {
        x: Math.abs(center.x - elCenter.x) < 2,
        y: Math.abs(center.y - elCenter.y) < 2
    };
}
