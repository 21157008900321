import * as ActionTypes from '../../constants/ActionTypes';

const initState = {
    backgroundJobs: [],
}

export default function tracksReducer(state = initState, action, store) {
    switch (action.type) {
        case ActionTypes.ADD_BACKGROUND_JOB:
            let array = state.backgroundJobs
            array.push(action.payload)
            return {
                ...state,
                backgroundJobs: array
            }

        case ActionTypes.REMOVE_BACKGROUND_JOB:
            const backgroundJobs = state.backgroundJobs.filter(job => job.jobId !== action.payload.jobId);
            return { ...state, backgroundJobs }

        case ActionTypes.UPDATE_BACKGROUND_JOB:
            // action.payload containing job id
            const jobObj = state.backgroundJobs.findIndex(elem => elem.name === action.payload);
            if (jobObj === -1) {
                return
            }
            state.backgroundJobs[jobObj] = action.payload
            return {
                ...state,
                backgroundJobs: state.backgroundJobs
            }

        default:
            return state
    }
}
