import * as ActionTypes from '../constants/ActionTypes';
import Thunks from '../store/middlewares/thunks';

export const setPanelId = payload => dispatch => {
    dispatch({ type: ActionTypes.CHANGE_PANEL_ID, payload });
}

export const saveProjectProgress = (payload) => dispatch => {
    dispatch({ type: ActionTypes.PROGRESS_SAVING });
    return dispatch(Thunks.metadata.saveProjectProgress(payload)).finally(() => {
        dispatch({ type: ActionTypes.PROGRESS_SAVED_SUCCESSFULLY });
    });
}

export const setNewProject = (payload) => dispatch => {
    dispatch({ type: ActionTypes.SET_NEW_PROJECT, payload });
}

export const saveRenderingToDashboard = (payload) => dispatch => {
    dispatch(Thunks.studio.saveRenderingToDashboard(payload)).then(() => {
        console.log('Project updated or created in dashboard.');
    }).catch(() => {
        console.error('Something went wrong while saving project in dashboard.');
    });
}

export const saveProjectToDashboard = (payload) => dispatch => {
    dispatch({ type: ActionTypes.ADD_PROJECT_KEEVI, payload })
};
export const setProjectToDashboard = (payload) => dispatch => {
    dispatch({ type: ActionTypes.SET_PROJECT_DETAILS, payload })
};

export const updateProjectName = (payload) => dispatch => {
    dispatch({type: ActionTypes.UPDATE_PROJECT_KEEVI, payload});
}

export const fetchProjectInfo = (payload) => dispatch => {
    return dispatch(Thunks.studio.fetchProjectInfo({type: ActionTypes.FETCH_PROJECT_INFO, payload}));
}

/* BACKGROUND JOB ACTIONS */
export const cancelBackgroundJob = (payload) => dispatch => {
    dispatch({ type: ActionTypes.CANCEL_BACKGROUND_JOB, payload });
}

export const addBackgroundJob = (payload) => dispatch => {
    dispatch({ type: ActionTypes.ADD_BACKGROUND_JOB, payload })
}

export const removeBackgroundJob = (payload) => dispatch => {
    dispatch({ type: ActionTypes.REMOVE_BACKGROUND_JOB, payload })
}
export const updateBackgroundJob = (payload) => dispatch => {
    dispatch({ type: ActionTypes.UPDATE_BACKGROUND_JOB, payload })
}
