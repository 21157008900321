import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import { cancelBackgroundJobApi, startSubtitlesJob } from "../../../api/subtitle-apis";
import { BackgroundJobModel } from "../../../models/BackgroundJobModel";
import * as ActionTypes from '../../../constants/ActionTypes';
import * as Constants from "../../../constants";
import { index } from '../../index';
import { getProjectProgressApiCall, updateRecordingToVumu, getProjectFromServer, addProjectKeevi, updateProjectKeevi, addRecordingToVumu } from '../../../api/studio-apis';
import { message } from 'antd';
import {SET_NEW_PROJECT} from "../../../constants/ActionTypes";

export function* getSubtitlesSaga() {
    yield takeEvery(ActionTypes.FETCH_SUBTITLES_FROM_SERVER, function* (payload) {
        const req_payload = payload['payload'];
        const video_id = req_payload['video_id'];
        try {
            delete req_payload['video_id'];

            const result = yield call(startSubtitlesJob, req_payload);

            const response = result.data;
            const store = index.getState();

            if (response.success) {
                const transcriptionBackgroundJob = new BackgroundJobModel({
                    jobId: response.data.task_id,
                    type: Constants.TRANSCRIPTION,
                    projectId: store.appStateInfoReducer.projectId,
                    userId: req_payload.user_id,
                    videoId: video_id
                })
                yield put({ type: ActionTypes.ADD_BACKGROUND_JOB, payload: transcriptionBackgroundJob });
            } else {
                yield put({ type: ActionTypes.SET_SUBTITLES_LOADING_FROM_SERVER, payload: {
                    videoId: video_id,
                    loading: false
                }});
                message.error("Can't generate subtitles for the video file.");
            }
        } catch (error) {
            yield put({ type: ActionTypes.SET_SUBTITLES_LOADING_FROM_SERVER, payload: {
                    videoId: video_id,
                    loading: false
            }});
            message.error("Something went wrong while generating subtitles.");
        }
    });
}



export function* cancelBackgroundJob() {
    yield takeEvery(ActionTypes.CANCEL_BACKGROUND_JOB, function* (payload) {
        try {
            const req_payload = payload['payload'];
            const result = yield call(cancelBackgroundJobApi, req_payload);
            const response = result.data;

            if(response.success) {

                const store = index.getState();
                const bgJobs = store.backgroundJobsReducer.backgroundJobs;
                const idx = bgJobs.findIndex(bg => bg.jobId === req_payload.jobPayload.task_id)
                if(idx !== -1) {
                    yield put({
                        type: ActionTypes.SET_SUBTITLES_LOADING_FROM_SERVER,
                        payload: {
                            loading: false,
                            videoId: bgJobs[idx].videoId
                        }
                    });
                }

                yield put({
                    type: ActionTypes.REMOVE_BACKGROUND_JOB,
                    payload: {
                        jobId: req_payload.jobPayload.task_id
                    }
                });

            } else {
                message.error('There appears to be some problem while cancelling transcription job.');
            }
        } catch (error) {
            message.error(error.message);
        }
    });
}

export function* addKeeviProjectSaga() {
    yield takeEvery(ActionTypes.ADD_PROJECT_KEEVI, function* (payload) {
        const token = payload['payload']['token'];
        delete payload['payload']['token'];

        try {
            const response = yield call(addKeeviProjectReq, payload['payload'], token);
            if(response.status === 200)
                yield put ({
                    type: ActionTypes.SET_PROJECT_DETAILS,
                    payload: response.data.data
                });
                yield put ({
                    type: ActionTypes.SET_NEW_PROJECT,
                    payload: { value: false }
                });
            // if data is not useful than do not put data to type SET_SUBTITLES_HASH
        } catch (error) {
            message.error(error.message);
        }
    });
}

export function* updateKeeviProjectSaga() {
    yield takeEvery(ActionTypes.UPDATE_PROJECT_KEEVI, function* (payload) {
        const token = payload['payload']['token'];
        delete payload['payload']['token'];
        try {
            const response = yield call(updateProjectKeeviReq, payload['payload'], token);
            // if(response.status === 200)
            // yield put ({
            //     type: ActionTypes.SET_PROJECT_DETAILS,
            //     payload: JSON.parse(response.data.data)
            // });
            // if data is not useful than do not put data to type SET_SUBTITLES_HASH
        } catch (error) {
            message.error(error.message);
        }
    });
}

//Api Calls here
async function getProjectProgressRequest(payload) {
    return await getProjectProgressApiCall(payload)
};

async function updateRecordingToVumuReq(payload) {
    return await updateRecordingToVumu(payload);
}

async function addKeeviProjectReq(payload, token) {
    return await addProjectKeevi(payload, token);
}

async function updateProjectKeeviReq(payload, token) {
    return await updateProjectKeevi(payload, token);
}

export default function* studioSaga() {
    yield all([
        fork(getSubtitlesSaga),
        fork(cancelBackgroundJob),
        fork(addKeeviProjectSaga),
        fork(updateKeeviProjectSaga)
    ]);
}
