import * as ActionTypes from '../../constants/ActionTypes';
import * as Constants from "../../constants";

const initState = {
    title: '',
    thumbnail: '',
    category: 0,
    status: ''
};

export default function templateReducer(state = initState, action) {
    switch (action.type) {
        case ActionTypes.SET_TITLE_FOR_TEMPLATE: {
            return {
                ...state,
                title: action.payload
            }
        }

        case ActionTypes.SET_THUMBNAIL_URL_FOR_TEMPLATE: {
            return {
                ...state,
                thumbnail: action.payload
            }
        }

        case ActionTypes.SET_CATEGORY_FOR_TEMPLATE: {
            return {
                ...state,
                category: action.payload
            }
        }

        case ActionTypes.RESET_TEMPLATE_DATA: {
            return {
                ...state,
                title: '',
                thumbnail: '',
                category: 0,
                status: ''
            }
        }

        case ActionTypes.SET_TEMPLATE_STATUS: {
            return {
                ...state,
                status: action.payload
            }
        }

        default:
            return state
    }
}
