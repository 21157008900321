import { SET_CONNECTED, SET_RE_CONNECTING, CONNECTION_TRIES_RESET } from '../constants/ActionTypes';

export const setConnected = (payload) => dispatch => {
    dispatch({ type: SET_CONNECTED, payload })
}

export const setReConnecting = payload => dispatch => {
    dispatch({ type: SET_RE_CONNECTING, payload });
}

export const resetConnectionTries = payload => dispatch => {
    dispatch({ type: CONNECTION_TRIES_RESET, payload });
}
