import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import * as ActionTypes from '../../../constants/ActionTypes';
import * as Constants from '../../../constants';
import { saveAsTemplate } from '../../../api/studio-apis';

export function* saveTemplateSaga() {
    yield takeEvery(ActionTypes.SAVE_TEMPLATE_DATA, function* (payload) {
        const req_payload = payload['payload'];

        yield put({ type: ActionTypes.SET_TEMPLATE_STATUS, payload: Constants.HTTP_REQUEST_STATUS.PROCESSING });

        try {
            const result = yield call(saveAsTemplate, req_payload);
            if (result.id) {
                yield put({ type: ActionTypes.SET_TEMPLATE_STATUS, payload: Constants.HTTP_REQUEST_STATUS.SUCCESS });
            } else {
                yield put({ type: ActionTypes.SET_TEMPLATE_STATUS, payload: Constants.HTTP_REQUEST_STATUS.ERROR });
            }
        } catch (error) {
            yield put({ type: ActionTypes.SET_TEMPLATE_STATUS, payload: Constants.HTTP_REQUEST_STATUS.ERROR });
        }
    });
}

export default function* templateSaga() {
    yield all([
        fork(saveTemplateSaga)
    ]);
}
