/**
 *
 * @param {*} data
 * Default render video model which will contain all the nesassary attributes for the payload used in backend.
 */
export function RenderTextModel(data) {
    this.id = data.id
    this.start = data.start
    this.end = data.end
    this.text = data.text
    this.animationobj = {
        type: data.animationType || 0,
        animationcolor: data.animationType === 0 ? (data.color || '').toUpperCase() : (data.animationColour || '').toUpperCase(),
        animationtime: data.animationTime !== 0 ? 6 : 0,
        animationdelay: data.animationTime !== 0 ? 0 : 0
    }
    this.xpos = data.xPosition
    this.ypos = data.yPosition
    this.fsize = data.fontSize
    this.fstyle = data.fontFamily
    this.pcolor = (data.color || '').toUpperCase()
    this.bcolor = '#ffffff00'
    this.shcolor = '#ffffff00'
    this.shadow = false// in  future if need to ass shadow, then it is it's flag
    this.border = false// in  future if need to ass border, then it is it's flag
    this.scolor = (data.color || '').toUpperCase()
    this.fontweights = [
        data.fontWeight,
        data.fontStyle,
    ];
    this.boxwidth = data.divWidth;
    this.boxheight = data.divHeight;
    this.textalign = data.align;
    this.rotation = data.rotation;
    this.transform = data.transform
    this.fspacing = parseInt(data.letterSpacing)
    this.lspacing = parseInt(data.lineSpacing)
    this.level = data.level
    this.type = data.type || "TEXT"
}


