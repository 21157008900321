import axios from 'axios';
import {config} from "../config/index"


export const startSubtitlesJob = async (req) => {
    const res = await axios.post(`${config.urls.renderserverurl}/transcribe`, req, {
        headers: {
            "Content-Type": "application/json"
            // "Authorization": `Bearer ${cookie}`
        },
    });
    return res
}
export const cancelBackgroundJobApi = async (req) => {
    const res = await axios.post(`${config.urls.renderserverurl}/render/cancel-job`, req, {
        headers: {
            "Content-Type": "application/json"
            // "Authorization": `Bearer ${cookie}`
        },
    });
    return res
}

//useful code
// export const fetchSingleTemplateDetail = async (req) => {
//     let cookie = await Cookies.get("userToken");
//     console.log("cookie", cookie);
//
//     const res = await axios.get(`${API_URL}/api/get-template-detail/${req.templateId}/`, {
//         headers: {
//             "Content-Type": "application/json",
//             "Authorization": `Bearer ${cookie}`
//         },
//     });
//     console.log(res);
//     return res
// }
//
// export const fetchTemplatesTagsHttp = async (req) => {
//     let cookie = await Cookies.get("userToken");
//     console.log("cookie", cookie);
//
//     const res = await axios.get(`${API_URL}/api/get-template-tags/`, {
//         headers: {
//             "Content-Type": "application/json",
//             "Authorization": `Bearer ${cookie}`
//         },
//     });
//     console.log(res);
//     return res
// }
