import { all } from 'redux-saga/effects';
import studioSaga from './studio'
import templateSaga from './template';
import socialShareSaga from './socialShare';
import awsSaga from './aws';

export default function* Sagas() {
  yield all([
      studioSaga(),
      templateSaga(),
      socialShareSaga(),
      awsSaga(),
  ]);
}
