/* eslint-disable no-bitwise */
import html2canvas from 'html2canvas';
import fileDownload from "js-file-download";
import clone from 'lodash/cloneDeep';
import { batch } from 'react-redux';
import * as StudioActions from '../actions/StudioActions';
import * as SubtitlesActions from '../actions/SubtitlesActions';
import * as TimelineActions from '../actions/TimelineActions';
import * as UploadActions from '../actions/UploadActions';
import { APP, config } from "../config/index";
import * as Constants from "../constants";
import {
  AUDIO, DIMENSION_ENUM, ELEMENT_POSITION, EMOJI, GIF, IMAGE,
  SUBS, TEXT,
  VIDEO
} from '../constants';
import { RenderVideoModel } from '../models/RenderVideoModel';
import { RenderAudioModel } from '../models/RenderAudioModel';
import { RenderImageModel } from '../models/RenderImageModel';
import { RenderTextModel } from '../models/RenderTextModel';
import { SubtitlesModel } from "../models/SubtitlesModel";
import { buildTrack } from "./index.builders";
import { TextModel } from '../models/TextModel';
import { VideoModel } from '../models/VideoModel';
import { dispatch, events, index } from '../store';
import { getFilenameFromUrl, roundValue } from "./index.helper";
import { SOCIAL_VIDEO_FORMATS } from "./sample-payloads";
import { convertSrtToJson, generateAssContent, generateSrtContent, generateVttContent } from "./subtitles.helper";

/**
 *
 * Default function to fill the tracks on the left side we need to pass a param no of elementes as n
 */
export const fill = n => {
  const arr = []
  for (let i = 0; i < n; i += 1) {
    arr.push(i)
  }
  return arr
}
/**
 * Array of Colours to fill the elements with random colour
 */
const COLORS = ['FF005D', '0085B6', '0BB4C1', '00D49D', 'FEDF03', '233D4D', 'FE7F2D', 'FCCA46', 'A1C181', '579C87']
/*
 * Random colour code generator function
 */
export const randomColor = () => COLORS[Math.floor(Math.random() * COLORS.length)]
/*
 * next colour matcher, ensuing each colour will not be same.
 */
let color = -1
export const nextColor = () => {
  color = (color + 1) % COLORS.length
  return COLORS[color]
}
/*
 * function that generates a random Unique ID
 */
export const generateUUID = () => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    let r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}
/**
 *
 * @param {*} r
 * @param {*} g
 * @param {*} b
 * function that check the colour is light or dark
 */

export const colourIsLight = (r, g, b) => {
  const a = 1 - (0.299 * r + 0.587 * g + 0.114 * b) / 255
  return a < 0.5
}

/**
 *
 * @param {*} array
 * @param {*} keyword
 * @param {*} zoomFactor
 * A generic function which will sum up the array based on keyword
 */
export const sumOfArray = (array, keyword, zoomFactor) => {

  let sum = 0;
  for (let i = 0; i < array.length; i++) {
    for (let j = 0; j < keyword.length; j++) {
      if (keyword[j] === 'duration') {
        sum = sum + array[i][keyword[j]]
      } else {
        sum = sum + array[i][keyword[j]]
      }
    }
  }
  return sum;
}
/**
 *
 * @param {*} obj
 * A function which will check the max duration according to xPosition and width of the element
 */
export const maxInTextDuration = (obj) => {
  let x = 0;
  for (let i = 0; i < obj.length; i++) {
    if (obj[i].xTimelinePosition + obj[i].width > x) {
      x = parseFloat(obj[i].xTimelinePosition) + parseFloat(obj[i].width)
    }
  }
  return parseFloat(x);
}
/**
 *
 * @param {*} event
 * @param {*} containerReference
 * @param {*} reference
 * A function which will receive
 */
export const dragPositionSet = (event, containerReference, reference) => {

  let data = event.data;
  let mousePosition = data.getLocalPosition(containerReference);
  let itemPosition = {
    "x": reference.x,
    "y": reference.y
  };
  let newanchorx = mousePosition.x + (reference.anchor.x * reference.width) - itemPosition.x;
  let newanchory = mousePosition.y + (reference.anchor.y * reference.height) - itemPosition.y;
  let newAnchor = {
    "x": newanchorx / reference.width,
    "y": newanchory / reference.height
  };
  return {
    newAnchor,
    mousePosition
  }
}
/**
 *
 * @param {video url} src
 *
 * we can get video duration from video element, all what i need is , to get call a promise becuase duration will
 * available when video's ready state is 4.
 */
export const getDurationFromVideoSrc = (src) => {
  let videoElement = document.createElement('video');
  videoElement.src = src;
  return new Promise((resolve, reject) => {
    videoElement.oncanplaythrough = () => {
      resolve({
        duration: videoElement.duration,
        width: videoElement.videoWidth,
        height: videoElement.videoHeight,
        fileName: getFilenameFromUrl(src)
      })
    }
  }).then(res => {
    return res;
  }).catch(res => { })
}

/*
  for adding text as a hashmap/dictonary
*/
export const addTextToList = (textList, payload) => {
  let obj = textList;
  // let list = Object.assign({}, obj);
  // ("list", list);
  if (payload) {
    if (payload.index != null) {
      obj['track-Text'].elements[payload.index] = new TextModel(payload);
    } else {
      obj['track-Text'].elements[obj['track-Text'].elements.length] = new TextModel({
        ...payload,
        xTimelinePosition: maxInTextDuration(obj['track-Text'].elements)
      });
    }
  }
  return obj;
}

/*
  for deleting a text from hashmap
*/
export const deleteText = (obj, textList) => {
  // delete textList[obj.id];
  // return textList;
  for (let i = 0; i < obj.length; i++) {
    if (obj[i].id === textList.id) {
      obj.splice(i, 1)
    }
  }
  return obj;
}
export const deleteSub = (tracks, subtitle) => {
  for (let i = 0; i < tracks.length; i++) {
    if (tracks[i].type === SUBS) {
      //find element obj
      let element = tracks[i]['elements'].find(element => element.videoId === subtitle.videoId);
      if (element) {
        //Remove specific subtitle element
        if (element.id === subtitle.id) {
          element.subtitles = [];
        } else {
          element.subtitles.splice(element.subtitles.findIndex(sub => sub.id === subtitle.id), 1);
          element.subtitles.forEach((sub, i) => {
            sub.elementIndex = i;
          });
        }

        //If that was last subtitle index then remove element object from element array
        if (element.subtitles.length === 0) {
          tracks[i]['elements'].splice(tracks[i]['elements'].findIndex(el => el.videoId === subtitle.videoId), 1);
          const indexes = getTrackAndElementIndex(tracks, { id: subtitle.videoId });

          tracks[indexes.trackIndex].elements[indexes.elementIndex].subtitles = [];
        }
        //If that was last element object in track then we donot have anything in track and we can remove the track safely
        if (tracks[i]['elements'].length === 0) {
          tracks.splice(i, 1);
        }
      }
    }
  }
  return tracks;
}

export const deleteVideo = (obj, subObj) => {
  // TODO: Handle delete last item in a track when there are multiple tracks
  for (let i = 0; i < obj.length; i++) {
    if (obj[i].type === VIDEO || obj[i].type === AUDIO) {
      for (let j = 0; j < obj[i]['elements'].length; j++) {
        if (obj[i]['elements'][j]['id'] === subObj.id) {
          removeVideoElementById(obj[i]['elements'][j].videoElementId)
          obj[i]['elements'].splice(j, 1)
          for (let k = j; k < obj[i].elements.length; k++) {
            obj[i]['elements'][k].elementIndex -= 1; // 1 step back track index, like 1->0, 2->1, starting from the found index.
          }
          if (obj[i]['elements'].length === 0) {
            obj.splice(i, 1);
          }
          break;
        }
      }
    }
  }
  return obj;
}

export const getVideoMetadata = async (projectId, url, duration) => {
  const videoInfo = await getDurationFromVideoSrc(url);
  duration = videoInfo.duration ? videoInfo.duration : duration;

  const obj = {
    id: projectId,
    src: url,
    file: url,
    targetEnd: duration,
    duration: duration,
    currentDuration: duration,
    end: duration,
    start: 0,
    originalWidth: videoInfo.width,
    originalHeight: videoInfo.height,
    thumbnail: '',
    videoElementId: generateObjectId(),
    targetStart: 0,
    isUploaded: true,
    s3Url: url.replace(new URL(url).host, config.bucketName + ".s3." + config.aws_region + ".amazonaws.com"),
    originalFileName: videoInfo.fileName,
    volume: 1
  }

  obj.videoElement = createVideoElement(obj, false);

  return obj;
}

/**
 *
 * @param {Hashmap} obj converting the object hashmap to array
 */
export const keyObjectListToArray = (obj) => {
  return Object.keys(obj).map((key) => {
    return obj[key];
  });
}

export const generateFontSizes = (start, end) => {
  const fontSize = [];
  for (let index = start; index < end; index++) {
    fontSize.push({
      id: index,
      label: index + "px",
      value: index
    })
  }
  return fontSize;
}

//export const fontSizes = generateFontSizes(8, 150);
export const fontWeights = [{
  label: "Normal",
  value: "normal"
},
{
  label: "Bold",
  value: "bold"
},
{
  label: "Italic",
  value: "italic"
},
]

/**
 * pause video helper. we will pause the video id only
 */
export const pauseVideoByVideoId = (videoID, flag) => {
  let videoObject = document.getElementById(videoID);

  if (videoObject) {
    if (flag) {
      let el = videoObject;
      let isPlaying = el.currentTime > 0 && !el.paused && !el.ended
        && el.readyState > el.HAVE_CURRENT_DATA;

      if (!isPlaying) {
        el.play();
      }
    } else {
      videoObject.pause();
    }
  }
}

/**
 * return remaining empty string
 */
export const returnSpaceBasedOnLength = (currentLength, totalLength) => {
  let space = '';
  for (let i = currentLength; i < totalLength; i++) {
    space = space + ' ';
  }
  return space;
}

export const API_URL = 'https://render.keevi.io/rend/render-job';
// export const API_URL_SERVER = 'http://localhost:8000/rend';
//export const API_URL_SERVER = 'https://render.keevi.io/rend';
// export const API_URL = 'http://localhost:8000/rend/render';

function compare(a, b) {
  if (a.sortOrder < b.sortOrder) {
    return -1;
  }
  if (a.sortOrder > b.sortOrder) {
    return 1;
  }
  return 0;
}

/**
 * backend Payload creater function
 */
export const createRenderingPayload = (tracks, duration, zoom, muted, subtitleStyle, projectId, userId, email = undefined) => {
  let TextPayload = [];
  let VideoPayload = [];
  let AudioPayload = [];
  let ImagePayload = []; //forImage
  let showTimeVideo = 0;
  let showTimeImage = 0;

  let obj = tracks.sort(levelSort);
  for (let i = 0; i < obj.length; i++) {
    let tempObj = obj[i];

    for (let j = 0; j < tempObj.elements.length; j++) {
      let tempObjElement = tempObj.elements[j];

      if (tempObj.type === VIDEO) {
        showTimeVideo = tempObjElement.start;
        tempObjElement.file = tempObjElement.src
        // tempObjElement.file = tempObjElement && tempObjElement.file ? tempObjElement.file : tempObj.elements[j - 1].file
        VideoPayload.push(new RenderVideoModel({
          ...tempObjElement,
          showTime: showTimeVideo,
          muted: false,
          level: tempObj.level
          // muted: muted
        }));
        // previousVideoEnd = tempObjElement.end
        //TODO
      } else if (tempObj.type === AUDIO) {

        tempObjElement.file = tempObjElement.file ? tempObjElement.file : tempObj.elements[j - 1].file
        AudioPayload.push(new RenderAudioModel({
          ...tempObjElement,
          showTime: tempObjElement.start,
        }));

      } else if (tempObj.type === SUBS) {
        let videoTracks = obj.filter(x => x.type === VIDEO);
        for (let v = 0; v < videoTracks.length; v++) {
          let videoElement = videoTracks[v].elements.find(x => x.id === tempObjElement.videoId);
          if (videoElement) {
            let elements = tempObjElement.subtitles;
            for (let m in elements) {
              if (elements[m].start >= videoElement.start && elements[m].end <= roundValue(videoElement.end)) {
                let subsModel = {
                  ...elements[m],
                  ...subtitleStyle
                };

                TextPayload.push(new RenderTextModel({
                  ...subsModel,
                  zoom: zoom,
                  level: tempObj.level,
                  type: "SUBS"
                }));
              }
            }
          }
        }

      } else if (tempObj.type === TEXT) {
        TextPayload.push(new RenderTextModel({
          ...tempObjElement,
          zoom: zoom,
          level: tempObj.level,
          type: "TEXT"
        }));

      } else if (tempObj.type === IMAGE || tempObj.type === GIF || tempObj.type === EMOJI) {
        showTimeImage = tempObjElement.start;
        ImagePayload.push(new RenderImageModel({
          ...tempObjElement,
          showTime: showTimeImage,
          currentWidth: tempObjElement.divWidth,
          currentHeight: tempObjElement.divHeight,
          zoom: zoom,
          level: tempObj.level
        }));
      }
    }
  }

  const state = index.getState();
  const { renderingPreferences } = state.renderReducer;

  let frame = {
    "framewidth": state.viewportReducer.width,
    "frameheight": state.viewportReducer.height,
    "duration": duration,
    "backgroundcolor": state.viewportReducer.background,
    "ratio": DIMENSION_ENUM[SOCIAL_VIDEO_FORMATS.filter(x => x.id === state.viewportReducer.preset_id).pop().value],
    "target_resolution": renderingPreferences.target_resolution,
    "fps": renderingPreferences.fps,
    "quality": renderingPreferences.quality,
    "compression": renderingPreferences.compression,
    projectId,
    "video_id": projectId
  }

  return {
    frame,
    videos: VideoPayload.sort(compare),
    audios: AudioPayload,
    texts: TextPayload,
    overlay_videos: [],
    overlay_images: ImagePayload,
    user_id: userId ? userId : null, // for vumu user Id is must required
    email: email,// for vumu email is must required
    source_obj: {
      source_name: APP.TITLE,
      update_end_point: config.VUMU_SERVERLESS + '/update-recording',
      auth_token: ''  // to add security to the API in future.
    }
  }
}

export const generateObjectId = (m = Math, d = Date, h = 16, s = s => m.floor(s).toString(h)) => {
  return s(d.now() / 1000) + ' '.repeat(h).replace(/./g, () => s(m.random() * h))
}

export const createthumbnailFromSrcFromCanvas = (src, duration, zoom, canvasId = "canvasForThumbnail") => {
  //first start
  let videoElementFlag = document.getElementById("thumbnailGenerator");
  if (!videoElementFlag) {

    return new Promise((resolve, reject) => {
      let videoElement = document.createElement("video");
      videoElement.src = src;
      videoElement.crossOrigin = "anonymous" //for
      videoElement.id = "thumbnailGenerator";
      let timeJump = Constants.TIMELINE_VIDEO_TRACK_THUMBNAIL_WIDTH / zoom;
      let totalThumbs = Math.ceil(duration / timeJump);
      let thumbsGenerated = 0;
      let canvas = document.getElementById(`${canvasId}_canvas`);
      canvas.width = canvas.offsetWidth;
      canvas.height = canvas.offsetHeight;
      let twoDContext = canvas.getContext('2d');
      twoDContext.clearRect(0, 0, canvas.width, canvas.height);
      function setInitTime() {
        videoElement.currentTime = 0.1;
      }
      function seeked(event) {
        twoDContext.drawImage(event.target, 0, 0, event.target.videoWidth, event.target.videoHeight, thumbsGenerated * Constants.TIMELINE_VIDEO_TRACK_THUMBNAIL_WIDTH, 0, Constants.TIMELINE_VIDEO_TRACK_THUMBNAIL_WIDTH, canvas.height);
        thumbsGenerated++;
        videoElement.currentTime = Math.min(videoElement.currentTime + timeJump, videoElement.duration);
        if (thumbsGenerated === totalThumbs) {
          videoElement.pause();
          videoElement.removeEventListener('seeked', seeked);
          resolve();
        }
      }

      videoElement.addEventListener('seeked', seeked);
      setTimeout((videoElement) => {
        setInitTime();
      }, 2);
    }).then(res => {
      return res;
    }).catch(res => {

    })
    //first end
  }

}


export const getVideoObjectOnly = (obj) => { }


export const addVideo = async (item, currentTime = null) => {
  const store = index.getState();
  let viewport = store.viewportReducer;
  if (!currentTime) currentTime = store.cursorReducer.currentTime;

  const ratio = item.width / item.height;
  let currentWidth = item.width;
  let currentHeight = currentWidth / ratio;

  if (currentHeight > viewport.height) {
    currentHeight = viewport.height;
    currentWidth = currentHeight * ratio;
  }

  const videoObject = new VideoModel({
    id: generateUUID(),
    src: item.url,
    s3Url: item.url,
    file: item.largeUrl,
    targetEnd: item.duration,
    duration: item.duration,
    currentDuration: item.duration,
    end: parseFloat(currentTime) + item.duration,
    start: parseFloat(currentTime),
    originalWidth: item.width,
    originalHeight: item.height,
    currentWidth: currentWidth,
    currentHeight: currentHeight,
    xPosition: (viewport.width / 2) - currentWidth / 2,
    yPosition: (viewport.height / 2) - currentHeight / 2,
    thumbnail: '',
    volume: 1,
    videoElementId: generateObjectId(),
    targetStart: 0,
    isUploaded: item.isUploaded ? item.isUploaded : false,
    originalFileName: item.FileName
  });

  let element = createVideoElement(videoObject, false);

  videoObject.videoElement = element;

  batch(() => {
    dispatch(UploadActions.addVideoObject(videoObject));
  })

  addElementToTimeline(videoObject);
  events.dispatch_event('hashmap:generate');
  return videoObject;
}
export const addElementToTimeline = (videoObject) => {
  const store = index.getState();
  const tracks = store.tracksReducer.tracks;
  const selectedItemModel = store.tracksReducer.selectedItemModel;

  if (!selectedItemModel || (selectedItemModel && selectedItemModel.type !== VIDEO)) {
    const track = buildTrack(VIDEO, generateUUID(), getMaxLevel(tracks, VIDEO));
    track.elements.push(new VideoModel(videoObject));
    tracks.push(track);
  } else {
    // add video in the same track
    const indexes = getTrackAndElementIndex(tracks, selectedItemModel)
    const max = getMaxDurationOfTrack(tracks[indexes.trackIndex]);
    const offsetStart = max === -1 ? 0 : max;

    videoObject.start = offsetStart + .1;
    videoObject.end = videoObject.start + videoObject.duration;

    tracks[indexes.trackIndex].elements.push(new VideoModel(videoObject));
  }

  batch(() => {
    dispatch(StudioActions.setLayersTracks(Object.values(tracks)))
    dispatch(TimelineActions.setTimelineMaxDuration(getMaxDuration(tracks)));
  })
}
export const createVideoElement = (videoObjPayload, muted, currentTime) => {
  let checkVideoFlag = document.getElementById(videoObjPayload.videoElementId);
  if (checkVideoFlag) {
    checkVideoFlag.src = videoObjPayload.src
    checkVideoFlag.currentTime = currentTime ? currentTime : parseInt(videoObjPayload.targetStart);
    return checkVideoFlag;
  } else {
    let video = document.createElement('video');
    video.preload = "auto";
    // video.crossOrigin = "Anonymous";     this is causing issue adding stock video and audios
    video.loop = false;
    video.muted = muted;
    video.autoload = true;
    video.autoplay = false;
    video.width = 400;
    video.height = 400;
    video.controls = true;
    video.id = videoObjPayload.videoElementId;
    video.className = "invisible-element";
    // video.crossOrigin = "anonymous"
    video.volume = videoObjPayload.volume || 1;
    video.currentTime = currentTime ? currentTime : parseInt(videoObjPayload.targetStart);
    video.src = videoObjPayload.file ? videoObjPayload.file : videoObjPayload.src
    document.body.appendChild(video);
    video.addEventListener("loadeddata", function () {
      let hasAudio = true;
      if (typeof this.webkitAudioDecodedByteCount !== "undefined") {
        // non-zero if video has audio track
        if (!(this.webkitAudioDecodedByteCount > 0)) {
          hasAudio = false;
        }
      }
      else if (typeof this.mozHasAudio !== "undefined") {
        // true if video has audio track
        if (!this.mozHasAudio)
          hasAudio = false;
      }

      events.dispatch_event('media-element-audio-found', { videoObject: videoObjPayload, hasAudio: hasAudio })
    });
    return video;
  }

}

export const convertIntoHashMap = (obj) => {
  let arr = {};
  for (let i = 0; i < obj.length; i++) {
    arr[obj[i].id] = obj[i];
  }

  return arr;

}

/*
 * function to convert hex color to RGB values
 */
export const hexToRgb = (hex) => {
  let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16)
  } : null;
}

export const getNormalizedColorFromString = (color) => {
  let rgb = hexToRgb(color);
  if (!rgb)
    return [0, 0, 0];
  return [rgb.r / 255, rgb.g / 255, rgb.b / 255];
}

export const removeVideoElement = async () => {
  let obj = document.getElementsByClassName('invisible-element');
  for (let i = 0; obj && i < obj.length; i++) {
    obj[i].remove();
  }
}

export const removeVideoElementById = (videoId) => {
  let obj = document.getElementById(videoId);
  if (obj) {
    obj.remove();
  }
}

export const addSubtitles = (sub, zoom, incrementInterval) => {
  const subStart = roundValue(parseFloat(sub.start), incrementInterval)
  const subEnd = roundValue(parseFloat(sub.end), incrementInterval)
  return new SubtitlesModel({
    text: sub.part,
    start: subStart,
    end: subEnd,
    targetStart: subStart,
    targetEnd: subEnd,
    startCopy: subStart,
    endCopy: subEnd,
    width: subEnd - subStart
  })

};

export const addSubtitlesToTimeline = (payload, incrementInterval) => {
  const { subtitlesArray } = payload;
  const result = {};

  if (subtitlesArray && subtitlesArray.length > 0) {
    const store = index.getState();
    const tracks = store.tracksReducer.tracks;
    const zoom = store.timelineReducer.zoom;
    const selectedVideo = store.subtitlesReducer.selectedVideo;

    if (selectedVideo) {
      let subtitlesElements = [];

      // Find out the video element which needs to be asosciate with video
      const indexes = getTrackAndElementIndex(tracks, selectedVideo);

      if (indexes.trackIndex !== -1) {
        const track = tracks[indexes.trackIndex];
        const element = tracks[indexes.trackIndex].elements[indexes.elementIndex];

        //Loop through the subtitles from json and add them one by one on timeline
        for (let i = 0; i < subtitlesArray.length; i++) {
          const subtitleElement = addSubtitles(subtitlesArray[i], zoom, incrementInterval)
          subtitleElement.videoId = element.id;
          subtitlesElements.push(subtitleElement)
        }

        if (payload.type && payload.type !== 'MANUAL') {
          const subStatusFlag = checkVideoTragetTimeDifference_withSubtitleStartEnd(element, subtitlesElements);
          if (subStatusFlag.status === 1) {
            for (let j = subStatusFlag.subtitleStartIndex; j < subStatusFlag.subtitleEndIndex; j++) {
              subtitlesElements[j].start = subtitlesElements[j].start + subStatusFlag.value;
              subtitlesElements[j].videoId = element.id;
              if (element.start > subtitlesElements[j].start) {
                subtitlesElements[j].start = element.start
              }
              subtitlesElements[j].end = subtitlesElements[j].end + subStatusFlag.value
              if (element.end < subtitlesElements[j].end) {
                subtitlesElements[j].end = element.end
              }
            }
          }
        }

        const subtitlesEl = new TextModel({
          text: 'Subtitles',
          type: Constants.SUBS,
          modelType: Constants.SUBS
        });

        subtitlesEl.subtitles = subtitlesElements;
        subtitlesEl.videoId = selectedVideo.id;

        if (tracks[indexes.trackIndex - 1] && tracks[indexes.trackIndex - 1].type === Constants.SUBS) {
          tracks[indexes.trackIndex - 1].elements.push(subtitlesEl);
        } else {
          const newTrack = buildTrack(SUBS, selectedVideo.id);
          newTrack.elements = [];
          newTrack.elements.push(subtitlesEl);
          tracks.splice(indexes.trackIndex, 0, newTrack);
        }

        result.success = true;
        result.tracks = resetTrackAndElementsIndexes(tracks.reverse());
      } else {
        result.success = false;
      }
    } else {
      result.success = false;
    }
  } else {
    result.success = false;
  }
  return result;
}

const checkVideoTragetTimeDifference_withSubtitleStartEnd = (videoElement, SubtitleArray) => {

  let status = 0; // 0 = no change -- 1= change the start -- 2 = remove that subtitle, it will be used by sultan
  let value = 0;
  if (videoElement.start === videoElement.targetStart) {
    return {
      status,
      value,
    }
  }

  let subtitleStartIndex = SubtitleArray.findIndex((key) => key.targetStart >= videoElement.targetStart || videoElement.targetStart < key.targetEnd)
  let subtitleEndIndex = SubtitleArray.findIndex((key) => key.targetStart >= videoElement.targetEnd || videoElement.targetEnd < key.targetEnd)

  if (subtitleStartIndex === -1) {
    subtitleStartIndex = 0
  }

  if (subtitleEndIndex === -1 || subtitleEndIndex === 0) {
    subtitleEndIndex = SubtitleArray.length - 1
  }

  return {
    status: 1,
    value: videoElement.start - videoElement.targetStart,// this will be the value we need to add in start and end of subtitle to match the video in drag form;
    subtitleStartIndex, // from where to where we need to change subs
    subtitleEndIndex,
  }

}
export const convertSrtToSubsJson = async (videoId, filePath) => {
  let json;
  await fetch(filePath)
    .then(async (res) => {
      json = await readAllChunks(res.body);
      json = json.replaceAll("-- ", "").replaceAll("<br />", "");
      const jsonSubs = await convertSrtToJson(json);
      dispatch(SubtitlesActions.setVideoSubtitles({
        videoId: videoId,
        res: jsonSubs
      }))
    })
    .catch(err => {
      throw err
    });
}
async function readAllChunks(readableStream) {
  const reader = readableStream.getReader();
  const chunks = [];

  let done, value;
  while (!done) {
    ({ value, done } = await reader.read());
    if (done) {
      return Utf8ArrayToStr(chunks[0]);
    }
    chunks.push(value);
  }
}
function Utf8ArrayToStr(array) {
  let out, i, len, c;
  let char2, char3;

  out = "";
  len = array.length;
  i = 0;
  while (i < len) {
    c = array[i++];
    switch (c >> 4) {
      case 0: case 1: case 2: case 3: case 4: case 5: case 6: case 7:
        // 0xxxxxxx
        out += String.fromCharCode(c);
        break;
      case 12: case 13:
        // 110x xxxx   10xx xxxx
        char2 = array[i++];
        out += String.fromCharCode(((c & 0x1F) << 6) | (char2 & 0x3F));
        break;
      case 14:
        // 1110 xxxx  10xx xxxx  10xx xxxx
        char2 = array[i++];
        char3 = array[i++];
        out += String.fromCharCode(((c & 0x0F) << 12) |
          ((char2 & 0x3F) << 6) |
          ((char3 & 0x3F) << 0));
        break;
    }
  }

  return out;
}
export const convertToSubsJson = async (json_file, char_length) => {
  let json;
  await fetch(json_file)
    .then(res => res.json())
    .then((out) => {
      json = out
    })
    .catch(err => {
      throw err
    });
  const subsArray = [];
  let start_time = "";
  let end_time = "";
  let sentence = "";
  let t = 1;
  const wtb = char_length;
  let j = json;
  let c = j.results.items.length;
  for (let i = 0; i < c; i++) {
    if (j.results.items[i].type === "pronunciation") {
      if (start_time === "") start_time = j.results.items[i].start_time;
      end_time = j.results.items[i].end_time;
      sentence += start_time === "" ? "" + j.results.items[i].alternatives[0].content : " " + j.results.items[i].alternatives[0].content;
      t++;
      if (t > 30) {
        const subtitleJson = {
          start: start_time,
          end: end_time,
          part: sentence,
        }
        subsArray.push(subtitleJson)
        // null values for other iterations
        start_time = "";
        end_time = "";
        sentence = "";
        t = 1;
      }
    } else if (j.results.items[i].type === "punctuation" && j.results.items[i].alternatives[0].content === "." || j.results.items[i].alternatives[0].content === ",") {
      sentence += '' + j.results.items[i].alternatives[0].content;
      if (t >= 5 && i !== c - 1) {
        const subtitleJson = {
          start: start_time,
          end: end_time,
          part: sentence,
        }
        subsArray.push(subtitleJson)
        // null values for other iterations
        start_time = "";
        end_time = "";
        sentence = "";
        t = 1;
      }

    }
    // to check last iteration here
    if (i === c - 1) {
      const subtitleJson = {
        start: start_time,
        end: end_time,
        part: sentence,
      }
      subsArray.push(subtitleJson)
      // null values for other iterations
      start_time = "";
      end_time = "";
      sentence = "";
      t = 1;
    }
  }
  return subsArray
}

export const downloadSubtitleFile = (format, filename) => {

  let data = "";
  let dest = filename + '.' + format;

  if (format === 'srt') {
    data = generateSrtContent();
  } else if (format === 'vtt') {
    data = generateVttContent();
  } else if (format === 'ass') {
    data = generateAssContent();
  }

  fileDownload(data, dest);

}


/*export const animationObject = {
  NONE: {
    id: 0,
    key: 'NONE',
    title: "None"
  },
  BOUNCING_TEXT: {
    id: 1,
    chars: require('../3rd_party/animations/fonts/JosefinSlab/chars.json'),
    list: require('../3rd_party/animations/fonts/JosefinSlab/list.json'),
    key: 'BOUNCING_TEXT',
    title: "Bouncing Text",
    json: require('../3rd_party/animations/bouncing-text.json'),
    demo: require('../3rd_party/animations/demos/bouncing-text.json')

  },
  SCALE_IN: {
    id: 4,
    chars: require('../3rd_party/animations/fonts/IndieFlower/chars.json'),
    list: require('../3rd_party/animations/fonts/IndieFlower/list.json'),
    key: 'SCALE_IN',
    title: "Scale in",
    json: require('../3rd_party/animations/scaleIn.json'),
    demo: require('../3rd_party/animations/demos/scale-in.json')
  },
  WIPE_TO_CENTER: {
    id: 3,
    chars: require('../3rd_party/animations/fonts/JosefinSlab/chars.json'),
    list: require('../3rd_party/animations/fonts/JosefinSlab/list.json'),
    key: 'WIPE_TO_CENTER',
    title: "Wipe to center",
    json: require('../3rd_party/animations/wipeToCenter.json'),
    demo: require('../3rd_party/animations/demos/wipe-to-center.json')
  },
  JUMP_IN: {
    id: 2,
    chars: require('../3rd_party/animations/fonts/JosefinSlab/chars.json'),
    list: require('../3rd_party/animations/fonts/JosefinSlab/list.json'),
    key: 'JUMP_IN',
    title: "Jump in",
    json: require('../3rd_party/animations/jumpIn.json'),
    demo: require('../3rd_party/animations/demos/jump-in.json')
  },
  FLIP_UP: {
    id: 5,
    chars: require('../3rd_party/animations/fonts/JosefinSlab/chars.json'),
    list: require('../3rd_party/animations/fonts/JosefinSlab/list.json'),
    key: 'FLIP_UP',
    title: "Flip up",
    json: require('../3rd_party/animations/flip-up.json'),
    demo: require('../3rd_party/animations/demos/flip-up.json')
  },
  Y_ROTATE: {
    id: 6,
    chars: require('../3rd_party/animations/fonts/JosefinSlab/chars.json'),
    list: require('../3rd_party/animations/fonts/JosefinSlab/list.json'),
    key: 'Y_ROTATE',
    title: "Y Rotate",
    json: require('../3rd_party/animations/3DBasicRotateY.json'),
    demo: require('../3rd_party/animations/demos/3DBasicRotateY.json')
  },
  FLIP_IN: {
    id: 7,
    chars: require('../3rd_party/animations/fonts/JosefinSlab/chars.json'),
    list: require('../3rd_party/animations/fonts/JosefinSlab/list.json'),
    key: 'FLIP_IN',
    title: "Flip in",
    json: require('../3rd_party/animations/3DFlipInRotate.json'),
    demo: require('../3rd_party/animations/demos/3DFlipInRotate.json')
  },
  SPIRAL_ROTATE: {
    id: 8,
    chars: require('../3rd_party/animations/fonts/JosefinSlab/chars.json'),
    list: require('../3rd_party/animations/fonts/JosefinSlab/list.json'),
    key: 'SPIRAL_ROTATE',
    title: "Spiral Rotate",
    json: require('../3rd_party/animations/3DSpiralRotatINByLine.json'),
    demo: require('../3rd_party/animations/demos/3DSpiralRotatINByLine.json')

  },
  SPIRAL_OUT: {
    id: 9,
    chars: require('../3rd_party/animations/fonts/IndieFlower/chars.json'),
    list: require('../3rd_party/animations/fonts/IndieFlower/list.json'),
    key: 'SPIRAL_OUT',
    title: "Spiral in",
    json: require('../3rd_party/animations/3DSpiralRotatOutByLine.json'),
    demo: require('../3rd_party/animations/demos/3DSpiralRotatOutByLine.json')
  },
  ZOOM_AWAY: {
    id: 10,
    chars: require('../3rd_party/animations/fonts/JosefinSlab/chars.json'),
    list: require('../3rd_party/animations/fonts/JosefinSlab/list.json'),
    key: 'ZOOM_AWAY',
    title: "Zoom away",
    json: require('../3rd_party/animations/ZoomAway.json'),
    demo: require('../3rd_party/animations/demos/ZoomAway.json')
  },
  SPIKE3D: {
    id: 11,
    chars: require('../3rd_party/animations/fonts/JosefinSlab/chars.json'),
    list: require('../3rd_party/animations/fonts/JosefinSlab/list.json'),
    key: 'SPIKE3D',
    title: "Spike 3d",
    json: require('../3rd_party/animations/3DSpike.json'),
    demo: require('../3rd_party/animations/demos/3DSpike.json')
  },
  CASCADE: {
    id: 12,
    chars: require('../3rd_party/animations/fonts/JosefinSlab/chars.json'),
    list: require('../3rd_party/animations/fonts/JosefinSlab/list.json'),
    key: 'CASCADE',
    title: "Cascade",
    json: require('../3rd_party/animations/Cascade.json'),
    demo: require('../3rd_party/animations/demos/Cascade.json')
  },
  CHARACTER_ROTATE: {
    id: 13,
    chars: require('../3rd_party/animations/fonts/JosefinSlab/chars.json'),
    list: require('../3rd_party/animations/fonts/JosefinSlab/list.json'),
    key: 'CHARACTER_ROTATE',
    title: "Character Rotate",
    json: require('../3rd_party/animations/CharacterRotate.json'),
    demo: require('../3rd_party/animations/demos/CharacterRotate.json')
  },
  INSECT_WIPE: {
    id: 14,
    chars: require('../3rd_party/animations/fonts/JosefinSlab/chars.json'),
    list: require('../3rd_party/animations/fonts/JosefinSlab/list.json'),
    key: 'INSECT_WIPE',
    title: "Insect Wipe",
    json: require('../3rd_party/animations/insectWipe.json'),
    demo: require('../3rd_party/animations/demos/insectWipe.json')
  }

  // BODY_MOVIN: {
  //   id: 5,
  //   chars: require('../3rd_party/animations/fonts/JosefinSlab/chars.json'),
  //   list: require('../3rd_party/animations/fonts/JosefinSlab/list.json'),
  //   key: 'BODY_MOVIN',
  //   title: "Body Movin",
  //   json: require('../3rd_party/animations/bodymovin.json'),
  //   demo: require('../3rd_party/animations/bodymovin.json')
  // }
}*/

// export const getAnimationObect = (id) => {
//   return Object.keys(animationObject).find((key) => {
//     let data = animationObject[key];
//     return data.id === id;
//   });
// }

/*export const animationFontObject = {
  lobster: {
    family: 'Lobster',
    chars: require('../3rd_party/animations/fonts/Lobster/chars.json'),
    list: require('../3rd_party/animations/fonts/Lobster/list.json')
  },
  barrio: {
    family: 'Barrio',
    chars: require('../3rd_party/animations/fonts/Barrio/chars.json'),
    list: require('../3rd_party/animations/fonts/Barrio/list.json')
  },
  indieFlower: {
    family: 'Indie Flower',
    chars: require('../3rd_party/animations/fonts/IndieFlower/chars.json'),
    list: require('../3rd_party/animations/fonts/IndieFlower/list.json')
  },
  josefinSlab: {
    family: 'Josefin Slab',
    chars: require('../3rd_party/animations/fonts/JosefinSlab/chars.json'),
    list: require('../3rd_party/animations/fonts/JosefinSlab/list.json')
  },
  sansita: {
    family: 'Sansita',
    chars: require('../3rd_party/animations/fonts/Sansita/chars.json'),
    list: require('../3rd_party/animations/fonts/Sansita/list.json')
  },
  viro: {
    family: 'Viro',
    chars: {},
    list: {}
  },
  openSans: {
    family: 'Open Sans',
    chars: {},
    list: {}
  },
  dosis: {
    family: 'Dosis',
    chars: {},
    list: {}
  },
  nunito: {
    family: 'Nunito',
    chars: {},
    list: {}
  },
  arimo: {
    family: 'Arimo',
    chars: {},
    list: {}
  },
  raleway: {
    family: 'Raleway',
    chars: {},
    list: {}
  },
  roboto: {
    family: 'Roboto',
    chars: {},
    list: {}
  },
  architext: {
    family: 'Architext',
    chars: {},
    list: {}
  },
  benegraphic: {
    family: 'Benegraphic',
    chars: {},
    list: {}
  },
  carnivaleeFreakshow: {
    family: 'Carnivalee Freakshow',
    chars: {},
    list: {}
  },
  dotty: {
    family: 'dotty',
    chars: {},
    list: {}
  },
  eutemiaI: {
    family: 'Eutemia I',
    chars: {},
    list: {}
  },
  mexcellent: {
    family: 'Mexcellent',
    chars: {},
    list: {}
  },
  octinStencil: {
    family: 'Octin Stencil',
    chars: {},
    list: {}
  },
  qikkiReg: {
    family: 'Qikki Reg',
    chars: {},
    list: {}
  },
  rMAlbion: {
    family: 'RM Albion',
    chars: {},
    list: {}
  },
  rMTyperighterMedium: {
    family: 'RM Typerighter Medium',
    chars: {},
    list: {}
  },
  stillTime: {
    family: 'Still Time',
    chars: {},
    list: {}
  }
}*/

// export const getAnimationFont = (f) => {
//   return Object.keys(animationFontObject).find((key) => {
//     let data = animationFontObject[key];
//     return data.family === f;
//   });
// }

export const alignmentEnum = {
  left: {
    id: 1,
    value: 'left'
  },
  center: {
    id: 2,
    value: 'center'
  },
  right: {
    id: 3,
    value: 'right'
  }
}

export const getMaxLevel = (arr, type = 'Text') => {
  if (arr.length === 0) return 0;
  arr = arr.sort(levelSort);
  return arr[0].level + 1;
}

/**
 * Sorts the array in descending order
 * @param {firstItem} a
 * @param {secondItem} b
 * @returns
 */
export const levelSort = (a, b) => { return b.level - a.level; }

export const updateDict = (hashmap, payload, key) => {
  let dict = Object.assign({}, hashmap);
  if (payload) {
    dict[payload[key]] = payload;
  }
  return dict;
}

export const splitTextByTime = (obj, currentTime) => {
  const textArray = obj.text.split(' ')
  if (textArray.length <= 1) {
    return { leftText: undefined, rightText: undefined }
  }
  const start = roundValue(obj.start)
  const end = roundValue(obj.end)
  const diffFactor = end - start
  const gap = currentTime - start
  const wordsPerSecond = ((textArray.length) / diffFactor)
  const wordSplitCount = Math.round(wordsPerSecond * gap)
  const leftText = (textArray.slice(0, wordSplitCount)).join(' ')
  const rightText = textArray.slice(wordSplitCount, textArray.length).join(' ')
  return { leftText, rightText }
}


export const removeThumbnail = (tracks) => {
  if (tracks === null) {
    return tracks;
  }
  const videoTrack = tracks.filter((key) => key.type === VIDEO)
  if (videoTrack && videoTrack.length > 0 && videoTrack[0]) {
    for (let i = 0; i < videoTrack[0].elements.length; i++) {
      videoTrack[0].elements[i].thumbnail = [];
    }
  }
  return tracks

}
export const dereferenceStructure = (obj) => {
  return JSON.parse(JSON.stringify(obj))
}

export const getScreenshotHandler = async (id, cb) => {
  const captureRef = document.getElementById(id)
  if (captureRef) {
    return await html2canvas(captureRef, {
      // letterRendering: 1,
      // logging: true,
      allowTaint: true,
      // useCORS: false
    }).then(canvas => {
      // const image = document.createElement('img');
      // image.src = canvas.toDataURL("image/png", 0.1);
      // document.body.appendChild(image);

      return canvas.toDataURL("image/png", 0.1)
    })
  }

};
export const getDivHeightById = (id) => {
  let object = document.getElementById(id);
  if (object) {
    return object.clientHeight;
  }
  return -1;

}



export const snapToSides = (x, y, width, height, canvasWidth, canvasHeight) => {
  const deltaX = 10;
  const deltaY = 10;
  let snapX = x;
  let snapY = y;
  if (Math.abs(0 - x) <= deltaX)
    snapX = 0; // will snap to left
  if (Math.abs(canvasWidth - (x + width)) <= deltaX) {
    snapX = (canvasWidth - width); // will snap to right
  }
  if (Math.abs(0 - y) <= deltaY)
    snapY = 0; // will snap to top
  if (Math.abs(canvasHeight - (y + height)) <= deltaY)
    snapY = canvasHeight - height; // will snap to bottom
  if (Math.abs((canvasHeight / 2) - (y + (height / 20))) <= (deltaY / 2) && Math.abs((canvasWidth / 2) - (x + (width / 2))) <= (deltaX / 2)) {
    snapY = Math.round((canvasHeight / 2) - (height / 2)); // will snap to center y
    snapX = Math.round(canvasWidth / 2) - (width / 2); // will snap to center x
  }
  return { x: snapX, y: snapY };
}

export const getSnapPosition = (x, y, width, height, canvasWidth, canvasHeight) => {

  // Center
  // x and y are 0 width and height is same as player width and height
  let position = "";
  if (Math.round(x) === 0 && Math.round(y) === 0 && Math.round(width) === Math.round(canvasWidth) && Math.round(height) === Math.round(canvasHeight)) {
    position = ELEMENT_POSITION.CENTER;
    return position;
  }

  // top
  // y is 0 and width or height or both are not equal to player width and height
  if (Math.round(y) === 0 && (Math.round(width) !== Math.round(canvasWidth) || Math.round(height) !== Math.round(canvasHeight)) && Math.round(x) !== 0)
    position = ELEMENT_POSITION.TOP;

  // left
  // x is 0 and width or height or both are not equal to player width and height
  if (Math.round(x) === 0 && (Math.round(width) !== Math.round(canvasWidth) || Math.round(height) !== Math.round(canvasHeight))) {
    // top left
    if (position === ELEMENT_POSITION.TOP)
      position = ELEMENT_POSITION.TOP_LEFT;
    // left
    else position = ELEMENT_POSITION.LEFT;
  }

  // right
  // x is equal to player width and width or height or both are not equal to player width and height
  if (Math.round(x + width) === Math.round(canvasWidth) && (Math.round(width) !== Math.round(canvasWidth) || Math.round(height) !== Math.round(canvasHeight))) {
    // top right
    if (position === ELEMENT_POSITION.TOP)
      position = ELEMENT_POSITION.TOP_RIGHT;
    // right
    else position = ELEMENT_POSITION.RIGHT;
  }
  // bottom
  // y is equal to player height and width or height or both are not equal to player width and height
  if (Math.round(y + height) === Math.round(canvasHeight) && (Math.round(width) !== Math.round(canvasWidth) || Math.round(height) !== Math.round(canvasHeight))) {
    // bottom left
    if (position === ELEMENT_POSITION.LEFT)
      position = ELEMENT_POSITION.BOTTOM_LEFT;
    // bottom right
    else if (position === ELEMENT_POSITION.RIGHT)
      position = ELEMENT_POSITION.BOTTOM_RIGHT;
    // bottom
    else position = ELEMENT_POSITION.BOTTOM;
  }

  return position;
}

export const getXYbySnapPosition = (x, y, width, height, canvasWidth, canvasHeight, snapPosition) => {
  switch (snapPosition) {
    case ELEMENT_POSITION.CENTER:
      return { x: Math.round((canvasWidth / 2) - (width / 2)), y: Math.round((canvasHeight / 2) - (height / 2)) };
      break;
    case ELEMENT_POSITION.LEFT:
      return { x: 0, y: y };
      break;
    case ELEMENT_POSITION.RIGHT:
      return { x: Math.round(canvasWidth - width), y: y };
      break;
    case ELEMENT_POSITION.TOP:
      return { x: x, y: 0 };
      break;
    case ELEMENT_POSITION.BOTTOM:
      return { x: x, y: Math.round(canvasHeight - height) };
      break;
    case ELEMENT_POSITION.TOP_LEFT:
      return { x: 0, y: 0 };
      break;
    case ELEMENT_POSITION.TOP_RIGHT:
      return { x: Math.round(canvasWidth - width), y: 0 };
      break;
    case ELEMENT_POSITION.BOTTOM_LEFT:
      return { x: 0, y: Math.round(canvasHeight - height) };
      break;
    case ELEMENT_POSITION.BOTTOM_RIGHT:
      return { x: Math.round(canvasWidth - width), y: Math.round(canvasHeight - height) };
      break;
    default:
      return { x, y };
  }
}

export const getHoveringTrack = (tracks, currentIndex, y) => {
  let videoIncrement = 72;
  let otherIncrement = 33;
  let index = currentIndex;
  if (y < 0) { // element is moving upwards
    while (y < 0 && index < tracks.length && tracks[index].type === VIDEO ? Math.abs(y) >= videoIncrement : Math.abs(y) >= otherIncrement) {
      y = y + (tracks[index + 1] && tracks[index + 1].type === VIDEO ? videoIncrement : otherIncrement);
      index++;
    }
    let incrementIndex = index < tracks.length - 1 && (tracks[index + 1].type === VIDEO ? Math.abs(y) >= (videoIncrement / 2) : Math.abs(y) >= (otherIncrement / 2));
    index = incrementIndex ? index + 1 : index;
    return index;
  }
  else if (y > 0) { // element is moving upwards
    while (y > 0 && index > -1 && tracks[index].type === VIDEO ? Math.abs(y) >= videoIncrement : Math.abs(y) >= otherIncrement) {
      y = y - (tracks[index].type === VIDEO ? videoIncrement : otherIncrement);
      index--;
    }
    let incrementIndex = index > 0 && (tracks[index - 1].type === VIDEO ? Math.abs(y) >= (videoIncrement / 2) : Math.abs(y) >= (otherIncrement / 2));
    index = incrementIndex ? index - 1 : index;
    return index;

  }
  return currentIndex;
}

export const textStylingSetting = {
  WIDTH: {
    HEADING: 40,
    SUB_HEADING: 50,
    BODY_TEXT: 40,
    ANIMATION: 30
  },
  FONT_SIZE: {
    HEADING: 72,
    SUB_HEADING: 48,
    BODY_TEXT: 30,
    ANIMATION: 48
  },
  TRANSFORMATION_PADDING: {
    HEADING: 20,
    SUB_HEADING: 10,
    BODY_TEXT: 10,
    ANIMATION: 50
  },
  DEFAULT_TEXT: {
    HEADING: "Heading",
    SUB_HEADING: "Sub heading",
    BODY_TEXT: "Text",
    ANIMATION: "New Text"
  }
}

export const getTrack = (tracks, element) => {
  return tracks.filter(track => track.elements[0].trackIndex === index).pop();
}

export const resetTrackAndElementsIndexes = (tracks) => {
  return tracks.map((t, i) => {
    t.level = i;
    t.elements = t.elements.map((e, j) => {
      e.elementIndex = j;
      e.trackIndex = i;

      if (e.subtitles && e.subtitles.length) {

        e.subtitles = e.subtitles.map((s, k) => {
          s.elementIndex = k;
          s.trackIndex = i;
          return s;
        });
      }

      return e;
    });
    return t;
  });
}

export const getTrackAndElementIndex = (tracks, element) => {
  let elementIndex = -1;
  let trackIndex = 0;

  // tracks = tracks.filter((t)=> {  return t.type.toLowerCase() === element.type.toLowerCase();});

  for (; trackIndex < tracks.length; trackIndex++) {
    const elements = tracks[trackIndex].elements;
    for (let j = 0; j < elements.length; j++) {
      if (tracks[trackIndex].elements[j].id === element.id) {
        elementIndex = j;
        break;
      }
    }
    if (elementIndex > -1)
      break;
  }
  if (elementIndex === -1)
    trackIndex = -1;
  return { trackIndex, elementIndex };
}

export const getSubtitlesTrackAndElementIndexFromVideo = (tracks, element) => {
  let elementIndex = -1;
  let trackIndex = 0;
  for (; trackIndex < tracks.length; trackIndex++) {
    if (tracks[trackIndex].type === SUBS) {
      const elements = tracks[trackIndex].elements;
      for (let j = 0; j < elements.length; j++) {
        if (tracks[trackIndex].elements[j].videoId === element.id) {
          elementIndex = j;
          break;
        }
      }
      if (elementIndex > -1)
        break;
    }
  }
  if (elementIndex === -1)
    trackIndex = -1;
  return { trackIndex, elementIndex };
}

export const prepareMetadataForSaving = () => {
  const store = index.getState();
  if (!store.tracksReducer.tracks.length) return null;

  return {
    tracks: removeThumbnail(store.tracksReducer.tracks),
    maxDuration: store.timelineReducer.maxDuration,
    selectedItemModel: store.tracksReducer.selectedItemModel,
    animationTypeRedux: store.animationsReducer.animationTypeRedux,
    muted: store.controlsReducer.muted,
    mediaList: store.uploadReducer.mediaList,
    subtitleStyle: store.subtitlesReducer.subtitleStyle,
    viewportPresetId: store.viewportReducer.preset_id,
    canvasColor: store.viewportReducer.background,
    canvasWidth: store.viewportReducer.width,
    canvasHeight: store.viewportReducer.height,
    renderingPreferences: store.renderReducer.renderingPreferences,
    subtitleVideos: clone(store.subtitlesReducer.videos).map(video => { video.loading = false; return video }),
    videoObj: store.uploadReducer.videoObj,
    zoom: store.timelineReducer.zoom,
    projectDetails: store.appStateInfoReducer.projectDetails
  }
}

export const getMaxDuration = (tracks = []) => {
  let max = -1;
  tracks.forEach((t) => {
    if (t.type !== Constants.SUBS) {
      t.elements.forEach((el) => {
        max = Math.max(max, el.end);
      });
    }
  });
  return max;
}

export const getMaxDurationOfTrack = (track) => {
  let max = -1;
  track.elements.forEach((el) => {
    max = Math.max(max, el.end);
  });
  return max;
}

export const getEnabledFeatures =  async (profileSettings) => {
  const enabledFeatures = [];
  if (!profileSettings.stitch_disabled) {
    enabledFeatures.push('Stitch');
  }
  if (!profileSettings.comment_disabled) {
    enabledFeatures.push('Comment');
  }
  if (!profileSettings.duet_disabled) {
    enabledFeatures.push('Duet');
  }

  return enabledFeatures;
}

export const  mapPrivacyOptions = async (privacyOptions) =>{
  const mappedOptions = privacyOptions.map(option => {
    return {
      value: option,
      label: option.replace(/_/g, ' ').toLowerCase().replace(/\b\w/g, c => c.toUpperCase())
    };
  });

  return mappedOptions;
}