import {
  MAX_TRACK_START_GAP,
  MAX_ELEMENT_GAP,
  MAX_MONTH_SPAN,
  VIDOES,
  TEXT,
} from '../constants'

import { hexToRgb, colourIsLight, nextColor } from '../utils'
import { TextModel } from '../models/TextModel';
import { VideoModel } from '../models/VideoModel';

export const buildMonthCells = (seconds) => {
  const v = []
  for (let i = 0; i < seconds / 5; i += 1) {
    const startMonth = i
    const start = i * 10
    const end = i * 10 + 10
    v.push({
      id: `m${startMonth}`,
      title: (i + 1) * 10 + 's',
      start,
      end,
    })
  }

  return v
}

export const buildTimebar = (seconds) => [

  {
    id: 'Timeline',
    title: 'Timeline',
    cells: buildMonthCells(seconds),
    useAsGrid: true,
    style: {
      backgroundColor: "#FFF",
      color: '#fff',
    },
  },
]

export const buildElement = ({ trackId, start, end, i, name, x }) => {
  if (trackId === TEXT) {

    let TextModelObj = new TextModel({
      id: `t-${trackId}-el-${i}`,
      text: name,
      xTimelinePosition: x,
      start,
      end,
      width: 200,
    })

    return TextModelObj;
  }
  else {
    const bgColor = nextColor()
    const color = colourIsLight(...hexToRgb(bgColor)) ? '#000000' : '#ffffff'
    return {
      id: `t-${trackId}-el-${i}`,
      title: name,
      start,
      end,
      style: {
        backgroundColor: `#${bgColor}`,
        color,
        borderRadius: '4px',
        boxShadow: '1px 1px 0px rgba(0, 0, 0, 0.25)',
        textTransform: 'capitalize',
        cursor: 'move'
      },
      x: x ? x : 0,
      y: 0,
      width: 100,
      duration: 10

    }
  }


}

export const buildTrackStartGap = () => Math.floor(Math.random() * MAX_TRACK_START_GAP)
export const buildElementGap = () => Math.floor(Math.random() * MAX_ELEMENT_GAP)

export const buildElements = (trackId, name) => {
  const v = []
  let i = 1
  const start = 0
  const end = Math.floor(Math.random() * (MAX_MONTH_SPAN) + 20);
  v.push(
    buildElement({
      trackId,
      start,
      end,
      i,
      name,
      duration: 10

    })
  )
  i += 1
  return v
}

export const buildSubtrack = (trackId, subtrackId, name) => ({
  id: `track-${trackId}-${subtrackId}`,
  title: `Subtrack ${subtrackId}`,
  elements: buildElements(subtrackId),

})

export const buildTrack = (trackTag, trackId, level) => {
  //const tracks = fill(1).map(i => buildSubtrack(trackId, i + 1, "FirstText")) // fille(1) must be change for dynamic add text functionality
  return {
    id: `${trackId}`,
    title: `${trackId}`,
    elements: [],//new VideoModel({ ...VIDOES[0], end: VIDOES[0].duration })]
    type: trackTag,
    tracks: [],
    // hasButton: true, // for button
    // link: 'www.google.com', // link
    isOpen: false,
    x: 0,
    y: 0,
    width: 100,
    level: level
  }
}
