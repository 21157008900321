
import AWS from 'aws-sdk'
import { CognitoUser, AuthenticationDetails, CookieStorage } from 'amazon-cognito-identity-js'
import Pool from './user-pool';
import { config } from "../config";
import { setUserInRedux } from "./index.helper";
import { dispatch, index } from '../store';
import { getworkspaceInfo } from '../store/middlewares/thunks/studio';
import jwt_decode from "jwt-decode";
import { UserModel } from '../models/userModel';
import { EXP_TIME_LEFT } from '../constants';

const cognito = new AWS.CognitoIdentityServiceProvider({ region: config.aws_region })
export const updateUserSession = async () => {
    return await getSession().then(async (session) => {
        console.log('User Details:', session);
        setUserInRedux(session);
        dispatch(getworkspaceInfo(session));
    }).catch((err) => {
        setUserInRedux(null);
        // window.location.href= "https://account.keevi.io"
    })
}

// Depends on ust.js
export const getUserTokens = async (code) =>{
    // let response = getSocialUserTokens(code);
    //let placeHolder = 'CognitoIdentityServiceProvider.3s891vbtrpkcdimr6sq44r1j0q.innayatullah%40hotmail.com.randomPasswordKey';

    //setCookie('CUSTOM_REDIRECT', options.CUSTOM_REDIRECT, 1);
    // console.log({response});
}

function setCookie(name, value, days) {
    var expires = '';
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = '; expires=' + date.toUTCString();
    }
    // document.cookie = name + '=' + (value || '') + expires + ';domain=' + options.DOMAIN + ';path=/';
}
export const getSession = async () =>
    await new Promise((resolve, reject) => {
        const user = Pool.getCurrentUser()
        if (user) {
            user.getSession(async (err, session) => {
                if (err) {
                    reject()
                } else {
                    const attributes = await new Promise((resolve, reject) => {
                        user.getUserAttributes((err, attributes) => {
                            if (err) {
                                reject(err)
                            } else {
                                const results = {}

                                for (let attribute of attributes) {
                                    const { Name, Value } = attribute
                                    results[Name] = Value
                                }

                                resolve(results)
                            }
                        })
                    })

                    const accessToken = session.accessToken.jwtToken

                    const mfaEnabled = await new Promise((resolve) => {
                        cognito.getUser(
                            {
                                AccessToken: accessToken,
                            },
                            (err, data) => {
                                if (err) resolve(false)
                                else
                                    resolve(
                                        data.UserMFASettingList &&
                                        data.UserMFASettingList.includes('SOFTWARE_TOKEN_MFA')
                                    )
                            }
                        )
                    })

                    const token = session.getIdToken().getJwtToken()
                    const accessTokenPayload = session.getAccessToken().payload;
                    const scope = accessTokenPayload.scope;
                    const permissionGroups = accessTokenPayload['cognito:groups'];

                    resolve({
                        user,
                        accessToken,
                        mfaEnabled,
                        headers: {
                            'x-api-key': attributes['custom:apikey'],
                            Authorization: token,
                        },
                        scope,
                        permissionGroups,
                        ...session,
                        ...attributes,
                    })
                }
            })
        } else {
            reject()
        }
    })



export const authenticate = async (Username, Password) =>
    await new Promise((resolve, reject) => {
        const user = new CognitoUser({ Username, Pool, Storage: new CookieStorage({ secure: false, domain: config.userPoolOrigin }) })
        const authDetails = new AuthenticationDetails({ Username, Password })

        user.authenticateUser(authDetails, {
            onSuccess: (data) => {
                console.log('onSuccess:', data)
                resolve(data)
            },

            onFailure: (err) => {
                console.error('onFailure:', err)
                reject(err)
            },

            newPasswordRequired: (data) => {
                console.log('newPasswordRequired:', data)
                resolve(data)
            },

            totpRequired: () => {
                const token = prompt('Please enter your 6-digit token')
                user.sendMFACode(
                    token,
                    {
                        onSuccess: () => {
                            // window.location.href = window.location.href
                        },
                        onFailure: () => alert('Incorrect code!'),
                    },
                    'SOFTWARE_TOKEN_MFA'
                )
            },
        })
    })



export const logout = () => {
    const user = Pool.getCurrentUser()
    if (user) {
        user.signOut()
        window.location.href = config.ustRedirectOrigin
    }
}


export const checkAndRefreshToken = async (token) => {
    try {
        const decoded = jwt_decode(token);
        const expirationDate = new Date(decoded.exp * 1000); //converting to milliseconds
        const currentTime = Date.now();
        const timeDifference = expirationDate - currentTime;

        if (timeDifference < EXP_TIME_LEFT) {
            const session = await getSession();
            const parsedData = new UserModel(session.idToken.payload, session.idToken.jwtToken);
            setUserInRedux(session);
            return parsedData.jwtToken;
        } else {
            return token;
        }
    } catch (error) {
        console.error('Error decoding token:', error);
        return null;
    }
};

export const refreshSession = async () => {
    const state = index.getState();
    const userDetails = state.userReducer.userDetails;
    const token = await checkAndRefreshToken(userDetails.jwtToken)
    return token
}