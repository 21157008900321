import toInteger from "lodash/toInteger";
import * as ActionTypes from "../../constants/ActionTypes";
import { SubtitlesStyleModel } from "../../models/SubtitlesStyleModel";
import { events } from '../index';

const initState = {
    subtitlesArray: null,
    subtitleStyle: new SubtitlesStyleModel({}),
    selectedVideo: null,
    videos: []
}

export default function subtitlesReducer(state = initState, action, store) {
    switch (action.type) {

        case ActionTypes.SET_SUBTITLES_LOADING_FROM_SERVER: {
            const videos = state.videos;
            const idx = videos.findIndex(v => v.id === action.payload.videoId);

            if(idx !== -1) {
                videos[idx].loading = action.payload.loading;
                videos[idx].completed = false;

                if(state.selectedVideo) {
                    if (state.selectedVideo.id === videos[idx].id) {
                        state.selectedVideo = {...videos[idx]};
                    }
                }
            }

            return {
                ...state
            }
        }

        case ActionTypes.SET_RESET_SUBTITLES: {
            const videos = state.videos;
            const idx = videos.findIndex(v => action.payload.videoId === v.id);

            if(idx !== -1) {
                videos[idx].loading = false;
                videos[idx].completed = false;

                if(state.selectedVideo) {
                    if (state.selectedVideo.id === videos[idx].id) {
                        state.selectedVideo = {...videos[idx]};
                    }
                }
            }

            return { ...state }
        }

        case ActionTypes.COMPUTE_ELEMENTS_RELATIVE_TRANSFORMATION: {
            const _state = { ...state }
            computeElementsTransformations(store.tracksReducer, store.viewportReducer, _state);
            return _state;
        }

        case ActionTypes.SET_SELECTED_VIDEOS_FOR_TRANSCRIBE: {
            const videos = state.videos;
            if(!Array.isArray(action.payload))
                action.payload = [action.payload];

            action.payload.forEach(vid => {
                const idx = videos.findIndex(v => v.id === vid.id);
                const video = idx !== -1 ? { ...videos[idx], ...vid } : { ...vid };

                if(idx !== -1) videos[idx] = video;
                else videos.push(video);
            })


            return { ...state, videos: [ ...videos ] };
        }

        case ActionTypes.SET_VIDEO_TO_TRANSCRIBE: {
            return {
                ...state,
                selectedVideo: action.payload
            }
        }

        case ActionTypes.SET_SUBTITLES_STYLE:
            state.subtitleStyle = action.payload;
            setTimeout(() => { events.dispatch_event('hashmap:generate') }, 0);
            return { ...state }

        case ActionTypes.SET_SUBTITLES_HASH: {
            return {
                ...state
            }
        }

        case ActionTypes.SET_SUBTITLES_COMPLETED: {
            const videos = state.videos;
            const idx = videos.findIndex(v => action.payload.videoId.indexOf(v.id) !== -1);

            if(idx !== -1) {
                videos[idx].loading = false;
                videos[idx].completed = true;

                if(state.selectedVideo) {
                    if (state.selectedVideo.id === videos[idx].id) {
                        state.selectedVideo = {...videos[idx]};
                    }
                }
            }

            return { ...state }
        }

        case ActionTypes.REMOVE_VIDEO_SUB_OBJECT: {
            const videoId = action.payload.elementToRemove.videoId;
            const videos = state.videos;
            const idx = videos.findIndex(v => v.id === videoId);

            if(idx !== -1) {
                videos[idx].loading = false;
                videos[idx].completed = false;

                if(state.selectedVideo) {
                    if(state.selectedVideo.id === videos[idx].id) {
                        state.selectedVideo = { ...videos[idx] };
                    }
                }
            }

            return { ...state }
        }

        default:
            return state
    }
}


const computeElementsTransformations = (tracks, viewport, state) => {
    const { canvasWidth, canvasHeight } = tracks;
    const oldWidth = (canvasWidth || viewport.width);
    const oldHeight = (canvasHeight || viewport.height);
    const widthRatio = viewport.width / (canvasWidth || viewport.width);
    const heightRatio = viewport.height / (canvasHeight || viewport.height);

    let ratioMultiplier = 1;
    if(Math.abs(viewport.width - oldWidth) > Math.abs(viewport.height - oldHeight)) {
        ratioMultiplier = widthRatio;
    } else {
        ratioMultiplier = heightRatio;
    }

    state.subtitleStyle = Object.assign({}, state.subtitleStyle, {
        divWidth: state.subtitleStyle.divWidth * ratioMultiplier,
        divHeight: state.subtitleStyle.divHeight * ratioMultiplier,
        fontSize: toInteger(state.subtitleStyle.fontSize * ratioMultiplier),
        xPosition: Math.round(state.subtitleStyle.xPosition * ratioMultiplier),
        yPosition: Math.round(state.subtitleStyle.yPosition * ratioMultiplier)
    });
}
