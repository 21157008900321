import axios from "axios";
import {config} from "../config/index";
import { checkAndRefreshToken, refreshSession } from "./ust.helper";
import { message } from "antd";
import { API_PATH, HTTP_STATUS, TOKEN_EXPIRED } from "../constants";

export const callNodeServerApi = async ({ url, payload, method, token }) => {
  const _token = await checkAndRefreshToken(token);

  return await axiosNodeApi({
    url,
    method,
    headers: {
      Authorization: `Bearer ${_token}`,
      "Content-Type": "application/json",
      "Cache-Control": "no-cache",
    },
    data: payload,
  })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      message.error("Something went wrong.");
      if(err.data.message == TOKEN_EXPIRED){
        if(url = API_PATH.LIST_FACEBOOK_PAGES){
          message.error("Connection with Facebook is expired Please reconnect it");
        } else {
          message.error("Connection with Instagram is expired Please reconnect it");
        }
      }
    });
};

const axiosNodeApi = axios.create({
  baseURL: config.urls.dashboardapiurl,
});

axiosNodeApi.interceptors.response.use(
  (response) => {
    return new Promise((resolve, reject) => {
      resolve(response);
    });
  },
  async (error) => {
    if (!error.response) {
      return new Promise((resolve, reject) => {
        reject(error);
      });
    }

    if ([HTTP_STATUS.UNAUTHORIZED, HTTP_STATUS.FORBIDDEN].indexOf(error.response.status) > -1) {
      window.location.href = config.ustRedirectOrigin;
    } else if ([HTTP_STATUS.GONE].indexOf(error.response.status) > -1) {
      const _token = await refreshSession();
      const originalRequest = error.config;
      originalRequest.headers["Authorization"] = `Bearer ${_token}`;
      return axiosNodeApi(originalRequest);
    } else {
      return new Promise((resolve, reject) => {
        reject(error);
      });
    }
  }
);
