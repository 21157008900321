import {
    v4 as uuidv4
} from 'uuid';

import {
    updateS3Url,
    updateVideoUploadProgress,
    setUploadStatus,
    setFileInfo,
    setShowUploadSuccessMessage
} from '../actions/UploadActions';
import {
    index
} from "../store";
import AWS from "aws-sdk";
import { CLOUDFRONT, TIMELINE_INCREMENT_INTERVAL, VIDEO } from '../constants';
import { updateUserInRedux, userAuthenticated, userNotAuthenticated } from "../actions/UserActions";
import { fetchAWSCredentials } from '../actions/AWSActions';
import { setLayersTracks } from "../actions/StudioActions";
import { UserModel } from "../models/userModel";
import { getTrackAndElementIndex, textStylingSetting } from './index';
import { s3 } from "./s3-helper";
import { APP, config } from "../config";
import connectSocket from "../utils/mqtt.helper"
export const connectSocketHelper = async () => {
    const user = getLocalUser();
    if (user) {
        if (user) {
            connectSocket(user.userId, user.email, user.jwtToken);
        }
    }
}
var s3UploadObj;
export const convertToSeconds = (time) => {
    var a = time.split(':'); // split it at the colons

    // minutes are worth 60 seconds. Hours are worth 60 minutes.
    var seconds = (+a[0]) * 60 * 60 + (+a[1]) * 60 + (+a[2]);
    return seconds;
}

export const convertToTimeString = (seconds) => {
    return new Date(seconds * 1000).toISOString().substr(11, 8)
}

export const getSanitizedValue = (val) => {
    return val.replace("<br>", "\n");
}

export const updateSelectedText = (arr, textData, zoom) => {
    let start = roundValue(textData.start)
    let end = roundValue(textData.end)

    const indexes = getTrackAndElementIndex(arr, textData);
    arr[indexes.trackIndex].elements[indexes.elementIndex] = textData;
    return {
        ...{
            arr: arr,
            start: start,
            end: end
        }
    };
}
export const setLocalUserFromRedux = () => {
    const state = index.getState();
    const userRedux = state.userReducer.userDetails
    if (userRedux === null)
        throw new Error("User is not logged in");
    localStorage.setItem('user', JSON.stringify(userRedux))
}

export const loadUserFromRedux = () => {
    var state = index.getState();
    return state.userReducer.loggedIn
}
export const setLocalUserFromGuest = async () => {
    const user = getLocalUser()
    const payload = {
        userId: user && user.userId ? user.userId : uuidv4(),
        email: null,
        jwtToken: null
    }
    localStorage.setItem('user', JSON.stringify(payload))
}
export const getLocalUser = () => {
    const user = localStorage.getItem('user');

    if (!user || user === 'null') {
        return null;
    } else {
        return JSON.parse(user)
    }
}

export const uploadFileToS3 = (fileInfo = null) => {
    return new Promise(async (resolve, reject) => {
        //    const { uploadStatus, fileInfo } = props;
        var state = index.getState();
        if (!fileInfo) fileInfo = state.uploadReducer.fileInfo;
        const id = fileInfo.id;
        delete fileInfo.id;
        //TODO need to remove it
        if (s3UploadObj) {
            s3UploadObj.abort();
            index.dispatch(updateVideoUploadProgress(0));
        }

        index.dispatch(setUploadStatus({
            status: 'pending',
            message: ""
        }));

        
        const credentials = await index.dispatch(fetchAWSCredentials({}));

        const s3 = new AWS.S3({
            region: config.aws_region,
            params: {
                Bucket: config.bucketName
            },
            accessKeyId: credentials.AccessKeyId,
            secretAccessKey: credentials.SecretAccessKey,
            sessionToken: credentials.SessionToken
        });

        s3UploadObj = s3.upload(fileInfo, async (err, data) => {
            if (err) {
                console.log(err)
                index.dispatch(setUploadStatus({
                    status: 'failed',
                    message: "Uploading failed "
                }));
                reject({
                    status: 'failed',
                    message: "Uploading failed "
                });
            } else {
                index.dispatch(setFileInfo(null));
                index.dispatch(setUploadStatus({
                    status: 'success',
                    message: "Uploading Completed"
                }));
                index.dispatch(setShowUploadSuccessMessage(true));
                setTimeout(function () {
                    index.dispatch(setShowUploadSuccessMessage(false));
                }, 20000);

                resolve({ url: data.Location, id: id });
            }
        });
        // Get upload status and display to user
        s3UploadObj.on('httpUploadProgress', progress => {
            var uploadProgress = Math.round(progress.loaded / progress.total * 100);
            index.dispatch(updateVideoUploadProgress(uploadProgress));
        });
    });
}

export const updateUrls = (type, response) => {
    const store = index.getState();
    const tracks = store.tracksReducer.tracks;

    for (let i = 0; tracks && i < tracks.length; i++) {
        let track = tracks[i];
        if (track.type === type) {
            for (let j = 0; j < track.elements.length; j++) {
                let element = track.elements[j];
                if (element.id === response.id) {
                    element.src = response.url;
                    element.file = response.url;
                    element.s3Url = response.url;
                    element.isUploaded = true;
                }
            }
        }
    }
    index.dispatch(setLayersTracks(tracks));
}

// Generic upload method
export const uploadFileToS3Comm = async (fileInfo, cb) => {

    index.dispatch(setUploadStatus({
        status: 'pending',
        message: ""
    }));

    
    const credentials = await index.dispatch(fetchAWSCredentials({}));
    
    const s3 = new AWS.S3({
        region: config.aws_region,
        params: {
            Bucket: config.bucketName
        },
        accessKeyId: credentials.AccessKeyId,
        secretAccessKey: credentials.SecretAccessKey,
        sessionToken: credentials.SessionToken
    });
    
    var s3ComUploadObj = s3.upload(fileInfo, async (err, data) => {
        if (err) {
            index.dispatch(setUploadStatus({
                status: 'failed',
                message: "Uploading failed "
            }));
        } else {
            //data.Location = CLOUDFRONT + data.Key;
            cb(data);
            index.dispatch(setUploadStatus({
                status: 'success',
                message: "Uploading Completed"
            }));
            index.dispatch(setShowUploadSuccessMessage(true));
            setTimeout(function () {
                index.dispatch(setShowUploadSuccessMessage(false));
            }, 20000);
        }
    });

    // Get upload status and display to user
    s3ComUploadObj.on('httpUploadProgress', progress => {
        var uploadProgress = Math.round(progress.loaded / progress.total * 100);
        index.dispatch(updateVideoUploadProgress(uploadProgress));
    });
    return s3ComUploadObj;
}

/**
 * Uses canvas.measureText to compute and return the width of the given text of given font in pixels.
 *
 * @param {String} text The text to be rendered.
 * @param {String} font The css font descriptor that text is to be rendered with (e.g. "bold 14px verdana").
 *
 * @see https://stackoverflow.com/questions/118241/calculate-text-width-with-javascript/21015393#21015393
 */
export const getTextDimensions = (text, font, animationType = 0) => {
    // re-use canvas object for better performance
    var canvas = getTextDimensions.canvas || (getTextDimensions.canvas = document.createElement("canvas"));
    var context = canvas.getContext("2d");
    context.font = font;
    var metrics = context.measureText(text);
    const boundingBoxTotal = metrics.actualBoundingBoxAscent + metrics.actualBoundingBoxDescent;
    const height = animationType === 0 ? (boundingBoxTotal + textStylingSetting.TRANSFORMATION_PADDING.SUB_HEADING) : (boundingBoxTotal + textStylingSetting.TRANSFORMATION_PADDING.ANIMATION);
    const width = animationType === 0 ? (metrics.width + textStylingSetting.TRANSFORMATION_PADDING.HEADING) : (metrics.width + textStylingSetting.TRANSFORMATION_PADDING.ANIMATION);
    return {
        height,
        width,
        actualBoundingBoxAscent: metrics.actualBoundingBoxAscent
    };
}
export const transformDivResize = (elemObj) => {
    const dimensions = getTextDimensions(elemObj.text, elemObj.fontWeight + " " + elemObj.fontSize + "px " + elemObj.fontFamily, elemObj.animationType);
    return { divWidth: dimensions.width, divHeight: dimensions.height }
}
export const getCanvasWidthHeight = () => {
    // re-use canvas object for better performance
    var canvas = getTextDimensions.canvas || (getTextDimensions.canvas = document.createElement("canvas"));
    return { canvas }
}

export const roundValue = (value, incrementInterval = TIMELINE_INCREMENT_INTERVAL) => {
    return Math.round(value / incrementInterval) * incrementInterval;
}

export const getExtension = (path) => {
    var basename = path.split(/[\\/]/).pop(), // extract file name from full path ...
        // (supports `\\` and `/` separators)
        pos = basename.lastIndexOf("."); // get last position of `.`

    if (basename === "" || pos < 1) // if file name is empty or ...
        return ""; //  `.` not found (-1) or comes first (0)

    return basename.slice(pos + 1); // extract extension ignoring `.`
}

export const updateTracksIndex = (array, start, flag) => {
    for (let i = start; i < array.length; i++) {
        for (let j = 0; j < array[i].elements.length; j++) {
            if (flag) {
                array[i].elements[j].trackIndex++;
            } else {
                array[i].elements[j].trackIndex--;
            }

        }
    }
    return array
}

export const convertSecondsToTime = (seconds) => {
    return new Date(seconds * 1000).toISOString().substr(11, 8)
}

export const getMilliseconds = (seconds) => {
    seconds = seconds.toString();
    return seconds.substring(seconds.indexOf('.') + 1);
}

/**
* Conserve aspect ratio of the original region. Useful when shrinking/enlarging
* images to fit into a certain area.
*
* @param {Number} srcWidth width of source image
* @param {Number} srcHeight height of source image
* @param {Number} maxWidth maximum available width
* @param {Number} maxHeight maximum available height
* @return {Object} { width, height }
*/
export const calculateAspectRatioFit = (srcWidth, srcHeight, maxWidth, maxHeight) => {

    var ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight);

    return { width: srcWidth * ratio, height: srcHeight * ratio };
}

export const setUserInRedux = (user) => {
    if(user) {
        const userModel = new UserModel(user.idToken.payload, user.idToken.jwtToken, user.scope, user.permissionGroups)
        index.dispatch(updateUserInRedux(userModel));
        index.dispatch(userAuthenticated(userModel));
    } else {
        setTimeout(() => index.dispatch(userNotAuthenticated(null)), 2000);
    }
}

let timer;
export const debounce = (cb, duration) => {

    return (...args) => {
        clearTimeout(timer);
        timer = setTimeout(() => {
            cb(...args);
        }, duration);
    };
}

export const convertSoundDuration = (duration) => {
    var minutes = (duration / 60).toFixed(2);
    return ((duration > 60) ? (minutes < 10 ? "0" + minutes : minutes) + "m" : "00:" + duration.toFixed(0) + "s").replace(".", ":");
}

export const convertTimeToSec = (time) => {
    var hms = time.toString();   // your input string
    var a = hms.split(':'); // split it at the colons

    if (a.length === 2) {
        a.unshift(0);
    }
    // minutes are worth 60 seconds. Hours are worth 60 minutes.
    var seconds = (+parseInt(a[0])) * 60 * 60 + (+parseInt(a[1])) * 60 + (+parseInt(a[2]));
    return seconds;
}

export const validateYoutubeUrl = (url) => {
    var p = /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
    if (url.match(p)) {
        return url.match(p)[1];
    }
    return false;
}

export const handleFavIcon = (favIcon) => {
    const favicon = document.getElementById("favicon"); // Accessing favicon element
    favicon.href = favIcon ?? APP.FAV_ICON
};

export const getFilenameFromUrl = (url) => {
    const pathname = new URL(url).pathname;
    const index = pathname.lastIndexOf('/');
    return (-1 !== index) ? pathname.substring(index + 1) : pathname;
};

export const formatBytes = (bytes, decimals = 0) => {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}
