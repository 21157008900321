// just change this file before build, that's it
import envJson from './environments/environment.bitesyzed.stage.json';
// import envJson from './environments/environment.vumu.json';
const googleFontApi = 'AIzaSyCs_kjnJ0k5q_uBHOfm0PcfLQEqAyErAho';
console.log(process.env);
const config = {
    DOMAIN: process.env.REACT_APP_DOMAIN,
    getSignedUrl: process.env.REACT_APP_GET_SIGNED_URL,
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    userPoolClientId: process.env.REACT_APP_USER_POOL_CLIENT_ID,
    userPoolOrigin: process.env.REACT_APP_USER_POOL_ORIGIN,
    aws_region: process.env.REACT_APP_AWS_REGION,
    ustRedirectOrigin: process.env.REACT_APP_UST_REDIRECT_ORIGIN,
    envOrigin: process.env.REACT_APP_ENV_ORIGIN,
    bucketName: process.env.REACT_APP_BUCKET_NAME,
    bucketBaseUrl: process.env.REACT_APP_BUCKET_BASE_URL,
    socketUrl: process.env.REACT_APP_SOCKET_URL,
    Pixabay: {
        PixabayAPIKey: process.env.REACT_APP_PIXABAY_KEY,
        PixabayVideosUrl: process.env.REACT_APP_PIXABAY_VIDEO_URL,
        PixabayImagesUrl: process.env.REACT_APP_PIXABAY_IMAGE_URL,
        PixabayAudiosUrl: process.env.REACT_APP_AUDIO_LIBRARY_URL,
    },
    urls: {
        screenrecorder: process.env.REACT_APP_SCREEN_RECORDER,
        extractyoutube: process.env.REACT_APP_YOUTUBE_EXTRACTION_URL,
        shareurl: process.env.REACT_APP_SHARE_URL,
        serverurl: process.env.REACT_APP_SERVER_URL,
        dashboardapiurl: process.env.REACT_APP_DASHBOARD_API_URL,
        dashboardUrl: process.env.REACT_APP_DASHBOARD_URL,
        renderserverurl: process.env.REACT_APP_DASHBOARD_API_URL,
        vumuserverless: process.env.REACT_APP_VUMU_SERVERLESS,
        mqttpresignedurl: process.env.REACT_APP_MQTT_PRESIGNED_URL,
        signinFormUrl: process.env.REACT_APP_SIGNIN_FORM_URL,
        LogOutUrl: process.env.REACT_APP_SIGNOUT_FORM_URL,
        YoutubeLink: process.env.REACT_APP_YOUTUBE_LINK
    },
    loginOptions: {
        DOMAIN: process.env.REACT_APP_DOMAIN,
        CUSTOM_REDIRECT: process.env.REACT_APP_LOGIN_CUSTOM_REDIRECT,
        MESSAGE: process.env.REACT_APP_LOGIN_MESSAGE,
        REGION: process.env.REACT_APP_AWS_REGION,
        USER_POOL_ID: process.env.REACT_APP_USER_POOL_ID,
        USER_POOL_CLIENT_ID: process.env.REACT_APP_USER_POOL_CLIENT_ID,
        REDIRECT_OAUTH_SIGNIN: process.env.REACT_APP_LOGIN_REDIRECT_OAUTH_SIGNIN,
        REDIRECT_OAUTH_SIGNOUT: process.env.REACT_APP_LOGIN_REDIRECT_OAUTH_SIGNOUT,
        CRISP_WEBSITE_ID: process.env.REACT_APP_LOGIN_CRISP_WEBSITE_ID,
        OAUTH_DOMAIN: process.env.REACT_APP_LOGIN_OAUTH_DOMAIN
    }
}
// object passed for the APP
const APP = envJson.APP

export {
    googleFontApi,
    config,
    APP
}
