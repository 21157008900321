import { index } from "./../store";
import {convertSecondsToTime, getMilliseconds, roundValue} from './../utils/index.helper';
const vttToJson = require("vtt-2-json")
const parseSRT = require('parse-srt')
const  subsrt = require('subsrt');
// generate SRT file
export const generateSrtContent = () => {

    let subs = getSubsData();
    let data = "";
    if (subs && subs.length) {
        var count = 1;
        for (var i in subs) {
            try {
                var start = subs[i].start;
                var startMs = getMilliseconds(start);
                var end = subs[i].end;
                var endMs = getMilliseconds(end);
                data += (count++) + "\n";
                data += convertSecondsToTime(start) + "," + startMs + "  -->  " + convertSecondsToTime(end) + "," + endMs + "\n";
                data += subs[i].text.trim() + "\n\n";
            }
            catch (ex) {
                console.log(ex.message);
            }
        }
    }
    return data;
}

// generate VTT file, VTT separates milliseconds with seconds by using a dot . instead of comma , in SRT
export const generateVttContent = () => {
    let data = getVttHeader();
    let subs = getSubsData();
    if (subs && subs.length) {
        var count = 1;
        for (var i in subs) {
            try {
                var start = subs[i].start;
                var startMs = getMilliseconds(start);
                var end = subs[i].end;
                var endMs = getMilliseconds(end);
                data += (count++) + "\n";
                data += convertSecondsToTime(start) + "." + startMs + "  -->  " + convertSecondsToTime(end) + "." + endMs + "\n";
                data += subs[i].text.trim() + "\n\n";
            }
            catch (ex) {
                console.log(ex.message);
            }
        }
    }
    return data;
}

// generate ASS file
export const generateAssContent = () => {
    let data = getAssHeader();
    let subs = getSubsData();
    if (subs && subs.length) {
        for (var i in subs) {
            try {
                var start = subs[i].start;
                var startMs = getMilliseconds(start);
                var end = subs[i].end;
                var endMs = getMilliseconds(end);
                data += "Dialogue: 0," + (convertSecondsToTime(start) + "." + startMs) + "," + (convertSecondsToTime(end) + "." + endMs) + ",Default,,0,0,0,," + subs[i].text.trim() + "\n";
            }
            catch (ex) {
                console.log(ex.message);
            }
        }
    }
    return data;
}

const getSubsData = () => {
    var state = index.getState();
    let tracks = state.tracksReducer.tracks;
    var subs = tracks.filter(x => x.type === 'Subs') ? tracks.filter(x => x.type === 'Subs') : [];
    if (subs && subs.length && subs[0].elements && subs[0].elements.length && subs[0].elements[0].subtitles) {
        subs = subs[0].elements[0].subtitles;
    }
    return subs;
}

const getVttHeader = () => {
    var header = "WEBVTT\n";
    header += "Keevi.io\n";
    return header + "\n";
}

const getAssHeader = () => {
    var header = `[Script Info]
; This is an Advanced Sub Station Alpha v4+ script. Generated by keevi.io"
Title: subtitles
ScriptType: v4.00+
Collisions: Normal
PlayDepth: 0

[V4+ Styles]
Format: Name, Fontname, Fontsize, PrimaryColour, SecondaryColour, OutlineColour, BackColour, Bold, Italic, Underline, StrikeOut, ScaleX, ScaleY, Spacing, Angle, BorderStyle, Outline, Shadow, Alignment, MarginL, MarginR, MarginV, Encoding
Style: Default,Arial,20,&H00FFFFFF,&H0300FFFF,&H00000000,&H02000000,0,0,0,0,100,100,0,0,1,2,1,2,10,10,10,1

[Events]
Format: Layer, Start, End, Style, Actor, MarginL, MarginR, MarginV, Effect, Text`;

    return header + "\n";
}
export const convertSrtToJson = async(srtString)=> {
    const  jsonSubs = parseSRT(srtString)
    return await parseSubsArray('srt', jsonSubs)
}

export const convertVttToJson = async (vttString)=> {
   return await vttToJson(vttString)
        .then(async (result) => {
          return await parseSubsArray('vtt', result)
        });
}
export const convertAssToJson = async (assString)=> {
    const options = { verbose: true };
    const captions = subsrt.parse(assString, options)
    return await parseSubsArray('ass', captions)

}
const parseSubsArray= async (type, array) => {
    let subsArray = []
    switch (type) {
        case 'srt':
            for(let obj of array){
                const subObj = {
                    start: roundValue((obj.start)),
                    end: roundValue((obj.end)),
                    part: obj.text.replaceAll("<br />", "\n")
                }
                subsArray.push(subObj)
            }
            break
        case 'vtt':
            for(let obj of array){
                const subObj = {
                    start: roundValue((obj.start)/1000),
                    end: roundValue((obj.end)/1000),
                    part: (obj.part).split('\n')[0]
                }
                subsArray.push(subObj)
            }
            break
        case 'ass':
            for(let obj of array){
                if(obj.type === 'caption'){
                    const subObj = {
                        start: roundValue((obj.start)/1000),
                        end: roundValue((obj.end)/1000),
                        part: obj.text
                    }
                    subsArray.push(subObj)
                }

            }
            break
        default:
            return null
    }

    return subsArray
}
