import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import createSagaMiddleware from 'redux-saga';
import { routerMiddleware, connectRouter } from 'connected-react-router';
import combineReducers from './reducers';
import rootSaga from './middlewares/sagas';
import EventDispatcher from '../utils/event-dispatcher';
import { batchedSubscribe } from 'redux-batched-subscribe';
import logger from 'redux-logger';
import debounce from 'lodash/debounce';
import { unstable_batchedUpdates } from 'react-dom';
import { HYDRATE } from '../constants/ActionTypes'
import { createBrowserHistory } from 'history';

export const persistedState = {};

const history = createBrowserHistory();
const sagaMiddleware = createSagaMiddleware();
const middlewares = [thunk, sagaMiddleware, routerMiddleware(history)];


const enhancers = []
if (process.env.NODE_ENV === 'development') {
    const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__

    if (typeof devToolsExtension === 'function') {
        enhancers.push(devToolsExtension({ trace: true }))
    }
}

// if(process.env.DEBUG === 'true') {
//     middlewares.push(logger);
// }

const debounceNotify = debounce(notify => notify());

const composedEnhancers = compose(
    applyMiddleware(...middlewares),
    ...enhancers,

    /* perf */
    batchedSubscribe(debounceNotify),
    batchedSubscribe(unstable_batchedUpdates) /* React 18 feature */
)

const index = createStore(combineReducers(history), compose(composedEnhancers));

sagaMiddleware.run(rootSaga);

const events = new EventDispatcher();

const dispatch = index.dispatch;

index.hydrate = () => {
    dispatch({ type: HYDRATE });
}

export { index, history, events, dispatch };
