import React from 'react'
export const LANGUAGE_JSON = [
    {
        "label": 'Afrikaans',
        "value": 'af'
    },
    {
        "label": 'Albanian',
        "value": 'sq'
    },
    {
        "label": 'Amharic',
        "value": 'am'
    },
    {
        "label": 'Arabic',
        "value": 'ar'
    },
    {
        "label": 'Azerbaijani',
        "value": 'az'
    },
    {
        "label": 'Bengali',
        "value": 'bn'
    },
    {
        "label": 'Bosnian',
        "value": 'bs'
    },
    {
        "label": 'Bulgarian',
        "value": 'bg'
    },
    {
        "label": 'Chinese (Simplified)',
        "value": 'zh'
    },
    {
        "label": 'Chinese (Traditional)',
        "value": 'zh-TW'
    },
    {
        "label": 'Croatian',
        "value": 'hr'
    },
    {
        "label": 'Czech',
        "value": 'cs'
    },
    {
        "label": 'Danish',
        "value": 'da'
    },
    {
        "label": 'Dari',
        "value": 'fa-AF'
    },
    {
        "label": 'Dutch',
        "value": 'nl'
    },
    {
        "label": 'English',
        "value": 'en'
    },
    {
        "label": 'Estonian',
        "value": 'et'
    },
    {
        "label": 'Finnish',
        "value": 'fi'
    },
    {
        "label": 'French',
        "value": 'fr'
    },
    {
        "label": 'French (Canada)',
        "value": 'fr-CA'
    },
    {
        "label": 'Georgian',
        "value": 'ka'
    },
    {
        "label": 'German',
        "value": 'de'
    },
    {
        "label": 'Greek',
        "value": 'el'
    },
    {
        "label": 'Hausa',
        "value": 'ha'
    },
    {
        "label": 'Hebrew',
        "value": 'he'
    },
    {
        "label": 'Hindi',
        "value": 'hi'
    },
    {
        "label": 'Hungarian',
        "value": 'hu'
    },
    {
        "label": 'Indonesian',
        "value": 'id'
    },
    {
        "label": 'Italian',
        "value": 'it'
    },
    {
        "label": 'Japanese',
        "value": 'ja'
    },
    {
        "label": 'Korean',
        "value": 'ko'
    },
    {
        "label": 'Latvian',
        "value": 'lv'
    },
    {
        "label": 'Malay',
        "value": 'ms'
    },
    {
        "label": 'Norwegian',
        "value": 'no'
    },
    {
        "label": 'Persian',
        "value": 'fa'
    },
    {
        "label": 'Pashto',
        "value": 'ps'
    },
    {
        "label": 'Polish',
        "value": 'pl'
    },
    {
        "label": 'Portuguese',
        "value": 'pt'
    },
    {
        "label": 'Romanian',
        "value": 'ro'
    },
    {
        "label": 'Russian',
        "value": 'ru'
    },
    {
        "label": 'Serbian',
        "value": 'sr'
    },
    {
        "label": 'Slovak',
        "value": 'sk'
    },
    {
        "label": 'Slovenian',
        "value": 'sl'
    },
    {
        "label": 'Somali',
        "value": 'so'
    },
    {
        "label": 'Spanish',
        "value": 'es'
    },
    {
        "label": 'Spanish (Mexico)',
        "value": 'es-MX'
    },
    {
        "label": 'Swahili',
        "value": 'sw'
    },
    {
        "label": 'Swedish',
        "value": 'sv'
    },
    {
        "label": 'Tagalog',
        "value": 'tl'
    },
    {
        "label": 'Tamil',
        "value": 'ta'
    },
    {
        "label": 'Thai',
        "value": 'th'
    },
    {
        "label": 'Turkish',
        "value": 'tr'
    },
    {
        "label": 'Ukrainian',
        "value": 'uk'
    },
    {
        "label": 'Urdu',
        "value": 'ur'
    },
    {
        "label": 'Vietnamese',
        "value": 'vi'
    }
]


export const AMAZON_TRANSCRIBE_LANGUAGES = [
    {
        "id": 1,
        "label": 'Gulf Arabic',
        "value": 'ar-AE'
    },
    {
        "id": 2,
        "label": 'Modern Standard Arabic',
        "value": 'ar-SA'
    },
    {
        "id": 3,
        "label": 'Chinese Mandarin - Mainland',
        "value": 'zh-CN'
    },
    {
        "id": 4,
        "label": 'Dutch',
        "value": 'nl-NL'
    },
    {
        "id": 5,
        "label": 'Australian English',
        "value": 'en-AU'
    },
    {
        "id": 6,
        "label": 'British English',
        "value": 'en-GB'
    },
    {
        "id": 7,
        "label": 'Indian English',
        "value": 'en-IN'
    },
    {
        "id": 8,
        "label": 'Irish English',
        "value": 'en-IE'
    },
    {
        "id": 9,
        "label": 'Scottish English',
        "value": 'en-AB'
    },
    {
        "id": 10,
        "label": 'US English',
        "value": 'en-US'
    },
    {
        "id": 11,
        "label": 'Welsh English',
        "value": 'en-WL'
    },
    {
        "id": 12,
        "label": 'Spanish',
        "value": 'es-ES'
    },
    {
        "id": 13,
        "label": 'US Spanish',
        "value": 'es-US'
    },
    {
        "id": 14,
        "label": 'French',
        "value": 'fr-FR'
    },
    {
        "id": 15,
        "label": 'Canadian French',
        "value": 'fr-CA'
    },
    {
        "id": 16,
        "label": 'Farsi',
        "value": 'fa-IR'
    },
    {
        "id": 17,
        "label": 'German',
        "value": 'de-DE'
    },
    {
        "id": 18,
        "label": 'Swiss German',
        "value": 'de-CH'
    },
    {
        "id": 19,
        "label": 'Hebrew',
        "value": 'he-IL'
    },
    {
        "id": 20,
        "label": 'Indian Hindi',
        "value": 'hi-IN'
    },
    {
        "id": 21,
        "label": 'Indonesian',
        "value": 'id-ID'
    },
    {
        "id": 22,
        "label": 'Italian',
        "value": 'it-IT'
    },
    {
        "id": 23,
        "label": 'Japanese',
        "value": 'ja-JP'
    },
    {
        "id": 24,
        "label": 'Korean',
        "value": 'ko-KR'
    },
    {
        "id": 25,
        "label": 'Malay',
        "value": 'ms-MY'
    },
    {
        "id": 26,
        "label": 'Portuguese',
        "value": 'pt-PT'
    },
    {
        "id": 27,
        "label": 'Brazilian Portuguese',
        "value": 'pt-BR'
    },
    {
        "id": 28,
        "label": 'Russian',
        "value": 'ru-RU'
    },
    {
        "id": 29,
        "label": 'Tamil',
        "value": 'ta-IN'
    },
    {
        "id": 30,
        "label": 'Telugu',
        "value": 'te-IN'
    },
    {
        "id": 31,
        "label": 'Turkish',
        "value": 'tr-TR'
    }
]

export const DOWNLOAD_FILE_FORMAT = [
    {
        "id": 1,
        "label": 'SubRip Subtitle files (.SRT)',
        "value": 'srt'
    },
    {
        "id": 2,
        "label": 'WebVTT (.VTT)',
        "value": 'vtt'
    },
    {
        "id": 3,
        "label": 'Advanced SubStation Alpha (.ASS)',
        "value": 'ass'
    }
]

export const SOCIAL_VIDEO_FORMATS_IDS = {
    ORIGINAL: 1,
    SQUARE: 2,
    YOUTUBE: 3,
    LANDSCAPE: 4,
    TWITTER_AND_FACEBOOK_PORTRAIT: 5,
    TWITTER_AND_FACEBOOK_LANDSCAPE: 6,
    STORY: 7
}

export const SOCIAL_VIDEO_FORMATS = [
    {
        "id": SOCIAL_VIDEO_FORMATS_IDS.ORIGINAL,
        "chipLabel": "Original",
        "label": <div className="screen-item-wrap" dangerouslySetInnerHTML={{
            __html: '<div class="screen-item">' +
                ' <div class="orig-bg">' +
                '<span class="text-white icon-ico-k-image"></span>' +
                '</div>  ' +
                '<label for="">Original</label>' +
                '<span class="res-value">--</span>' +
                '</div>'
        }} />,
        "value": '0:0',
        "factor": null,
        "key": 0
    },
    // Square <span class="pull-right">1:1</span>
    {
        "id": SOCIAL_VIDEO_FORMATS_IDS.SQUARE,
        "chipLabel": "Square",
        "label": <div className="screen-item-wrap" dangerouslySetInnerHTML={{
            __html: '<div class="screen-item">' +
                ' <div class="square-bg">' +
                '<span class="text-white icon-ico-k-image"></span> ' +
                '</div> ' +
                '<label for="">Square</label>' +
                '<span class="res-value">1:1</span>' +
                '</div>'
        }} />,
        "value": '1:1',
        "factor": 1,
        "key": 1
    },
    {
        "id": SOCIAL_VIDEO_FORMATS_IDS.YOUTUBE,
        "chipLabel": "Youtube",
        "label": <div className="screen-item-wrap" dangerouslySetInnerHTML={{
            __html: '<div class="screen-item">' +
                    '<div class="youtube-bg">' +
                ' <span class="text-white icon-ico-k-image"></span>  ' +
                '</div>' +
                '<label for="">Youtube</label>' +
                '<span class="res-value">16:9</span>' +
                '</div>'
        }} />,
        "value": '16:9',
        "factor": 1.78,
        "key": 2
    },
    {
        "id": SOCIAL_VIDEO_FORMATS_IDS.LANDSCAPE,
        "chipLabel": "Landscape",
        "label": <div className="screen-item-wrap" dangerouslySetInnerHTML={{
            __html: '<div class="screen-item">' +
                ' <div class="landscape-bg">' +
                '<span class="text-white icon-ico-k-image"></span> ' +
                '</div> ' +
                '<label for="">Landscape</label>' +
                '<span class="res-value">16:9</span>' +
                '</div>'
        }} />,
        "value": '16:9',
        "factor": 1.78,
        "key": 3
    },
    {
        "id": SOCIAL_VIDEO_FORMATS_IDS.TWITTER_AND_FACEBOOK_PORTRAIT,
        "chipLabel": "Twitter & Facebook Portrait",
        "label": <div className="screen-item-wrap" dangerouslySetInnerHTML={{
            __html:
                '<div class="screen-item">' +
                ' <div class="fbPort-bg">' +
                '<span class="text-white icon-ico-k-image"></span> ' +
                '</div> ' +
                '<label for="">Twitter & Facebook Portrait</label>' +
                '<span class="res-value">4:5</span>' +
                '</div>'
        }} />,
        "value": '4:5',
        "factor": 0.8,
        "key": 4
    },
    {
        "id": SOCIAL_VIDEO_FORMATS_IDS.TWITTER_AND_FACEBOOK_LANDSCAPE,
        "chipLabel": "Twitter & Facebook Landscape",
        "label": <div className="screen-item-wrap" dangerouslySetInnerHTML={{
            __html: '<div class="screen-item">' +
                ' <div class="fbLnad-bg">' +
                '<span class="text-white icon-ico-k-image"></span> ' +
                '</div> ' +
                '<label for="">Twitter & Facebook Landscape</label>' +
                '<span class="res-value">5:4</span>' +
                '</div>'
        }} />,
        "value": '5:4',
        "factor": 1.25,
        "key": 5
    },
    {
        "id": SOCIAL_VIDEO_FORMATS_IDS.STORY,
        "chipLabel": "Story",
        "label": <div className="screen-item-wrap" dangerouslySetInnerHTML={{
            __html: '<div class="screen-item">' +
                '<div class="story-bg">' +
                ' <span class="text-white icon-ico-k-image"></span> ' +
                '</div> ' +
                '<label for="">Story</label>' +
                '<span class="res-value">9:16</span>' +
                '</div>'
        }} />,
        "value": '9:16',
        "factor": 0.5625,
        "key": 6
    }
]

/**
 * Vide output qualities list
 */

export const VIDEO_OUTPUT_QUALITY = [
    {
        "id": 1,
        "label": '720p',
        "value": '720p'
    }
]
