import { message } from 'antd';
import clone from 'lodash/cloneDeep';
import { batch } from 'react-redux';
import * as Constants from "../constants";
import * as ActionTypes from "../constants/ActionTypes";
import { SubtitlesModel } from "../models/SubtitlesModel";
import { TextModel } from "../models/TextModel";
import { VideoModel } from "../models/VideoModel";
import * as utils from "../utils";
import { generateObjectId, generateUUID } from "../utils";
import { events } from "../store";
import * as builders from "../utils/index.builders";
import * as helpers from "../utils/index.helper";
import * as StudioActions from "./StudioActions";


export const setPlayPause = payload => dispatch => {
    dispatch({ type: ActionTypes.PLAY_VIDEO, payload });
}

export const setMute = (payload) => dispatch => {
    dispatch({ type: ActionTypes.SET_MUTED, payload })
}

export const performSplit = (payload) => (dispatch, getState) => {
    const store = getState();
    const selectedItem = store.tracksReducer.selectedItemModel;
    const normalizedCurrentTime = helpers.roundValue(store.cursorReducer.currentTime, Constants.TIMELINE_INCREMENT_INTERVAL);

    if (selectedItem) {
        const snapshot = payload.hashMap[normalizedCurrentTime];
        const targetItem = snapshot.find(snap => snap.id === selectedItem.id);
        if (targetItem) {
            if (targetItem.modelType === Constants.VIDEO) {
                splitVideoElement(store.tracksReducer, store.uploadReducer, payload.hashMap, targetItem, normalizedCurrentTime, dispatch);
            } else if (targetItem.modelType === Constants.TEXT) {
                splitTextElement(store.tracksReducer, store.uploadReducer, payload.hashMap, targetItem, normalizedCurrentTime, dispatch);
            }
        }
    }
}

const splitVideoElement = (studioR, videoR, hashMap, targetItem, normalizedCurrentTime, dispatch) => {
    const { tracks, zoom, muted } = studioR;
    const selectedItem = studioR.selectedItemModel;
    const indexes = utils.getTrackAndElementIndex(tracks, selectedItem);

    if (indexes.elementIndex > -1) {
        if (selectedItem.start + 5 < normalizedCurrentTime) {

            const newVideoId = utils.generateUUID();
            const layerElement = tracks[indexes.trackIndex].elements[indexes.elementIndex];
            // Generate new Video element in html and tracks
            const newItem = new VideoModel({
                ...selectedItem,
                targetStart: (parseFloat(normalizedCurrentTime) - selectedItem.start) + selectedItem.targetStart,
                start: parseFloat(normalizedCurrentTime),
                currentWidth: selectedItem.currentWidth === 0 ? selectedItem.originalWidth : selectedItem.currentWidth,
                currentHeight: selectedItem.currentHeight === 0 ? selectedItem.originalHeight : selectedItem.currentHeight,
                duration: selectedItem.duration,
                currentDuration: selectedItem.end - parseFloat(normalizedCurrentTime),
                videoElementId: utils.generateObjectId(),
                id: newVideoId,
                videoId: newVideoId
            });

            layerElement.targetEnd = newItem.targetStart;
            layerElement.end = normalizedCurrentTime;
            selectedItem.end = normalizedCurrentTime;
            layerElement.currentDuration = layerElement.end - layerElement.start;

            newItem.end = helpers.roundValue(newItem.end, Constants.TIMELINE_INCREMENT_INTERVAL);
            newItem.xTimelinePosition = parseFloat(layerElement.xTimelinePosition) + parseFloat(layerElement.currentDuration * zoom);
            newItem.trackIndex = layerElement.trackIndex;
            newItem.elementIndex = layerElement.elementIndex + 1;
            newItem.type = Constants.VIDEO;
            // newItem.subtitles = selectedItem.subtitles

            let subtitleObj = null;

            // Find if splitted part contains subtitles
            for (let key in hashMap) {
                if (key >= normalizedCurrentTime && key <= selectedItem.end) {
                    subtitleObj = hashMap[key].find(obj => obj.modelType === Constants.SUBS);
                    if (subtitleObj) {
                        break;
                    }
                }
            }

            // for spliting subs
            // const subtitles = splitSubtitles(tracks, subtitleObj, normalizedCurrentTime, selectedItem.id, newVideoId);
            // if (subtitles) {
            //     selectedItem.subtitles = subtitles;
            // }

            if (tracks[indexes.trackIndex + -1] && tracks[indexes.trackIndex + -1].type === Constants.SUBS) {
                const connectedSubtitles = tracks[indexes.trackIndex + -1].elements.find(el => el.videoId === selectedItem.id);
                if (connectedSubtitles) {
                    const clonnedConnectedSubtitles = clone(connectedSubtitles);
                    clonnedConnectedSubtitles.id = "Text-" + generateObjectId();
                    clonnedConnectedSubtitles.videoId = newItem.id;
                    clonnedConnectedSubtitles.start = newItem.start;
                    clonnedConnectedSubtitles.end = newItem.end;

                    clonnedConnectedSubtitles.subtitles = clonnedConnectedSubtitles.subtitles.map(sub => {
                        const _sub = clone(sub);
                        _sub.id = "Subs-" + generateUUID();
                        _sub.videoId = newItem.id;
                        return _sub;
                    });

                    tracks[indexes.trackIndex + -1].elements.splice(connectedSubtitles.elementIndex + 1, 0, clonnedConnectedSubtitles);
                    tracks[indexes.trackIndex + -1].elements.forEach((el, i) => { el.elementIndex = i });

                    dispatch({ type: ActionTypes.SET_SELECTED_VIDEOS_FOR_TRANSCRIBE, payload: { id: newItem.id, label: 'other-' + newItem.originalFileName, value: newVideoId } });
                    dispatch({ type: ActionTypes.SET_VIDEO_TO_TRANSCRIBE, payload: { id: newItem.id, label: 'other-' + newItem.originalFileName, value: newVideoId } });
                }
            }

            const element = utils.createVideoElement(newItem, muted);
            newItem.videoElement = element

            tracks[indexes.trackIndex].elements.splice(indexes.elementIndex + 1, 0, newItem);

            batch(() => {
                dispatch({ type: ActionTypes.MODIFY_VIDEO_OBJECT, payload: { id: layerElement.id, targetEnd: newItem.targetStart, end: normalizedCurrentTime, currentDuration: layerElement.currentDuration } });
                dispatch({ type: ActionTypes.ADD_VIDEO_OBJECT, payload: newItem });
                dispatch({ type: ActionTypes.SET_MAX_DURATION, payload: utils.getMaxDuration(tracks) });
                dispatch({ type: ActionTypes.SET_LAYERS_TRACKS, payload: utils.resetTrackAndElementsIndexes(tracks.reverse()) });
                dispatch({ type: ActionTypes.UPDATE_CURRENT_TIME, payload: normalizedCurrentTime });
                dispatch({ type: ActionTypes.SELECTED_OBJECT, payload: selectedItem });
            });

            events.dispatch_event('hashmap:generate', {
                start: layerElement.start,
                end: newItem.end
            });
        } else {
            message.error("Please increase split point more than 5 sec.");
        }
    }
}

const splitSubtitles = (tracks, subtitle, currentTime, prevVideoId, nextVideoId) => {
    const subTracks = tracks.filter(x => x.type === Constants.SUBS);
    let elements, subTrackIndex;

    for (let i in subTracks) {
        let subs = subTracks[i].elements.find(x => x.videoId === prevVideoId);
        if (subs) {
            elements = subs.subtitles;
            subTrackIndex = elements[0].trackIndex;
        }
    }

    if (!elements || elements.length === 0) {
        return;
    }

    let subtitles = JSON.parse(JSON.stringify(elements));

    if (subtitle) {
        const start = helpers.roundValue(subtitle.start)
        const end = helpers.roundValue(subtitle.end)
        //const { leftText, rightText } = splitTextByTime(subtitle, currentTime);

        //if (leftText) {
        // now replace left subtitle index
        elements[subtitle.elementIndex]['start'] = start
        elements[subtitle.elementIndex]['end'] = currentTime
        elements[subtitle.elementIndex]['startCopy'] = start
        elements[subtitle.elementIndex]['endCopy'] = currentTime
        // return
        //}

        // add right subtitle and shift indexes of the subtitles
        // const newSubtitleRight = new SubtitlesModel({
        //   ...subtitle,
        //   text: rightText,
        //   start: currentTime,
        //   end: end,
        //   startCopy: currentTime,
        //   endCopy: end,
        //   id: "Subs-" + generateUUID(),
        //   elementIndex: subtitle.elementIndex + 1,
        //   trackIndex: subTrackIndex,
        //   videoId: nextVideoId
        // });

        //subtitles.splice(subtitle.elementIndex + 1, 0, newSubtitleRight);
        subtitles[subtitle.elementIndex]['start'] = currentTime
        subtitles[subtitle.elementIndex]['end'] = end
        subtitles[subtitle.elementIndex]['startCopy'] = currentTime
        subtitles[subtitle.elementIndex]['endCopy'] = end

    }

    for (let j = 0; j < subtitles.length; j++) {
        subtitles[j] = new SubtitlesModel({ ...subtitles[j] });
        subtitles[j].id = Constants.SUBS + "-" + utils.generateUUID();
        subtitles[j].videoId = nextVideoId;
        subtitles[j].elementIndex = j;
    }

    tracks[subTrackIndex].elements.push({ videoId: nextVideoId, subtitles: subtitles });

    return subtitles;
}

const splitTextElement = (studioR, videoR, hashMap, targetItem, normalizedCurrentTime, dispatch) => {
    const { tracks } = studioR;
    const start = helpers.roundValue(targetItem.start)
    const end = helpers.roundValue(targetItem.end);
    //now replace left textObj index
    const indexes = utils.getTrackAndElementIndex(tracks, targetItem);
    const layerElement = tracks[indexes.trackIndex].elements[indexes.elementIndex]
    layerElement['start'] = helpers.roundValue(start);
    layerElement['end'] = normalizedCurrentTime;
    layerElement['text'] = targetItem.text;

    // add right textObj and shift indexes of the textObjs
    const newText = new TextModel({
        ...targetItem,
        start: normalizedCurrentTime,
        end: helpers.roundValue(end),
        id: "Text-" + utils.generateObjectId(),
    })

    const level = tracks[indexes.trackIndex].level + 1;
    const newTrack = builders.buildTrack(Constants.TEXT, generateUUID(), level);
    newText.trackIndex = level;
    newText.elementIndex = 0;
    newTrack.elements.push(newText)
    tracks.splice(indexes.trackIndex + 1, 0, newTrack)

    batch(() => {
        dispatch(StudioActions.setSelectedLayerElement(targetItem));
        dispatch({ type: ActionTypes.SET_MAX_DURATION, payload: utils.getMaxDuration(tracks) });
        dispatch({ type: ActionTypes.SET_LAYERS_TRACKS, payload: utils.resetTrackAndElementsIndexes(tracks.reverse()) });
    });

    events.dispatch_event('hashmap:generate');
}
