import axios from 'axios';
import { config } from '../config';
import { callNodeServerApi } from '../utils/interceptor';

export const socialAuthTwitter = async (req) => {
    if (config.urls.dashboardapiurl) {
        const res = await axios.post(`${config.urls.dashboardapiurl}/auth/twitter`, req, {
            headers: {
                "Content-Type": "application/json"
            },
        });
        return res.data
    }
}

export const sharePostApi = async (url, payload, token) => {
    const res = await callNodeServerApi({url, payload, method: "POST", token});
    return res.data
}
