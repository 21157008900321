export const SET_LAYERS_TRACKS = 'SET_LAYERS_TRACKS';
export const REMOVE_VIDEO_OBJECT = 'REMOVE_VIDEO_OBJECT';
export const REMOVE_VIDEO_SUB_OBJECT = 'REMOVE_VIDEO_SUB_OBJECT';
export const ADD_VIDEO_OBJECT = 'ADD_VIDEO_OBJECT';
export const MODIFY_VIDEO_OBJECT = 'MODIFY_VIDEO_OBJECT';
export const UPDATED_TEXT = 'UPDATED_TEXT';
export const DELETE_LAYER_ELEMENT = 'DELETE_LAYER_ELEMENT';
export const PLAY_VIDEO = 'PLAY_VIDEO';
export const UPDATE_CURRENT_TIME = 'UPDATE_CURRENT_TIME';
export const SET_BUFFERING_VALUE = 'SET_BUFFERING_VALUE';
export const SET_MAX_DURATION = 'SET_MAX_DURATION';
export const SELECTED_OBJECT = 'SELECTED_OBJECT';
export const SET_UPLOAD_PROGRESS = 'SET_UPLOAD_PROGRESS';
export const SET_REDUX_FROM_STATE_STUDIO = 'SET_REDUX_FROM_STATE_STUDIO';
export const SET_REDUX_FROM_STATE_VIDEO = 'SET_REDUX_FROM_STATE_VIDEO';
export const CHANGE_PANEL_ID = 'CHANGE_PANEL_ID';
export const SET_PROJECT_ID = 'SET_PROJECT_ID';
export const SET_UPLOAD_STATUS = 'SET_UPLOAD_STATUS';
export const SET_FILE_INFO = 'SET_FILE_INFO';
export const SET_SHOW_UPLOAD_SUCCESS_MESSAGE = 'SET_SHOW_UPLOAD_SUCCESS_MESSAGE';
export const SET_DOWNLOADING = 'SET_DOWNLOADING';
export const SET_CONNECTED = 'SOCKET_CONNECTED ✅';
export const SET_RE_CONNECTING = 'SOCKET_RE_CONNECTING 🤞';
export const CONNECTION_TRIES_RESET = 'CONNECTION_TRIES_RESET';
export const SET_RENDERINGS = "SET_RENDERINGS";
export const RENDERING_PROGRESS = 'RENDERING_PROGRESS';
export const YOUTUBE_DOWNLOAD_PROGRESS = 'YOUTUBE_DOWNLOAD_PROGRESS';
export const RENDERING_COMPLETED = 'RENDERING_COMPLETED';
export const RENDERING_FAILED = 'RENDERING_FAILED';
export const SET_RENDERING = 'SET_RENDERING';
export const SET_RENDERED_URL = 'SET_RENDERED_URL';

export const FETCH_AWS_CREDENTIALS_FROM_SERVER = 'FETCH_AWS_CREDENTIALS_FROM_SERVER';
export const SET_AWS_CREDENTIALS = 'SET_AWS_CREDENTIALS';

export const SET_SUBTITLES_LOADING_FROM_SERVER = 'SET_SUBTITLES_LOADING_FROM_SERVER';
export const SET_RESET_SUBTITLES = 'SET_RESET_SUBTITLES';
export const SET_SUBTITLES_COMPLETED = 'SET_SUBTITLES_COMPLETED';
export const SET_SUBTITLES_HASH = 'SET_SUBTITLES_HASH';
export const FETCH_SUBTITLES_FROM_SERVER = 'FETCH_SUBTITLES_FROM_SERVER';
export const SET_VIDEO_TO_TRANSCRIBE = "SET_VIDEO_TO_TRANSCRIBE";
export const SET_SELECTED_VIDEOS_FOR_TRANSCRIBE = 'SET_SELECTED_VIDEOS_FOR_TRANSCRIBE';

export const SET_TIMELINE_DIMENSIONS = 'SET_TIMELINE_DIMENSIONS';
export const SET_ZOOM_FACTOR = 'SET_ZOOM_FACTOR';
export const SET_BASE_ZOOM_FACTOR = 'SET_BASE_ZOOM_FACTOR';
export const SET_ZOOM_FACTOR_DISPLAY = 'SET_ZOOM_FACTOR_DISPLAY';

export const CHANGE_ANIMATION_TYPE = 'CHANGE_ANIMATION_TYPE';
export const CANCEL_BACKGROUND_JOB = 'CANCEL_BACKGROUND_JOB';
export const SET_MUTED = 'SET_MUTED';
export const SET_MEDIA_LIST = 'SET_MEDIA_LIST'
export const ADD_BACKGROUND_JOB = 'ADD_BACKGROUND_JOB';
export const REMOVE_BACKGROUND_JOB = 'REMOVE_BACKGROUND_JOB';
export const UPDATE_BACKGROUND_JOB = 'UPDATE_BACKGROUND_JOB';
export const SET_SUBTITLES_STYLE = 'SET_SUBTITLES_STYLE';
export const SET_VIEWPORT_SCREEN_SHOT = 'SET_VIEWPORT_SCREEN_SHOT';
export const UPDATE_USER = 'UPDATE_USER';
export const USER_AUTHENTICATED = 'USER_AUTHENTICATED';
export const SET_THUMBNAIL_SRC = 'SET_THUMBNAIL_SRC';
export const PROGRESS_SAVING = 'PROGRESS_SAVING';
export const PROGRESS_SAVED_SUCCESSFULLY = 'PROGRESS_SAVED_SUCCESSFULLY';
export const SAVE_PROJECT_OR_ADD_SNIPPET_PROJECT_TO_DASHBOARD = 'SAVE_PROJECT_OR_ADD_SNIPPET_PROJECT_TO_DASHBOARD';
export const SET_RENDERING_PREFS = "SET_RENDERING_PREFS";

export const SET_VIEWPORT_DIMENSIONS = 'SET_VIEWPORT_DIMENSIONS';
export const SET_VIEWPORT_BACKGROUND_COLOR = 'SET_VIEWPORT_BACKGROUND_COLOR';
export const SET_VIEWPORT_ASPECT_RATIO_PRESET = 'SET_VIEWPORT_ASPECT_RATIO_PRESET';

export const COMPUTE_ELEMENTS_RELATIVE_TRANSFORMATION = 'COMPUTE_ELEMENTS_RELATIVE_TRANSFORMATION';

export const ADD_PROJECT_KEEVI = 'ADD_PROJECT_KEEVI';
export const SET_PROJECT_DETAILS = 'SET_PROJECT_DETAILS';
export const UPDATE_PROJECT_KEEVI = 'UPDATE_PROJECT_KEEVI';
export const FETCH_PROJECT_INFO = 'FETCH_PROJECT_INFO';
export const SET_NEW_PROJECT = 'SET_NEW_PROJECT';
export const HYDRATE = 'HYDRATE';

export const SET_TITLE_FOR_TEMPLATE = 'SET_TITLE_FOR_TEMPLATE';
export const SET_THUMBNAIL_URL_FOR_TEMPLATE = 'SET_THUMBNAIL_URL_FOR_TEMPLATE';
export const SET_CATEGORY_FOR_TEMPLATE = 'SET_CATEGORY_FOR_TEMPLATE';
export const RESET_TEMPLATE_DATA = 'RESET_TEMPLATE_DATA';
export const SAVE_TEMPLATE_DATA = 'SAVE_TEMPLATE_DATA';
export const SET_TEMPLATE_STATUS = 'SET_TEMPLATE_STATUS';

export const STUDIO_STATE_READY = 'STUDIO_STATE_READY';

export const SOCIAL_SHARE_TWITTER = 'SOCIAL_SHARE_TWITTER';
export const SET_WORKSPACE = 'SET_WORKSPACE';
export const GET_PROFILE = 'GET_PROFILE';
export const SET_PROFILE = 'SET_PROFILE';
export const SET_INTEGRATION = 'SET_INTEGRATION';
