import { CognitoUserPool, CookieStorage } from 'amazon-cognito-identity-js';
import {config} from '../config';

console.log(config)
const poolData = {
  UserPoolId: config.userPoolId,
  ClientId: config.userPoolClientId,
  Storage: new CookieStorage({secure: false, domain: config.userPoolOrigin}),
};

export default new CognitoUserPool(poolData);
