import {
    UPDATE_USER,
    USER_AUTHENTICATED
} from '../constants/ActionTypes';

/**
 * actions for uplaoding the video
 */
export const updateUserInRedux = payload => dispatch => {
    dispatch({ type: UPDATE_USER, payload });
}

export const userAuthenticated = payload => dispatch => {
    dispatch({ type: USER_AUTHENTICATED, payload });
}

export const userNotAuthenticated = payload => dispatch => {
    dispatch({ type: USER_AUTHENTICATED, payload });
}
