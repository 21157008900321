import {
    SET_VIEWPORT_SCREEN_SHOT,
    SET_VIEWPORT_ASPECT_RATIO_PRESET,
    SET_VIEWPORT_BACKGROUND_COLOR,
    SET_VIEWPORT_DIMENSIONS
} from "../constants/ActionTypes";
import {getScreenshotHandler} from "../utils";

export const setViewportDimensions = (payload) => dispatch => {
    dispatch({ type: SET_VIEWPORT_DIMENSIONS, payload })
}

export const setViewportAspectRatioPreset = (payload) => dispatch => {
    dispatch({ type: SET_VIEWPORT_ASPECT_RATIO_PRESET, payload })
}

export const setViewportBackgroundColor = (payload) => dispatch => {
    dispatch({ type: SET_VIEWPORT_BACKGROUND_COLOR, payload })
}

export const generateViewPortScreenShot = (payload) => async (dispatch) => {
    try {
        const canvasRef = await getScreenshotHandler(payload.payload);
        if (canvasRef) {
            dispatch({
                type: SET_VIEWPORT_SCREEN_SHOT,
                payload: canvasRef
            });
        }
    } catch(error) {
        console.error(error);
    }
}
