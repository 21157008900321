import {addRecordingToVumu, getProfileApi, getProjectFromServer, getWorkspaceApi} from "../../../api/studio-apis";
import { index } from "../../index";
import { message } from "antd";
import {call, put, takeEvery} from "redux-saga/effects";
import * as ActionTypes from "../../../constants/ActionTypes";
import { handleFavIcon } from "../../../utils/index.helper";

export const saveRenderingToDashboard = (payload) => (dispatch) => {
    return new Promise(async (resolve, reject) => {
        const store = index.getState();

        let videoCompleted = store.renderReducer.list.filter(item => item.payload.frame.video_id === payload.payload.video_id)
        //action.payload.payload.url
        // It should always have one video when this is called
        let data = {
            _id: videoCompleted[0].payload.frame.video_id,
            videoUrl: payload.payload.url,
            parentDirectory: videoCompleted[0].payload.frame.type === 'snippet' ? videoCompleted[0].payload.frame.parentDirectory : null,
            createdBy: videoCompleted[0].payload.user_id,
            title: videoCompleted[0].payload.frame.type === 'snippet' ? `${videoCompleted[0].payload.frame.title}` : store.appStateInfoReducer.projectDetails?.name,
            subVideos: [videoCompleted[0].payload.frame.video_id]
        }

        try {
            const response = await addRecordingToVumu(data);
            resolve(response);
        } catch (error) {
            console.log({error});
            message.error("There is some problem while updating project for dashboard!");
            reject();
        }
    });
}


export const fetchProjectInfo = (payload) => (dispatch) => {
    return new Promise((resolve, reject) => {
        fetchProjectDetails(payload['payload']).then(response => {
            if(response.status === 200) {
                const data = response.data.data;
                if(data) {
                    dispatch({ type: ActionTypes.SET_PROJECT_DETAILS, payload: data })
                    resolve(data);
                } else {
                    reject(null);
                }
            } else {
                reject(response);
            }
        }).catch((error) => {
            message.error(error.message);
            reject(error);

        });
    })
}

export const getworkspaceInfo = (payload) => (dispatch) => {

    return new Promise((resolve, reject) => {
        getWorkspaceApi({userId: payload.sub, email: payload.email}, payload?.idToken?.jwtToken).then(response => {
            if(response.status === 200) {
                const data = response.data;
                if(data) {
                    dispatch({ type: ActionTypes.SET_WORKSPACE, payload: data })
                    handleFavIcon(data.workspaceFavIcon)
                    resolve(data);
                } else {
                    reject(null);
                }
            } else {
                reject(response);
            }
        }).catch((error) => {
            message.error(error.message);
            reject(error);

        });
    })
}

async function fetchProjectDetails(payload) {
    return await getProjectFromServer(payload);
}


export const getProfileInfo = (payload) => (dispatch) => {

    return new Promise((resolve, reject) => {
        getProfileApi({ activeEmail: payload.activeEmail }, payload.token).then(response => {
            if (response.status === 200) {
                const data = response.data;
                if (data) {
                    dispatch({ type: ActionTypes.SET_PROFILE, payload: data })
                    resolve(data);
                } else {
                    reject(null);
                }
            } else {
                reject(response);
            }
        }).catch((error) => {
            message.error(error.message);
            reject(error);
        });
    })
}