/* eslint-disable import/no-unresolved */
import React, { Component, lazy, Suspense } from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch, withRouter } from 'react-router-dom';
import * as UserActions from '../actions/UserActions';
import AppLoading from '../components/Loaders/AppLevel';
import { dispatch } from '../store';
import { connectSocketHelper, getLocalUser, setLocalUserFromGuest, setLocalUserFromRedux } from "../utils/index.helper";
import { updateUserSession } from "../utils/ust.helper";

const Studio = lazy(() => import('../pages/studio'));
const RenderComponent = lazy(() => import('../pages/render-screen'));

class RouterClass extends Component {
  bootApp = async () => {
      await updateUserSession();
  };

  componentDidMount() {
    this.bootApp().then(res => {
      setLocalUserFromRedux()
      connectSocketHelper();
    }).catch(async () => {
      await setLocalUserFromGuest();
      const user = getLocalUser();
      dispatch(UserActions.updateUserInRedux(user))
      connectSocketHelper();
    })
  }

  render() {
    return (
      <Router >
        <Suspense fallback={AppLoading()}>
          <Switch>
            <Route path="/studio" exact component={withRouter(Studio)} />
            <Route path="/studio/:id" exact component={withRouter(Studio)} />
            <Route path="/render/:id" exact component={withRouter(RenderComponent)} />
            <Redirect from={`/`} to={`/studio`} />
            <Redirect from="*" to={{ pathname: "/404" }} />
          </Switch>
        </Suspense>
      </Router>
    )
  }
}


export default RouterClass
