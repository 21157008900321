import {
    SET_UPLOAD_PROGRESS,
    SET_REDUX_FROM_STATE_VIDEO, SET_UPLOAD_STATUS, SET_FILE_INFO, SET_SHOW_UPLOAD_SUCCESS_MESSAGE,
    SET_MEDIA_LIST, REMOVE_VIDEO_OBJECT, ADD_VIDEO_OBJECT,
    MODIFY_VIDEO_OBJECT
} from '../../constants/ActionTypes';

const initState = {
    fileInfo: null,
    progress: 0,
    uploadStatus: { status: null, message: "" },
    showSuccessMessage: true,

    // TODO: videoObj, thumbnailUrl, mediaList needs to be ripped off.
    videoObj: null,
    mediaList: [],
    thumbnailUrl: null,
}


export default function uploadReducer(state = initState, action) {
    switch (action.type) {
        case ADD_VIDEO_OBJECT:
            return {
                ...state,
                videoObj: state.videoObj === null ? [action.payload] : [...state.videoObj, action.payload]
            }
        case MODIFY_VIDEO_OBJECT: {
            const videoObject = state.videoObj.find(x => x.id === action.payload.id);

            videoObject.targetEnd = action.payload.targetStart ? action.payload.targetStart : videoObject.targetEnd;
            videoObject.end = action.payload.end ? action.payload.end : videoObject.end;
            videoObject.currentDuration = action.payload.currentDuration ? action.payload.currentDuration : videoObject.currentDuration;
            videoObject.src = action.payload.src ? action.payload.src : videoObject.src;

            return { ...state, videoObj: state.videoObj === null ? null : [ ...state.videoObj ] }
        }
        case REMOVE_VIDEO_OBJECT:
            return {
                ...state,
                videoObj: state.videoObj.filter(obj => obj.id !== action.payload.id)
            }
        case SET_UPLOAD_PROGRESS:
            return {
                ...state,
                progress: action.payload
            }
        case SET_REDUX_FROM_STATE_VIDEO:
            return {
                ...state,
                ...action.payload
            }
        case SET_UPLOAD_STATUS:
            return {
                ...state,
                uploadStatus: action.payload
            }
        case SET_FILE_INFO:
            return {
                ...state,
                fileInfo: action.payload
            }
        case SET_SHOW_UPLOAD_SUCCESS_MESSAGE:
            return {
                ...state,
                showSuccessMessage: action.payload
            }
        case SET_MEDIA_LIST:
            return {
                ...state,
                mediaList: action.payload
            }
        default:
            return state
    }
}
