import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux';
import { index } from './store';
import Routes from './routes';
import { AppContextProvider } from "./contexts/AppContext";
import { initialize } from './managers'
import { initDatadog } from './utils/app-init'
import './styles/css/index.css';
import './styles/scss/app-global.css';
import 'antd/dist/antd.css';
import "./styles/fonts.css";

initDatadog()
const managers = initialize();

ReactDOM.render(
    <Provider store={index}>
        <AppContextProvider value={managers}>
            <Routes />
        </AppContextProvider>
    </Provider>, document.getElementById('app'))
