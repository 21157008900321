import { UPDATE_CURRENT_TIME, SET_BUFFERING_VALUE } from '../../constants/ActionTypes';

const initState = {
    currentTime: 0,
    buffering: false
}

export default function tracksReducer(state = initState, action) {
    switch (action.type) {
        case UPDATE_CURRENT_TIME: {
            return {
                ...state,
                currentTime: action.payload,
            }
        }

        case SET_BUFFERING_VALUE: {
            return {
                ...state,
                buffering: action.payload
            }
        }

        default:
            return state
    }
}
