import {
    SET_RENDERINGS,
    RENDERING_COMPLETED,
    SET_DOWNLOADING,
    RENDERING_FAILED,
    SET_THUMBNAIL_SRC,
    RENDERING_PROGRESS,
    SET_RENDERING,
    SET_RENDERED_URL,
    SET_RENDERING_PREFS
} from '../constants/ActionTypes';

export const setRenderingPreferences = (payload) => dispatch => {
    dispatch({ type: SET_RENDERING_PREFS, payload });
}

export const setRenderings = (payload) => dispatch => {
    dispatch({ type: SET_RENDERINGS, payload })
}

export const renderingCompleted = payload => dispatch => {
    dispatch({ type: RENDERING_COMPLETED, payload });
}

export const renderingProgress = payload => dispatch => {
    dispatch({ type: RENDERING_PROGRESS, payload });
}

export const renderingFailed = payload => dispatch => {
    dispatch({ type: RENDERING_FAILED, payload });
}

export const setRendering = payload => dispatch => {
    dispatch({ type: SET_RENDERING, payload });
}

export const setRenderedUrl = payload => dispatch => {
    dispatch({ type: SET_RENDERED_URL, payload });
}

export const setThumbnailSrc = (payload) => dispatch => {
    dispatch({ type: SET_THUMBNAIL_SRC, payload });
}

export const setDownloading = (payload) => dispatch => {
    dispatch({ type: SET_DOWNLOADING, payload });
}

