/**
 *
 * @param {*} data
 * Default render video model which will contain all the nesassary attributes for the payload used in backend.
 */
export function RenderAudioModel(data) {
    this.id = data.id
    this.start = data.targetStart
    this.end = data.targetEnd
    this.showtime = data.showTime
    this.src = data.src
    this.volume = data.volume
    this.level = data.level
}


