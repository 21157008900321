import _isEmpty from 'lodash/isEmpty';
import { AUDIO, DEFAULT_AUDIO_THUMBNAIL_HEIGHT, DEFAULT_VIDEO_RATIO, TRANSPARENT_BACKGROUND_COLOUR, VIDEO } from "../../constants";
import {
    SET_VIEWPORT_ASPECT_RATIO_PRESET, SET_VIEWPORT_BACKGROUND_COLOR, SET_VIEWPORT_DIMENSIONS, SET_VIEWPORT_SCREEN_SHOT
} from '../../constants/ActionTypes';
import { SOCIAL_VIDEO_FORMATS, SOCIAL_VIDEO_FORMATS_IDS } from "../../utils/sample-payloads";

const initState = {
    preset_id: SOCIAL_VIDEO_FORMATS.filter(x => x.id === SOCIAL_VIDEO_FORMATS_IDS.ORIGINAL).pop().id,
    screenshot_canvas_ref: null,
    background: TRANSPARENT_BACKGROUND_COLOUR,
    available: { width: 0, height: 0 },
    width: 0,
    height: 0
}

export default function viewportReducer(state = initState, action, store) {
    switch (action.type) {
        case SET_VIEWPORT_DIMENSIONS: {
            const _state = {
                ...state,
                available: {
                    width: action.payload.available.width,
                    height: action.payload.available.height,
                }
            };

            if(store.tracksReducer.tracks)
                computeDimensions(_state, store.tracksReducer.tracks);

            return _state;
        }

        case SET_VIEWPORT_ASPECT_RATIO_PRESET: {
            const _state = {
                ...state,
                preset_id: action.payload.presetId
            };

            if(store.tracksReducer.tracks)
                computeDimensions(_state, store.tracksReducer.tracks);

            return _state;
        }

        case SET_VIEWPORT_BACKGROUND_COLOR:
            return {
                ...state,
                background: action.payload.background
            }

        case SET_VIEWPORT_SCREEN_SHOT:
            return {
                ...state,
                screenshot_canvas_ref: action.payload
            }

        default:
            return state
    }
}

const computeDimensions = (viewport, tracks) => {
    let dimensions;
    if (viewport.preset_id === SOCIAL_VIDEO_FORMATS_IDS.ORIGINAL) {
        dimensions = computeDimensionsWithOriginalPreset(viewport, tracks);
    } else {
        dimensions = computeDimensionsWithPreset(viewport);
    }

    viewport.width = dimensions.newWidth;
    viewport.height = dimensions.newHeight;
}

const computeDimensionsWithPreset = (viewport) => {
    const preset = SOCIAL_VIDEO_FORMATS.filter(x => x.id === viewport.preset_id).pop();

    let newHeight = viewport.available.height;
    let newWidth = viewport.available.height * preset.factor;

    // find out if width is more than available area
    if (newWidth > viewport.available.width) {
        newWidth = viewport.available.width;
        newHeight = newWidth / preset.factor;
    }

    return { newWidth, newHeight };
}

const computeDimensionsWithOriginalPreset = (viewport, tracks) => {
    let maxWidth = 0;
    let maxHeight = 0;

    if(tracks.length > 0) {
        // first find all videos and their dimensions to calculate canvas width and height
        const mediaTracks = tracks.filter(item => [VIDEO,AUDIO].includes(item.type)) 
        mediaTracks.forEach((mediaTrack) => {
            // Audio will be skip because originalWidth is 0
            mediaTrack.elements.forEach((vid) => {
                maxWidth = Math.max(maxWidth, vid.originalWidth);
                maxHeight = Math.max(maxHeight, vid.originalHeight);
            });
        });

        let newHeight, newWidth;
        const ratio = (maxWidth / maxHeight) || DEFAULT_VIDEO_RATIO
        newHeight = viewport.available.height;
        newWidth = (newHeight * (maxWidth / maxHeight));

        if (isNaN(newWidth) && !_isEmpty(mediaTracks)) {
            newHeight = DEFAULT_AUDIO_THUMBNAIL_HEIGHT
            newWidth = newHeight * DEFAULT_VIDEO_RATIO
        }

        // find out if width is more than available area
        if (newWidth > viewport.available.width) {
            newWidth = viewport.available.width;
            newHeight = (newWidth * ratio);
        }

        return { newWidth, newHeight };
    } else {
        return { newWidth: 0, newHeight: 0 };
    }
}

/*
// let { currentCanvasWidth, currentCanvasHeight, vidWidthRatio, vidHeightRatio } = this.getCanvasSizeOnFormatChange(options, tracks);


dispatch(ViewportActions.setViewportDimensions({ width: currentCanvasWidth, height: currentCanvasHeight }));

for (let i = 0; tracks && i < tracks.length; i++) {
    let tempObj = tracks[i];
    for (let j = 0; j < tempObj.elements.length; j++) {
        let element = tempObj.elements[j];
        if (tempObj.type === VIDEO) {

            if ((element.currentWidth === 0 && element.currentHeight === 0) || (options && options.isSocialFormatChanged)) {
                let projectionRatio = 1;
                // check if the canvas is portrait or landscape
                if (element.originalHeight > element.originalWidth) {  // portrait
                    projectionRatio = currentCanvasHeight / element.originalHeight;
                    let newWidth = Math.round(element.originalWidth * projectionRatio);
                    if (newWidth > currentCanvasWidth)
                        projectionRatio = currentCanvasWidth / element.originalWidth;
                } else { // landscape
                    projectionRatio = currentCanvasWidth / element.originalWidth;
                    let newHeight = Math.round(element.originalHeight * projectionRatio);
                    if (newHeight > currentCanvasHeight)
                        projectionRatio = currentCanvasHeight / element.originalHeight;
                }
                element.currentWidth = element.originalWidth * projectionRatio;
                element.currentHeight = element.originalHeight * projectionRatio;
            }
            else {
                element.currentWidth = element.currentWidth * vidWidthRatio;
                element.currentHeight = element.currentHeight * vidHeightRatio;
            }
            dispatch(ViewportActions.setViewportDimensions({ width: currentCanvasWidth, height: currentCanvasHeight }));
        }
        else if (tempObj.type === SUBS) {
            let subtitleStyleObject = Object.assign({}, subtitleStyleObj, {
                divWidth: subtitleStyleObj.divWidth * vidWidthRatio,
                divHeight: subtitleStyleObj.divHeight * vidHeightRatio,
                xPosition: Math.round(subtitleStyleObj.xPosition * vidWidthRatio),
                yPosition: Math.round(subtitleStyleObj.yPosition * vidHeightRatio)
            });
            this.props.setSubtitleStyle(subtitleStyleObject)
        }
        else {
            element.divHeight = vidHeightRatio * element.divHeight;
            element.divWidth = vidWidthRatio * element.divWidth;
        }

        // Based on width and height of element change it's position on canvas
        if (element.alignment !== "") {
            let position = getXYbySnapPosition(element.xPosition, element.yPosition,
                element.currentWidth ? element.currentWidth : element.divWidth, element.currentHeight ? element.currentHeight : element.divHeight,
                currentCanvasWidth, currentCanvasHeight, element.alignment);
            element.xPosition = position.x;
            element.yPosition = position.y;
        }
        else if (tempObj.type !== SUBS) {
            element.xPosition = Math.round(element.xPosition * vidWidthRatio);
            element.yPosition = Math.round(element.yPosition * vidHeightRatio);
        }
    }
}
this.updateCompleteLayer(tracks)

const getCanvasSizeOnFormatChange = (options, obj) => {
    let { socialDimension } = this.props;
    if (obj && options && options.isSocialFormatChanged) {
        // First set all the video elements to center aligned and reset their current width height to 0 so that it can be calculated again
        let _vidTracks = obj.filter((item, index) => { return item.type === VIDEO; });
        _vidTracks.forEach((_track) => {
            _track.elements.forEach((_el) => {
                _el.alignment = ELEMENT_POSITION.CENTER;
                _el.currentWidth = 0;
                _el.currentHeight = 0;
            })
        })
    }
    if (socialDimension.width === 0 && socialDimension.height === 0) {
        return this.original(options);
    }

    // if there are options and dimensions has changed now
    if (options && options.isSocialFormatChanged) {
        return this.getSocialFormatCanvasDimensionsOnChange();
    }
    else {
        return this.getSocialFormatCanvasDimensions();
    }
}
*/
