import { renderingCompleted, renderingFailed, renderingProgress } from "../actions/RenderActions";
import { setConnected, setReConnecting } from "../actions/ConnectionActions";
import { index } from "../store";
import { message } from 'antd';
import * as SubtitlesActions from '../actions/SubtitlesActions';
import {ytdProgress} from "../actions/StudioActions";
import {saveProjectShare} from "../actions/AppActions"
import { convertToSubsJson, convertSrtToSubsJson } from "./index";
import {saveRenderingToDashboard} from "../actions/AppActions";
import {config} from '../config'
import * as utils from '../utils';
import { setProfile } from "../actions/share";

export default async (clientId, username, JWTtoken) => {
    let socket = null;
    let pingPongInterval = null;
    let totaltries = 10;

    const pingPong = () => {
        if (!socket || socket.readyState !== 1) {
            connect();
            return;
        }
        socket.send("heartbeat");
    }
    const connect = () => {
        if(pingPongInterval)
            clearInterval(pingPongInterval);

        //Set the websocket server URL
        const wsUrl =  config.socketUrl + clientId;
        //Create the WebSocket object (web socket echo test service provided by websocket.org)
        socket = new WebSocket(wsUrl);
        pingPongInterval = setInterval(pingPong, 15000);
        //This function is called when the websocket connection is opened
        socket.onopen = function () {
            index.dispatch(setConnected({ value: true }));
        };
        //This function is called when the websocket connection is closed
        socket.onclose = function () {
            totaltries--;
            if (totaltries > 0) {
                connect();
                index.dispatch(setReConnecting());
            }

        };
        socket.onerror = async (event) => {
            totaltries--;
            if(totaltries > 0){
                console.log({ event });
                connect();
                index.dispatch(setReConnecting());
            }
        }
        //This function is called when the websocket receives a message. It is passed the message object as its only parameter
        socket.onmessage = function (messageRecieved) {
            let msg = messageRecieved.data.toString('utf8');
            if(msg === 'ping') {
                socket.send("pong");
            } else {
                msg = JSON.parse(messageRecieved.data.toString('utf8'));
                msg = msg.payload;
                if (msg.messageType === 'RENDERING') {
                    switch (msg.data.type) {
                        case 'PROGRESS':
                            index.dispatch(renderingProgress( msg.data ));
                            break;
                        case 'COMPLETED':
                            index.dispatch(renderingCompleted( msg.data ));
                            // index.dispatch(saveRenderingToDashboard(msg.data));
                            break;
                        case 'FAILED':
                            index.dispatch(renderingFailed( msg.data ));
                            break;
                        case 'CANCELED':
                            break;
                        default:
                            break;
                    }
                } else if (msg.messageType === 'TRANSCRIPTION') {
                    switch (msg.data.type) {
                        case 'COMPLETED':
                            message.success("Transcription is done");
                            const assumedVideoId = msg.data.payload.json_file_uri.split('/').pop().replace(/\.json/, '');
                            const store = index.getState();
                            const video = store.subtitlesReducer.videos.find(video => assumedVideoId.indexOf(video.id) !== -1)
                            index.dispatch(SubtitlesActions.setSubtitlesStatusLoading({ videoId: video.id, loading: false }));
                            if (msg.data.payload.srt !== "")
                                convertSrtToSubsJson(video.id, msg.data.payload.srt);
                            else
                                convertToSubsJson(msg.data.payload.json_file_uri, 10).then(res => {
                                    index.dispatch(SubtitlesActions.setVideoSubtitles({
                                        videoId: video.id,
                                        res: res
                                    }));
                                });
    
                            break;
                        case 'FAILED':
                            // index.dispatch(SubtitlesActions.setSubtitlesStatusLoading(false));
                            message.error("Transcription failed");
                            break;
                        case 'CANCELED':
                            break;
                        default:
                            break;
                    }
                } else if (msg.messageType === 'YOUTUBE_DOWNLOAD') {
                    switch (msg.data.type) {
                        case 'COMPLETED':
                            let url = msg.data.payload.url;
                            utils.getDurationFromVideoSrc(url).then((videoInfo) => {
                                var item = {
                                    url: url,
                                    largeUrl: url,
                                    duration: videoInfo.duration,
                                    width: videoInfo.width,
                                    height: videoInfo.height,
                                    FileName: videoInfo.fileName + " (Youtube)",
                                    isUploaded: true,
                                };
                                utils.addVideo(item);
                                index.dispatch(ytdProgress( null ));
                            });
                            break;
                        case 'FAILED':
                            // index.dispatch(SubtitlesActions.setSubtitlesStatusLoading(false));
                            message.error("Adding youtube video failed");
                            break;
                        case 'CANCELED':
                            break;
    
                        case 'PROGRESS':
                            index.dispatch(ytdProgress( msg.data ));
                            break;
                        default:
                            break;
                    }
                } else if (msg.messageType === 'PROFILE') {
                    index.dispatch(setProfile(msg.data));
                }
            }
        }
    }
    if (!window.WebSocket) {
        //If the user's browser does not support WebSockets, give an alert message
        alert("Your browser does not support the WebSocket API!");
    } else {
        connect();
    }
}
