import { RENDERING_COMPLETED, SET_DOWNLOADING, RENDERING_FAILED, SET_THUMBNAIL_SRC, RENDERING_PROGRESS, SET_RENDERINGS, SET_RENDERED_URL, SET_RENDERING } from '../../constants/ActionTypes';
import * as ActionTypes from "../../constants/ActionTypes";
import { saveQueue } from '../../utils/indexdb.helper';

const initState = {
    project_id: '',
    renderingPreferences: {},
    list: []
}


export default function render(state = initState, action) {
    switch (action.type) {
        case SET_RENDERINGS: {
            return { ...state, list: action.payload.payloads.map(item => ({ payload: item, url: item.rendered_url })), project_id: action.payload.id }
        }

        case SET_RENDERING: {
            state.list
                .filter(item => item.payload.frame.video_id === action.payload.video_id)
                .forEach(item => { item.rendering = action.payload.rendering });
            return { ...state, list: [ ...state.list ] }
        }

        case RENDERING_PROGRESS: {
            state.list
                .filter(item => item.payload.frame.video_id === action.payload.video_id)
                .forEach(item => {
                    item.current_step = action.payload.progress_payload.current_step;
                    item.percentage = action.payload.progress_payload.percentage;
                    item.step_message = action.payload.progress_payload.step_message;
                    item.total_steps = action.payload.progress_payload.total_steps;
                });

            return { ...state, list: [ ...state.list ] }
        }

        case RENDERING_COMPLETED: {
            state.list
                .filter(item => item.payload.frame.video_id === action.payload.payload.video_id)
                .forEach(item => {
                    item.url = action.payload.payload.url;
                    item.rendering = false;
                    item.completed = true;
                    item.payload.rendered_url = action.payload.payload.url;
                });

            const lsValue = {
                id: state.project_id,
                payloads: state.list.map(item => item.payload)
            }
            
            const ls_data = {[action.payload.payload.video_id]: action.payload.payload.url}
            saveQueue.push({ id: state.project_id, payload:  ls_data});
            return { ...state, list: [ ...state.list ] }
        }

        case RENDERING_FAILED: {
            state.list
                .filter(item => item.payload.frame.video_id === action.payload.payload.video_id)
                .forEach(item => {
                    item.failed = true
                    item.rendering = false
                });

            const lsValue = {
                id: state.project_id,
                payloads: state.list.map(item => item.payload)
            }
            return { ...state, list: [ ...state.list ] }
        }

        case SET_RENDERED_URL: {
            state.list
                .filter(item => item.payload.frame.video_id === action.payload.video_id)
                .forEach(item => { item.url = action.payload.url; item.payload.rendered_url = action.payload.url });

            const lsValue = {
                id: state.project_id,
                payloads: state.list.map(item => item.payload)
            }
            return { ...state, list: [ ...state.list ] }
        }

        case SET_THUMBNAIL_SRC: {
            state.list
                .filter(item => item.payload.frame.video_id === action.payload.video_id)
                .forEach(item => {
                    item.thumbnailUrl = action.payload.url;
                });
            return { ...state, list: [ ...state.list ] }
        }

        case SET_DOWNLOADING: {
            state.list
                .filter(item => item.payload.frame.video_id === action.payload.video_id)
                .forEach(item => {
                    item.downloading = action.payload.value;
                });
            return { ...state, list: [ ...state.list ] }
        }

        case ActionTypes.SET_RENDERING_PREFS: {
            return {
                ...state,
                renderingPreferences: action.payload
            }
        }

        default:
            return state;
    }
}
