import * as utils from "../utils";
import { index, dispatch } from '../store';
import * as helpers from "../utils/index.helper";

class AppManager {
    constructor() {
        window.addEventListener('beforeunload', this.setLocalStorage);
        window.addEventListener('popstate', this.setLocalStorage);
        // helpers.handleFavIcon();
    }

    setLocalStorage = () => {
        const store = index.getState();
        const metaData = utils.prepareMetadataForSaving();

        if(metaData) {
            // dispatch(AppActions.saveProjectProgress({
            //     userId: store.userReducer.userDetails?.userId,
            //     projectId: store.appStateInfoReducer.projectId,
            //     metaData: metaData
            // }));

            window.sessionStorage.setItem(store.appStateInfoReducer.projectId, JSON.stringify(metaData))
        }
    }
}

export default AppManager;

