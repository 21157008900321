import * as ActionTypes from "../../constants/ActionTypes";
import * as Constants from "../../constants";

const initState = {
    dimensions: { width: null, height: '250px' },
    maxDuration: 0,
    zoom: Constants.DEFAULT_TIMELINE_ZOOM_FACTOR,
    zoomPercentage: Constants.DEFAULT_ZOOM_PERCENTAGE,
    baseZoom: Constants.DEFAULT_TIMELINE_ZOOM_FACTOR
}

export default function timelineReducer(state = initState, action) {
    switch (action.type) {
        case ActionTypes.SET_TIMELINE_DIMENSIONS: {
            return {
                ...state,
                dimensions: action.payload,
            }
        }
        case ActionTypes.SET_MAX_DURATION: {
            return {
                ...state,
                maxDuration: action.payload,
            }
        }
        case ActionTypes.SET_BASE_ZOOM_FACTOR:
            return {
                ...state,
                zoom: action.payload.zoom,
                baseZoom: action.payload.zoom
            }
        case ActionTypes.SET_ZOOM_FACTOR:
            return {
                ...state,
                zoom: action.payload.zoom
            }
        case ActionTypes.SET_ZOOM_FACTOR_DISPLAY:
            return {
                ...state,
                zoomPercentage: action.payload.display,
            }

        default:
            return state
    }
}
