import * as Constants from "../constants/ActionTypes";

export const getProfile = (payload) => async (dispatch) => {
    return new Promise((resolve, reject) => {
        dispatch({ type: Constants.GET_PROFILE, payload, resolve, reject })
    })
};

export const setProfile = (payload) => (dispatch) => {
    dispatch({ type: Constants.SET_PROFILE, payload });
};

export const setIntegration = (payload) => (dispatch) => {
    dispatch({ type: Constants.SET_INTEGRATION, payload });
};

