import AWS from "aws-sdk";
import {config} from '../config'

const aws_configs = {
    // bucketName: 'ffmpeg-app-media-bucket',
    bucketName: config.bucketName,
    bucketRegion: config.aws_region,
    // accessKeyId: config.accessKeyId,
    // secretAccessKey: config.secretAccessKey,
}
AWS.config.update({
    region: aws_configs.bucketRegion,
});

const s3 = new AWS.S3({
    apiVersion: '2006-03-01',
    params: { Bucket: aws_configs.bucketName },
    accessKeyId: aws_configs.accessKeyId,
    secretAccessKey: aws_configs.secretAccessKey,
    maxRetries: 3
    // httpOptions: {
    //     connectTimeout: 5* 1000,
    //     timeout: 10 * 1000
    //   },
});

export { s3 }
