import { generateUUID, generateObjectId } from "../utils";
import { VIDEO, TRANSPARENT_BACKGROUND_COLOUR } from '../constants'
export function VideoModel(data) {
    data = data || {};//sample data
    let uuid = generateUUID();
    this.id = data.id || uuid;
    this.videoId = (data.videoId) ? data.videoId : (data.id) ? data.id : uuid;
    this.modelType = VIDEO;
    this.videoElementId = data.videoElementId || generateObjectId()
    this.src = data.src || "https://keevi-media-bucket.s3-us-west-2.amazonaws.com/Leonardo+DiCaprio+(UN+Messenger+of+Peace)+at+the+opening+of+Climate+Summit+2014-vTyLSr_VCcg.mp3";
    this.file = data.file || null; //'https://ffmpeg-app-media-bucket.s3.us-west-2.amazonaws.com/1604642457414_exam2.mp4'; //local file
    this.s3Url = data.s3Url;
    this.originalWidth = data.originalWidth || 0; // the actual width of video
    this.originalHeight = data.originalHeight || 0;// the actual height of video
    this.originalFileName = data.originalFileName || "";
    this.currentWidth = data.currentWidth || 0;// the player width of video
    this.currentHeight = data.currentHeight || 0;// the player height of video
    this.duration = data.duration || 0; // the video duration
    this.volume = data.volume || 1;// the volume 0.0-1 it is float value
    this.trimStartTime = data.trimStartTime || 0; // trimStartTime may be user want to trim video
    this.trimEndTime = data.trimEndTime || 0;// trimEndTime may be user want to trim video
    this.backgroundColor = data.backgroundColor || TRANSPARENT_BACKGROUND_COLOUR; // background of the video, may be user want to show background
    this.xPosition = data.xPosition || 0; //position x of video in he pixi
    this.yPosition = data.yPosition || 0;//position y of video in he pixi
    this.rotation = data.rotation || 0; // rotation angle of the video
    this.name = data.name || ''; // video name
    this.start = data.start || 0; // video name
    this.end = data.end || 0; // video name
    this.targetStart = data.targetStart || 0; // video target start
    this.targetEnd = data.targetEnd || 0; // video target end
    this.xTimelinePosition = data.xTimelinePosition || 0; // video name
    this.yTimelinePosition = data.yTimelinePosition || 0; // video name
    this.currentDuration = data.currentDuration || 0; // detect the trim duration for timeline
    this.thumbnail = data.thumbnail || ''; // thumbnail url
    this.ratio = data.ratio || '16/9'; // thumbnail url
    this.subtitles = data.subtitles || []; // substitle Array
    this.isDragging = data.isDragging || false;
    this.isUploaded = data.isUploaded || false;
    this.initialStart = data.initialStart || null;
    this.alignment = data.alignment || "CENTER";
    this.videoElement = data.videoElement || null;
    this.hasAudio = data.hasAudio || true;
    this.type = data.type || VIDEO;
}
