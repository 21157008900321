import { generateUUID } from "../utils";
import { SUBS } from "../constants";


export function SubtitlesModel(data) {
    this.id = data.id || "Subs-" + generateUUID();
    this.modelType = data.modelType || SUBS;
    this.text = data.text || "";
    this.start = data.start ? parseFloat(data.start) : 0;
    this.end = data.end ? parseFloat(data.end) : 10;
    this.startCopy = data.startCopy ? parseFloat(data.startCopy) : 0;
    this.endCopy = data.endCopy ? parseFloat(data.endCopy) : 10;
    this.targetStart = data.targetStart !== null && data.targetStart !== undefined ? parseFloat(data.targetStart) : 0;
    this.targetEnd = data.targetEnd !== null && data.targetEnd !== undefined ? parseFloat(data.targetEnd) : 10;
    this.trackIndex = data.trackIndex || null;
    this.elementIndex = data.elementIndex || null;
    this.videoId = data.videoId || null;
    this.alignment = data.alignment || "";
    this.xPosition = data.xPosition || 0; //position x of video in he pixi
    this.yPosition = data.yPosition || 0;//position y of video in he pixi
    this.type = data.type || SUBS;
}
