import {
    generateUUID,
    nextColor,
    hexToRgb,
    colourIsLight,
} from "../utils";

export function UserModel(data, token, scope, permissionGroups) {
    this.userId = data['preferred_username'] ?? data['cognito:username']
    this.fullName = data['name']
    this.parentAccount = data['custom:parentAccount']
    this.subscription = data['custom:subscription']
    this.email = data['email']
    this.emailVerified = data['email_verified']
    this.scope = scope
    this.permissionGroups = permissionGroups
    this.picture = data['picture']
    this.sub = data['sub']
    this.jwtToken = token

}
